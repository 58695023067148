import clone from 'clone';

import * as types from './constants';
import { INVITE_EDITOR_SUCCESS, REMOVE_EDITOR_SUCCESS } from "../Invitations/store/constants";

export const EMPTY_EPILOGG = {
    content: [{
        type: 'it',
        isDefault: true
    }, {
        type: 't',
        isDefault: true
    }, {
        type: 'iii',
        isDefault: true
    }, {
        type: 'd',
        isDefault: true
    }, {
        type: 'm',
        isDefault: true
    }, {
        type: 'f',
        isDefault: true,
        subtitle: 'family and friends'
    }, {
        type: 'plant_a_tree',
        isDefault: true
    }, {
        type: 'flower',
        isDefault: true
    }, {
        type: 'g',
        isDefault: true,
        navTitle: 'guestbook'
    }]
};

export const initialState = {
    all: [],
    shared: [],
    epilogg: clone(EMPTY_EPILOGG),
    viewEpilogg: {},
    followEmail: '',
    newComment: {},
    reportReason: '',
    isCreatingEpilogg: false,
    createEpiloggError: {},
    isDeletingComment: false,
    deleteCommentError: {},
    isDeletingEpilogg: false,
    deleteEpiloggError: {},
    isFollowingEpilogg: false,
    followEpiloggError: {},
    isUnfollowingEpilogg: false,
    oneClickUnfollowEpiloggError: {},
    isGettingEpilogg: false,
    getEpiloggError: {},
    isPublishingEpilogg: false,
    publishEpiloggError: {},
    isReportingEpilogg: false,
    reportEpiloggError: {},
    isSavingEpilogg: false,
    saveEpiloggError: {}
};

function epiloggsReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_EPILOGG:
            return { ...state, epilogg: clone(EMPTY_EPILOGG) };
        case types.CREATE_EPILOGG_START:
            return { ...state, isCreatingEpilogg: true, createEpiloggError: {} };
        case types.CREATE_EPILOGG_SUCCESS:
            let all = clone(state.all);
            all.unshift(action.data);
            return { ...state, isCreatingEpilogg: false, all };
        case types.CREATE_EPILOGG_FAILURE:
            return { ...state, isCreatingEpilogg: false, createEpiloggError: action.error };
        case types.DELETE_COMMENT_START:
            return { ...state, isDeletingComment: true, deleteCommentError: {} };
        case types.DELETE_COMMENT_SUCCESS:
            return { ...state, isDeletingComment: false };
        case types.DELETE_COMMENT_FAILURE:
            return { ...state, isDeletingComment: false, deleteCommentError: action.error };
        case types.DELETE_EPILOGG_START:
            return { ...state, isDeletingEpilogg: true, deleteEpiloggError: {} };
        case types.DELETE_EPILOGG_SUCCESS:
            return { ...state, isDeletingEpilogg: false, all: action.data, epilogg: clone(EMPTY_EPILOGG) };
        case types.DELETE_EPILOGG_FAILURE:
            return { ...state, isDeletingEpilogg: false, deleteEpiloggError: action.error };
        case types.FOLLOW_EPILOGG_START:
            return { ...state, isFollowingEpilogg: true, followEpiloggError: {} };
        case types.FOLLOW_EPILOGG_SUCCESS:
            return { ...state, isFollowingEpilogg: false };
        case types.FOLLOW_EPILOGG_FAILURE:
            return { ...state, isFollowingEpilogg: false, followEpiloggError: action.error };
        case types.GET_EPILOGG_START:
            return { ...state, isGettingEpilogg: true, getEpiloggError: {} };
        case types.GET_EPILOGG_SUCCESS:
            return { ...state, isGettingEpilogg: false, viewEpilogg: action.data };
        case types.GET_EPILOGG_FAILURE:
            return { ...state, isGettingEpilogg: false, getEpiloggError: action.error };
        case types.ONE_CLICK_UNFOLLOW_EPILOGG_START:
            return { ...state, isUnfollowingEpilogg: true, oneClickUnfollowEpiloggError: {} };
        case types.ONE_CLICK_UNFOLLOW_EPILOGG_SUCCESS:
            return { ...state, isUnfollowingEpilogg: false };
        case types.ONE_CLICK_UNFOLLOW_EPILOGG_FAILURE:
            return { ...state, isUnfollowingEpilogg: false, oneClickUnfollowEpiloggError: action.error };
        case types.PUBLISH_EPILOGG_START:
            return { ...state, isPublishingEpilogg: true, isSavingEpilogg: true, publishEpiloggError: {} };
        case types.PUBLISH_EPILOGG_SUCCESS:
            return { ...state, isPublishingEpilogg: false, isSavingEpilogg: false };
        case types.REPORT_EPILOGG_START:
            return { ...state, isReportingEpilogg: true, reportEpiloggError: {} };
        case types.REPORT_EPILOGG_SUCCESS:
            return { ...state, isReportingEpilogg: false };
        case types.REPORT_EPILOGG_FAILURE:
            return { ...state, isReportingEpilogg: false, reportEpiloggError: action.error };
        case types.PUBLISH_EPILOGG_FAILURE:
            return { ...state, isPublishingEpilogg: false, isSavingEpilogg: false, publishEpiloggError: action.error };
        case types.SAVE_EPILOGG_START:
            return { ...state, isSavingEpilogg: true, saveEpiloggError: {} };
        case types.SAVE_EPILOGG_SUCCESS:
            let epiloggs = clone(state.all);
            for(var i = 0; i < epiloggs.length; i++) {
                if(epiloggs[i].id === action.data.id) {
                    epiloggs[i] = action.data;
                    break;
                }
            }

            let shared = clone(state.shared);
            for(var i = 0; i < shared.length; i++) {
                if(shared[i].id === action.data.id) {
                    shared[i] = action.data;
                    break;
                }
            }
            return { ...state, isSavingEpilogg: false, epilogg: action.data, epiloggs, shared };
        case types.SAVE_EPILOGG_FAILURE:
            return { ...state, isSavingEpilogg: false, all: epiloggs, saveEpiloggError: action.error };
        case types.SAVE_NEW_COMMENT_START:
            return { ...state, isSavingNewComment: true, saveNewCommentError: {} };
        case types.SAVE_NEW_COMMENT_SUCCESS:
            return { ...state, isSavingNewComment: false };
        case types.SAVE_NEW_COMMENT_FAILURE:
            return { ...state, isSavingNewComment: false, saveNewCommentError: action.error };
        case types.SET_EPILOGG:
            return { ...state, epilogg: action.data };
        case types.SET_ALL_EPILOGGS:
            return { ...state, all: action.data };
        case types.SET_FOLLOW_EMAIL:
            return { ...state, followEmail: action.data };
        case types.SET_NEW_COMMENT:
            return { ...state, newComment: action.data };
        case types.SET_REPORT_REASON:
            return { ...state, reportReason: action.data };
        case types.SET_SHARED_EPILOGGS:
            return { ...state, shared: action.data };
        case types.SET_VIEW_EPILOGG:
            return { ...state, viewEpilogg: action.data };

        case INVITE_EDITOR_SUCCESS:
            if(action.data.email) {
                let epilogg = clone(state.epilogg);
                const foundIndex = epilogg.collaborators.findIndex((c) => c.email === action.data.email);
                if(foundIndex === -1) {
                    epilogg.collaborators.push(action.data);
                }

                return { ...state, epilogg };
            } else {
                return state;
            }

        case REMOVE_EDITOR_SUCCESS:
            if(action.data) {
                let epilogg = clone(state.epilogg);

                epilogg.collaborators = epilogg.collaborators.filter((c) => c.email !== action.data);

                return { ...state, epilogg };
            } else {
                return state;
            }

        default:
            return state;
    }
}

export default epiloggsReducer;
