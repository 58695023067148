import './ViewGuestbook.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import { deleteComment, setNewComment } from 'containers/Epilogg/store/actions';

import Button from 'components/Button';
import Guestbook from 'components/Guestbook';
import Modal from 'components/Modal'
import NewCommentModal from './NewCommentModal';

const ViewGuestbook = ({section}) => {
    const dispatch = useDispatch();
    const [comment, setComment] = useState({});
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showNewCommentModal, setShowNewCommentModal] = useState(false);

    const newComment = useSelector(store => store.epiloggs.newComment);
    const publishStatus = useSelector(store => store.epiloggs.viewEpilogg.publishStatus);
    const { profile: { isAdmin }, token } = useSelector(store => store.auth);
    const { isDeletingComment } = useSelector(store => store.epiloggs);

    const closeModal = () => {
        setShowNewCommentModal(false);
        dispatch(setNewComment({}));
    };

    const confirmDeleteComment = async () => {
        await dispatch(deleteComment(comment, true));
        setShowConfirmDeleteModal(false);
    };

    const handleDeletePress = (c) => {
        setComment(c);
        setShowConfirmDeleteModal(true);
    };

    return (
        <div className="c-view-section__guestbook">
            <Guestbook
                comments={section.comments || []}
                disabled={isDeletingComment}
                handleDeletePress={isAdmin ? handleDeletePress : null}
            />

            <div className="c-view-section__leave-comment-button">
                <Button
                    disabled={publishStatus !== 'published'}
                    onClick={() => setShowNewCommentModal(true)}
                >
                    Leave a Comment
                </Button>
            </div>

            <NewCommentModal
                closeModal={closeModal}
                isAuthenticated={token != null}
                newComment={newComment}
                setNewComment={(newCommentObj) => dispatch(setNewComment(newCommentObj))}
                show={showNewCommentModal}
            />

            <Modal
                showActivityIndicator={isDeletingComment}
                confirmButtonOnClick={confirmDeleteComment}
                confirmButtonText="remove"
                declineButtonOnClick={() => {
                    setShowConfirmDeleteModal(false);
                    setComment({});
                }}
                declineButtonText="cancel"
                show={showConfirmDeleteModal}
                title="confirm delete"
            >
                Are you sure you want to remove this comment? This action cannot be undone.
            </Modal>
        </div>
    );
};

ViewGuestbook.propTypes = {
    section: PropTypes.object.isRequired
};

export default ViewGuestbook;
