import './ViewDonation.scss';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import GivingInstance from './givingInstance';
import ParseLinks from 'components/ParseLinks';
import H2 from "../../../../../components/H2";

const givingInstance = GivingInstance();

const ViewDonation = ({section}) => {
    let id = useRef(`jlwku${section.thirdPartyId}`).current;

    useEffect(() => {
        if(section.thirdPartyId && section.thirdPartyState === 'A') {
            if (givingInstance && givingInstance.addAction) {
                givingInstance.addAction({
                    action: "_b", // slightly different syntax if you want to use a button modal vs an iframe embed
                    publicAccessCode: section.thirdPartyId,
                    containerId: id,
                    themeColor: "#23A278",
                    customButton: true
                });
            }
        }
    }, []);

    return (
        <div className="c-view-section__donation">
            {section.title1 ? (
                <H2 className="c-view-section__text-title">
                    {section.title1}
                </H2>
            ) : null}

            {section.text1 ? (
                <ParseLinks>
                    <div className="c-view-section__text-text">
                        {section.text1.split('\n').map((paragraph, paragraphIndex) => {
                            if (paragraph) {
                                return (
                                    <p key={paragraphIndex}>
                                        {paragraph}
                                    </p>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </ParseLinks>
            ) : null}

            {section.thirdPartyId && section.thirdPartyState === 'A' ? (
                <div className="c-view-section__text-text c-view-section__donation-link">
                    <Button
                        className="a-button--4giving"
                        data-giving-button-id={id}
                    >
                        <svg viewBox="0 0 24 24">
                            <path d="M20 17Q20.86 17 21.45 17.6T22.03 19L14 22L7 20V11H8.95L16.22 13.69Q17 14 17 14.81 17 15.28 16.66 15.63T15.8 16H13L11.25 15.33L10.92 16.27L13 17H20M16 3.23Q17.06 2 18.7 2 20.06 2 21 3T22 5.3Q22 6.33 21 7.76T19.03 10.15 16 13Q13.92 11.11 12.94 10.15T10.97 7.76 10 5.3Q10 3.94 10.97 3T13.31 2Q14.91 2 16 3.23M.984 11H5V22H.984V11Z" />
                        </svg>

                        Donate Now
                    </Button>
                </div>
            ) : (
                <>
                    {section.thirdPartyUrl ? (
                        <div className="c-view-section__text-text c-view-section__donation-link">
                            <a className="c-button" href={section.thirdPartyUrl.indexOf('http') === 0 ? section.thirdPartyUrl : `https://${section.thirdPartyUrl}`} target="_blank">Donate Now</a>
                        </div>
                    ) : null}
                </>
            )}


        </div>
    );
};

ViewDonation.propTypes = {
    section: PropTypes.object.isRequired
};

export default ViewDonation;
