import React from 'react';
import PropTypes from 'prop-types';
import { validated } from "react-custom-validation";
import { useDispatch } from "react-redux";

import { handleTextChange } from 'utils/handle-changes';
import { isRequired } from 'utils/validations';

import { setReportReason } from 'containers/Epilogg/store/actions';

import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';
import FormError from 'components/FormError';

let ReportEpiloggForm = ({close, error, isReportingEpilogg, onSubmit, reportReason, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();

    const handleChange = (name, value) => {
        dispatch(setReportReason(value));
    };

    const handleSubmit = async (isValid) => {
        if(isValid) {
            try {
                await onSubmit();
                $fieldEvent('reset');
            } catch(err) {

            }
        }
    };

    return (
        <Form className="report-epilogg-form">
            <FormRow>
                <Textbox
                    id="txtReportReason"
                    label="reason"
                    name="reportReason"
                    placeholder="reason"
                    required
                    type="textarea"
                    validation={$validation.reportReason}
                    value={reportReason || ''}
                    {...$field('reportReason', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormError
                error={error}
            />

            <FormRow>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isReportingEpilogg}
                    type="submit"
                >
                    report
                </Button>

                <Button
                    disabled={isReportingEpilogg}
                    onClick={close}
                    type="button"
                >
                    cancel
                </Button>
            </FormRow>
        </Form>
    );
};

ReportEpiloggForm.propTypes = {
    close: PropTypes.func.isRequired,
    error: PropTypes.object,
    isReportingEpilogg: PropTypes.bool,
    reportReason: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired
};



function reportEpiloggFormValidationConfig(props) {
    const { reportReason } = props;

    return {
        fields: ['reportReason'],
        validations: {
            reportReason: [
                [isRequired, reportReason]
            ]
        }
    }
}


export default ReportEpiloggForm = validated(reportEpiloggFormValidationConfig)(ReportEpiloggForm);
