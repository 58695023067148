import './MainHeader.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from 'react-responsive';

import { breakpoints } from 'utils/constants';

import { clearCredentials, clearLoginError } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import HeaderLogin from "./HeaderLogin";
import Logo from 'components/Logo';
import MainNav from './MainNav';
import ProfileModal from './ProfileModal';

const MainHeader = () => {
    const dispatch = useDispatch();
    const [showLogin, setShowLogin] = useState(false);
    const [showProfile, setShowProfile] = useState(false);

    const { profile, token } = useSelector(store => store.auth);

    const closeLogin = () => {
        setShowLogin(false);
        dispatch(clearCredentials());
        dispatch(clearLoginError());
    };

    const openCloseMenu = () => {
        document.body.classList.toggle('open-menu');
    };

    return (
        <header className="c-main-header">
            <div className="c-main-header--inner">
                <A href={process.env.REACT_APP_WEBSITE_URL}>
                    <Logo />
                </A>

                <div className="c-main-header__nav-wrapper">
                    <div className="c-main-header__quick-links">
                        {token ? (
                            <>
                                <A to="/dashboard">
                                    your epiloggs
                                </A>

                                <Button
                                    className={`c-main-header__avatar ${profile.profilePicUrl ? 'a-main-header__avatar--has-image' : ''}`}
                                    noStyles
                                    onClick={() => setShowProfile(true)}
                                    style={profile.profilePicArtifact ? {backgroundImage: `url(${profile.profilePicArtifact.url})`} : null}
                                >
                                    {!profile.profilePicArtifact ? `${profile.firstName[0]}${profile.lastName[0]}` : null}
                                </Button>
                            </>
                        ) : (
                            <A onClick={() => setShowLogin(!showLogin)}>
                                login
                            </A>
                        )}

                        <MediaQuery maxWidth={breakpoints.maxTabletBreakpoint}>
                            {(matches) => {
                                if (matches) {
                                    return (
                                        <Button className="open-close-menu"
                                                onClick={openCloseMenu}>
                                            <div className="open-close-menu_inner">
                                                <div className="menu-line line1" />
                                                <div className="menu-line line2">
                                                    <div className="menu-line line4" />
                                                </div>
                                                <div className="menu-line line3" />
                                            </div>
                                        </Button>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                        </MediaQuery>
                    </div>

                    <MainNav />
                </div>
            </div>

            <HeaderLogin
                closeLogin={closeLogin}
                showLogin={showLogin}
            />

            <ProfileModal
                closeModal={() => setShowProfile(false)}
                profile={profile}
                show={showProfile}
            />
        </header>
    );
};

MainHeader.propTypes = {

};

export default MainHeader;
