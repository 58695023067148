export const ACCEPT_INVITATION_START = 'Invitations/ACCEPT_INVITATION_START';
export const ACCEPT_INVITATION_SUCCESS = 'Invitations/ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAILURE = 'Invitations/ACCEPT_INVITATION_FAILURE';

export const INVITE_EDITOR_START = 'Invitations/INVITE_EDITOR_START';
export const INVITE_EDITOR_SUCCESS = 'Invitations/INVITE_EDITOR_SUCCESS';
export const INVITE_EDITOR_FAILURE = 'Invitations/INVITE_EDITOR_FAILURE';

export const REMOVE_EDITOR_START = 'Invitations/REMOVE_EDITOR_START';
export const REMOVE_EDITOR_SUCCESS = 'Invitations/REMOVE_EDITOR_SUCCESS';
export const REMOVE_EDITOR_FAILURE = 'Invitations/REMOVE_EDITOR_FAILURE';

export const SET_EMAIL_TO_INVITE = 'Invitation/SET_EMAIL_TO_INVITE';
