import clone from 'clone';

import * as types from './constants';

export const initialState = {
    title: 'Epilogg'
};

function appReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.SET_TITLE:
            return { ...state, title: action.data };
        default:
            return state;
    }
}

export default appReducer;
