import './FileUpload.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FileUploadErrorModal from 'components/Modal/FileUploadErrorModal';

const FileUpload = ({disabled, imageUrl, isUploading, name, onDelete, onDrop, preview, thisRef, types}) => {
    const [activeDrag, setActiveDrag] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const handleDrop = async (goodFiles, badFiles) => {
        setActiveDrag(false);
        console.log(goodFiles, badFiles);
        if(badFiles && badFiles.length > 0) {
            setShowErrorModal(true);
        } else {
            onDrop(goodFiles[0]);
        }
    };

    const classes = classNames(
        'c-file-upload',
        {
            'a-file-upload--active': activeDrag,
            'a-file-upload--has-preview': (preview || imageUrl)  && !isUploading,
            'a-file-upload--is-uploading': isUploading,
            'a-file-upload--disabled': disabled
        }
    );

    return (
        <div className={classes}>
            <div className="c-file-upload--inner">
                <Dropzone accept={types}
                          disabled={disabled}
                          multiple={false}
                          name={name}
                          onDragEnter={() => setActiveDrag(true)}
                          onDragLeave={() => setActiveDrag(false)}
                          onDrop={handleDrop}
                          ref={thisRef}>
                    {({getRootProps, getInputProps}) => (
                        <div className="c-file-upload__content" {...getRootProps()}>
                            <input {...getInputProps()} />

                            {isUploading ? (
                                <div className="c-file-upload__uploading">
                                    <ActivityIndicator />  Uploading
                                </div>
                            ) : (
                                <>
                                    {preview || imageUrl ? (
                                        <img src={preview ? preview.preview : imageUrl} alt="" className="c-file-upload__preview" />
                                    ) : (
                                        <>
                                            <Icon type="upload" />
                                            <div className="c-file-upload__text">
                                                drag/drop or click to upload photo
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </Dropzone>

                {(preview || imageUrl) && !isUploading ? (
                    <Button
                        className="c-file-upload__delete"
                        onClick={onDelete}
                    >
                        <Icon type="x" />
                    </Button>
                ) : null}
            </div>

            <FileUploadErrorModal
                close={() => setShowErrorModal(false)}
                show={showErrorModal}
            />
        </div>
    );
};

FileUpload.propTypes = {
    disabled: PropTypes.bool,
    imageUrl: PropTypes.string,
    isUploading: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    thisRef: PropTypes.object,
    types: PropTypes.string,
    uploadPercentage: PropTypes.number.isRequired
};

FileUpload.defaultProps = {
    types: "image/jpeg,image/png,image/gif,image/webp,image/heic,image/heif"
};

export default FileUpload;
