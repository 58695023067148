import './FormError.scss';

import React from 'react';
import PropTypes from 'prop-types';

import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';

const FormError = ({error}) => {
    if(error && Object.keys(error).length > 0) {
        return (
            <FormRow className="c-form-error">
                <FormValidationMessage>
                    {error.friendlyMessage || error.message}
                </FormValidationMessage>
            </FormRow>
        );
    } else {
        return null;
    }
};

FormError.propTypes = {
    error: PropTypes.object
};

export default FormError;
