import PATHS from 'utils/paths';
import Request from 'utils/request';

import * as types from './constants';

import { generateFriendlyErrorString } from 'utils/data';

import { addError } from 'containers/Errors/store/actions';
import {getImageDimensions} from "../../utils/images";

const getPresignedUrlStart = () => ({
    type: types.GET_PRESIGNED_URL_START
});

const getPresignedUrlSuccess = () => ({
    type: types.GET_PRESIGNED_URL_SUCCESS
});

const getPresignedUrlFailure = () => ({
    type: types.GET_PRESIGNED_URL_FAILURE
});

const uploadFileStart = () => ({
    type: types.UPLOAD_FILE_START
});

const uploadFileSuccess = () => ({
    type: types.UPLOAD_FILE_SUCCESS
});

const uploadFileFailure = () => ({
    type: types.UPLOAD_FILE_FAILURE
});

export const uploadFiles = (file, name) => async (dispatch, getStore) => {
    dispatch(getPresignedUrlStart());

    file = await getImageDimensions(file);

    console.log(file);

    let body = [{
        fileName: file.name,
        width: file.width,
        height: file.height,
        size: file.size
    }];

    let presignedUrlRequest = new Request(getStore().auth.token);

    return presignedUrlRequest.post(PATHS.upload.getPresignedUrl(), body)
        .then((res) => {
            console.log(res.data);
            dispatch(getPresignedUrlSuccess());
            file.uploadUrl = res.data.data[0].uploadUrl;
            file.artifactId = res.data.data[0].id;
            return dispatch(uploadFileToAws(file));
        })
        .catch((err) => {
            console.log(err, err.response);
            err.friendlyMessage = generateFriendlyErrorString('We were unable to get an upload URL. Please try again.', err.response.data);
            dispatch(getPresignedUrlFailure(err));
        })
};

export const uploadFileToAws = (file) => (dispatch, getStore) => {
    dispatch(uploadFileStart());

    let config = {
        baseURL: '',
        headers: {
            'Content-Type': 'application/octet-stream'
        }
    };
    let uploadRequest = new Request();

    return uploadRequest.put(file.uploadUrl, file, config)
        .then((res) => {
            dispatch(uploadFileSuccess());
            return {res, file};
        })
        .catch((err) => {
            console.log(err, err.response);
            err.friendlyMessage = 'There was an error uploading your file. Please try again.';
            dispatch(addError(err));
            dispatch(uploadFileFailure(err));
            throw err;
        })
};
