export const handleCheckboxChange = (handleChangeFunc, e, objectName) => {
    return handleChangeFunc(e.target.name, e.target.checked, objectName);
};

export const handleDateChange = (handleChangeFunc, name, objectName, date) => {
    return handleChangeFunc(name, date, objectName);
};

export const handleSelectChange = (handleChangeFunc, name, objectName, $fieldEvent, selectedValue) => {
    let newValue = null;
    if (selectedValue != null) {
        newValue = selectedValue.value;
    }

    handleChangeFunc(name, newValue, objectName);

    if ($fieldEvent != null) {
        $fieldEvent('change', name);
    }
};

export const handleTextChange = (handleChangeFunc, e, objectName) => {
    return handleChangeFunc(e.target.name, e.target.value, objectName);
};
