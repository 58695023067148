import './CreateTextButtonColumn.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

import { handleTextChange } from 'utils/handle-changes';

import Textbox from 'components/Textbox';
import FormRow from 'components/FormRow';

const CreateTextButtonColumn = ({columnIndex, handleChange, section}) => {
    const titleName = `title${columnIndex + 1}`;
    const textName = `text${columnIndex + 1}`;

    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const disabled = isUploading || isSavingEpilogg;

    return (
        <div className="c-create-section-column a-create-section-column--textbutton">
            <FormRow>
                <Textbox
                    disabled={disabled}
                    label="story title"
                    name={titleName}
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder="story title"
                    value={section[titleName]}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={disabled}
                    label="text"
                    name={textName}
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder="text"
                    type="textarea"
                    value={section[textName]}
                />
            </FormRow>

            {/* Add a button here eventually */}
        </div>
    );
};

CreateTextButtonColumn.propTypes = {
    columnIndex: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired
};

export default CreateTextButtonColumn;
