import './Signup.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

import { setTitle } from 'containers/App/store/actions';

import H3 from 'components/H3';
import SignupForm from './SignupForm';

const Signup = () => {
    const signupData = useSelector(store => store.auth.signupData);
    const dispatch = useDispatch();

    useEffect(() => {
        document.body.classList.add('signup-page');
        dispatch(setTitle('create an account'));

        return () => {
            document.body.classList.remove('signup-page');
        }
    }, [dispatch]);

    return (
        <div className="signup-form--wrapper">
            <Helmet>
                <title>Sign Up for Epilogg</title>
            </Helmet>

            <H3>
                create an account
            </H3>

            <SignupForm
                signupData={signupData}
            />
        </div>
    );
};

export default Signup;
