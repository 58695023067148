function injectGivingShim() {
    if (!window.fourGiving) {
        window.fourGiving = {};
        const m = ["init", "action"];
        window.fourGiving._c = [];
        m.forEach(function (me) {
            window.fourGiving[me] = function () {
                window.fourGiving._c.push([me, arguments]);
            };
        });

        var elt = document.createElement("script");
        elt.type = "text/javascript";
        elt.async = true;
        elt.src = "https://js.4giving.com/shim-v2.js";
        var before = document.getElementsByTagName("script")[0];
        before.parentNode.insertBefore(elt, before);
    }
}

export class GivingShim {
    static initalized = false;
    static instance = undefined;

    static getInstance() {
        if (!GivingShim.instance) {
            GivingShim.instance = new GivingShim();
        }
        return GivingShim.instance;
    }

    constructor() {
        if (!GivingShim.initalized) {
            this.init();
        }
    }

    init() {
        if (!GivingShim.initalized) {
            injectGivingShim();
            GivingShim.initalized = true;
            window.fourGiving.init(process.env.REACT_APP_ENV === 'production' ? 'prod' : 'preview'); // prod // preview
        }
    }

    /**
     *
     * @param {
     *   embedId: string,
     *   publicAccessCode: string,
     *   themeColor?: string;
     * } actionOptions
     */
    addAction(actionOptions) {
        if (!window.fourGiving.addAction) {
            // if action fires before lib is added
            window.fourGiving.action(actionOptions);
        } else {
            // if action fires after lib is loaded
            window.fourGiving.addAction(actionOptions);
        }
    }
}

export default GivingShim.getInstance;
