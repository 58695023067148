import './VerifyEmail.scss';

import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import { setTitle } from 'containers/App/store/actions';
import { verifyEmail } from 'containers/Auth/store/actions';
import { createEpilogg } from 'containers/Epilogg/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import H3 from 'components/H3';
import Modal from 'components/Modal';
import {getRedirectAfterAuth} from "../../../utils/utils";

const VerifyEmail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [token, setToken] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [newEpiloggId, setNewEpiloggId] = useState(null);

    const authToken = useSelector(store => store.auth.token);
    const epiloggs = useSelector(store => store.epiloggs.all);

    useEffect(() => {
        const verify = async (token) => {
            try {
                await dispatch(verifyEmail(token));
                createFirstEpilogg();
            } catch(err) {

            }
        };

        dispatch(setTitle('email verification'));
        document.body.classList.add('email-verification-page');

        const qs = queryString.parse(location.search);

        if(qs.t) {
            setToken(qs.t);
            verify(qs.t);
        } else if(location && location.pathname === '/auth/complete' && authToken && epiloggs.length === 0) {
            createFirstEpilogg();
        }

        return () => {
            document.body.classList.remove('email-verification-page');
        }
    }, [dispatch, history, location]);

    const createFirstEpilogg = async () => {
        let redirect = getRedirectAfterAuth('');
        if(redirect !== '') {
            history.push(redirect);
        } else {
            try {
                let createRes = await dispatch(createEpilogg());
                setNewEpiloggId(createRes.data.data.id);
                setShowSuccessModal(true);
            } catch (err) {

            }
        }
    };

    return (
        <div className="email-verification">
            <Helmet>
                <title>Verify Your Email Address | Epilogg</title>
            </Helmet>

            {token ? (
                <div className="email-verification__activity-indicator">
                    <ActivityIndicator/> Please wait
                </div>
            ) : (
                <>
                    <H3>
                        you've successfully signed up for epilogg.
                    </H3>

                    {location && location.pathname === '/auth/complete' ? (
                        <p>
                            You can now begin writing your epilogg. Thank you!
                        </p>
                    ) : (
                        <p>
                            For your security, we've sent an email to the address you used to create an account. Press the link in the email to complete signup.  If you don't see the email from us, be sure to check your spam folder.  Thank you!
                        </p>
                    )}
                </>
            )}

            <Modal
                confirmButtonOnClick={() => window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/search`}
                confirmButtonText="See examples"
                declineButtonOnClick={() => {
                    setShowSuccessModal(false);
                    history.push(`/epilogg/edit/${newEpiloggId}`);
                }}
                declineButtonText="Build my epilogg"
                show={showSuccessModal}
                title="Success!"
            >
                Hi! Since it's your first time building an epilogg, feel free to review what a finished one will look like!
            </Modal>
        </div>
    );
};

export default VerifyEmail;
