import { LOCALSTORAGE } from "./constants";
import moment from "moment";

export const isAuthPage = location => {
    return location.indexOf('/auth/') === 0;
};

export const getTimezoneAbbreviation = (time) => {
    const dateObject = new Date(time);

    return dateObject.toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop();
};

export const getRedirectAfterAuth = (redirect) => {
    let redirectString = localStorage.getItem(LOCALSTORAGE.REDIRECT);
    let redirectItems = redirectString ? redirectString.split('||') : null;
    if(redirectItems && new Date().getTime() - parseInt(redirectItems[1], 10) <= 300000) {
        redirect = redirectItems[0];
    }
    localStorage.removeItem(LOCALSTORAGE.REDIRECT);
    return redirect;
};


