import './App.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Redirect, useHistory} from "react-router";
import { Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isMobile } from 'react-device-detect';

import { isAuthPage } from 'utils/utils';
import { LOCALSTORAGE } from 'utils/constants';

import { getLoggedInUser, setToken } from 'containers/Auth/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import Auth from 'containers/Auth';
import Dashboard from 'containers/Dashboard';
import Epilogg from 'containers/Epilogg';
import Error404 from 'containers/Errors/Error404';
import Errors from 'containers/Errors';
import MainFooter from 'components/MainFooter';
import MainHeader from 'components/MainHeader';
import requireAuth from "../Auth/requireAuth";
import ScrollToTop from './ScrollToTop';
import View from 'containers/Epilogg/View';

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isFetchingProfile = useSelector(store => store.auth.isFetchingProfile);

    const [hasFetchedProfile, setHasFetchedProfile] = useState(false);

    useEffect(() => {
        let authToken = localStorage.getItem(LOCALSTORAGE.ID_TOKEN);

        if (authToken == null) {
            console.log('authToken == null');
            if(window.location.pathname === '/obituary' || window.location.pathname === '/obituary/') {
                history.push('/auth/login');
            }
            setHasFetchedProfile(true);
        } else {
            dispatch(getLoggedInUser(authToken))
                .then((res) => {
                    dispatch(setToken(authToken));
                    if(isAuthPage(window.location.pathname) || window.location.pathname === '/obituary' || window.location.pathname === '/obituary/') {
                        history.push('/dashboard');
                    }
                    setHasFetchedProfile(true);
                })
                .catch((err) => {
                    history.push('/auth/logout');
                    setHasFetchedProfile(true);
                });
        }

        if(isMobile) {
            document.body.classList.add('is-mobile');
        }

        setTimeout(() => document.body.classList.add('loaded'), 200);

        return () => {

        }
    }, [dispatch, history]);

    return (
        <div className="wrapper">
            <ScrollToTop />
            <Helmet
                defaultTitle="Create a Free Online Obituary – Life Stories That Live On | Epilogg"
            />

            <MainHeader />

            {isFetchingProfile === true || hasFetchedProfile === false ? (
                <div className="main-content wrapper__activity-indicator">
                    <ActivityIndicator />
                </div>
            ) : (
                <div className="main-content">
                    <Switch>
                        <Route path="/auth" component={Auth} />
                        <Route path="/dashboard" component={requireAuth(Dashboard)} />
                        <Route path="/epilogg" component={Epilogg} />
                        <Route path="/404" component={Error404} />
                        <Route path="/:slug" component={View} />
                        <Redirect path="*" to="/404" />
                    </Switch>
                </div>
            )}

            <MainFooter />

            <Errors />
        </div>
    );
};

export default App;
