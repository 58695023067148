import './Create.scss';

import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import clone from "clone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { clearEpilogg, saveEpilogg, setEpilogg } from 'containers/Epilogg/store/actions';

import CreateHeader from "./components/CreateHeader";
import CreateSection from "./components/CreateSection";
import ActivityIndicator from "../../../components/ActivityIndicator";
import Modal from "../../../components/Modal";
import {LOCALSTORAGE} from "../../../utils/constants";
import { buildTitle } from '../../../utils/build-metadata';

let intervalSaveTimer;

const Create = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isFindingEpilogg, setIsFindingEpilogg] = useState(true);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    const { profile } = useSelector(store => store.auth);
    const { all, epilogg, isSavingEpilogg, shared } = useSelector(store => store.epiloggs);
    let { epiloggId } = useParams();
    epiloggId = parseInt(epiloggId);

    useEffect(() => {
        if(!epilogg.id || epilogg.id !== epiloggId) {
            let found = false;
            for(var i = 0; i < all.length; i++) {
                if(all[i].id === epiloggId) {
                    dispatch(setEpilogg(all[i]));
                    found = true;
                    setIsFindingEpilogg(false);
                    break;
                }
            }
            if(!found) {
                for(var i = 0; i < shared.length; i++) {
                    if(shared[i].id === epiloggId) {
                        dispatch(setEpilogg(shared[i]));
                        found = true;
                        setIsFindingEpilogg(false);
                        break;
                    }
                }
            }
            if(!found) {
                history.push('/dashboard');
            }
        } else {
            setIsFindingEpilogg(false);
        }

        testShowWelcomeModal();

        return () => {
            dispatch(clearEpilogg());
            window.clearTimeout(intervalSaveTimer);
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('create-page');

        return () => {
            document.body.classList.remove('create-page');
        }
    }, [epilogg.id]);

    useEffect(() => {
        if(isSavingEpilogg) {
            window.clearTimeout(intervalSaveTimer);
        }
    }, [isSavingEpilogg]);

    const generateTitle = () => {
        let title = 'edit epilogg';

        if(epilogg.displayName) {
            title += ` for ${epilogg.displayName}`;
        }

        return title;
    };

    const handleChange = (name, value) => {
        let newEpiloggObj = clone(epilogg);
        newEpiloggObj[name] = value;
        dispatch(setEpilogg(newEpiloggObj));
        setTimer();
    };

    const intervalSave = () => {
        if(!isSavingEpilogg && epilogg.id) {
            dispatch(saveEpilogg());
        }
    };

    const setTimer = () => {
        window.clearTimeout(intervalSaveTimer);
        intervalSaveTimer = setTimeout(intervalSave, 10000);
    };

    const testShowWelcomeModal = () => {
        let config = JSON.parse(localStorage.getItem(LOCALSTORAGE.CONFIG));

        if(config && config.userId === profile.id && config.hasSeenWelcomeModal === true) {
            return;
        }

        if(!config || config.userId !== profile.id) {
            config = {
                userId: profile.id,
                hasSeenWelcomeModal: true
            };
        } else {
            config.hasSeenWelcomeModal = true;
        }

        setShowWelcomeModal(true);
        localStorage.setItem(LOCALSTORAGE.CONFIG, JSON.stringify(config));
    };

    if(isFindingEpilogg) {
        return (
            <div className="create crate--finding">
                <ActivityIndicator />
            </div>
        );
    } else {
        return (
            <div className="create">
                <Helmet>
                    <title>{epilogg.id ? 'Edit' : 'Create'} Obituary | Epilogg</title>
                </Helmet>

                <CreateHeader
                    epilogg={epilogg}
                    setTimer={setTimer}
                />

                <div className="create__sections">
                    {epilogg.content.map((section, i) => (
                        <CreateSection
                            handleChange={handleChange}
                            key={section.id || i}
                            lastSection={i + 1 === epilogg.content.length}
                            section={section}
                            sectionIndex={i}
                        />
                    ))}
                </div>

                <Modal
                    confirmButtonOnClick={() => setShowWelcomeModal(false)}
                    confirmButtonText="OK"
                    show={showWelcomeModal}
                    title="Reminder!"
                >
                    An Epilogg can be short and sweet, with a few important details, or it can be full of photos and stories.  You get to choose! Come back to edit or update as many times as you’d like.
                </Modal>
            </div>
        );
    }
};

export default Create;
