import React, { useState } from 'react';

import Modal from 'components/Modal';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { clearErrors } from 'containers/Errors/store/actions';

const Errors = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [closeModal, setCloseModal] = useState(false);

    const errors = useSelector(store => store.errors.errors);

    const handleClose = async () => {
        for(var i = 0; i < errors.length; i++) {
            if(errors[i].status === 401 || (errors[i].response && errors[i].response.status === 401)) {
                history.push('/auth/logout');
                return;
            }
        }

        setCloseModal(true);
        setTimeout(() => {
            dispatch(clearErrors());
            setCloseModal(false);
        }, 500);
    };

    return (
        <Modal
            declineButtonOnClick={handleClose}
            declineButtonText="OK"
            show={errors && errors.length > 0 && !closeModal}
            title="Error"
        >
            <ul className="errors__list">
                {errors.map((err, i) => (
                    <li key={i}>
                        {err.friendlyMessage || err.message}
                    </li>
                ))}
            </ul>
        </Modal>
    );
};

export default Errors;
