import './Dashboard.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

import H1 from "../../components/H1";
import Button from "../../components/Button";
import EpiloggCard from "./components/EpiloggCard";
import Icon from "../../components/Icon";
import {createEpilogg, deleteEpilogg} from "../Epilogg/store/actions";
import ActivityIndicator from "../../components/ActivityIndicator";
import DeleteEpiloggModal from "../../components/Modal/DeleteEpiloggModal";


const Dashboard = ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { all: epiloggs, shared } = useSelector(store => store.epiloggs);
    const { isCreatingEpilogg, isDeletingEpilogg } = useSelector(store => store.epiloggs);

    const [filter, setFilter] = useState(epiloggs.length > 0 || shared.length > 0 ? 'all' : null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        document.body.classList.add('dashboard-page');

        return () => {
            document.body.classList.remove('dashboard-page');
        }
    }, []);

    const getCount = (status) => {
        return epiloggs.filter(epilogg => epilogg.publishStatus === status).length;
    };

    const getFilteredEpiloggs = (epiloggsToFilter) => {
        if(filter === 'all') {
            return epiloggsToFilter;
        } else if(filter === 'published') {
            return epiloggsToFilter.filter(epilogg => epilogg.publishStatus === 'published');
        } else {
            return epiloggsToFilter.filter(epilogg => epilogg.publishStatus !== 'published');
        }
    };

    const handleCreateNewEpilogg = async () => {
        try {
            const createRes = await dispatch(createEpilogg());
            history.push(`/epilogg/edit/${createRes.data.data.id}`);
        } catch(err) {
            console.log(err);
        }
    };

    const handleDeleteEpilogg = async () => {
        try {
            await dispatch(deleteEpilogg());
            history.push('/dashboard');
        } catch(err) {
            console.log(err);
        }
        setShowDeleteModal(false);
    };

    const handleFilterClick = (filter) => {
        setFilter(filter);
    };

    const publishedCount = getCount('published');
    const draftCount = epiloggs.length - publishedCount;
    const filteredEpiloggs = getFilteredEpiloggs(epiloggs);
    const filteredSharedEpiloggs = getFilteredEpiloggs(shared);

    return (
        <section className="dashboard">
            <Helmet>
                <title>Your Epiloggs | Epilogg</title>
            </Helmet>

            <div className="dashboard--inner">
                <nav className="dashboard__filters">
                    <Button
                        className={`dashboard__filter-button ${filter === 'all' ? 'dashboard__filter-button--is-active' : ''}`}
                        disabled={!epiloggs || epiloggs.length === 0}
                        onClick={() => handleFilterClick('all')}
                    >
                        All ({epiloggs.length})
                    </Button>

                    <Button
                        className={`dashboard__filter-button ${filter === 'draft' ? 'dashboard__filter-button--is-active' : ''}`}
                        disabled={draftCount === 0}
                        onClick={() => handleFilterClick('draft')}
                    >
                        Drafts ({draftCount})
                    </Button>

                    <Button
                        className={`dashboard__filter-button ${filter === 'published' ? 'dashboard__filter-button--is-active' : ''}`}
                        disabled={publishedCount === 0}
                        onClick={() => handleFilterClick('published')}
                    >
                        Published ({publishedCount})
                    </Button>
                </nav>

                <H1>
                    my epiloggs
                </H1>

                <ul className="dashboard__epilogg-list">
                    <li className="dashboard__create-new" onClick={handleCreateNewEpilogg}>
                        <div className="dashboard__create-new-icon">
                            <Icon type="plus" />
                        </div>
                        create an epilogg

                        {isCreatingEpilogg ? (
                            <div className="dashboard__create-new-is-creating">
                                <ActivityIndicator />
                            </div>
                        ) : null}
                    </li>

                    {filteredEpiloggs.map((epilogg) => (
                        <EpiloggCard
                            epilogg={epilogg}
                            key={epilogg.id}
                            onDeleteClick={() => setShowDeleteModal(true)}
                        />
                    ))}
                </ul>

                {filteredSharedEpiloggs?.length > 0 && (
                    <>
                        <H1>
                            shared with me
                        </H1>

                        <ul className="dashboard__epilogg-list">
                            {filteredSharedEpiloggs.map((epilogg) => (
                                <EpiloggCard
                                    epilogg={epilogg}
                                    key={epilogg.id}
                                    onDeleteClick={() => setShowDeleteModal(true)}
                                />
                            ))}
                        </ul>
                    </>
                )}
            </div>

            <DeleteEpiloggModal
                cancel={() => setShowDeleteModal(false)}
                confirm={handleDeleteEpilogg}
                isDeletingEpilogg={isDeletingEpilogg}
                show={showDeleteModal}
            />
        </section>
    );
};

Dashboard.propTypes = {

};

export default Dashboard;
