import clone from 'clone';

import Request from 'utils/request';
import PATHS from 'utils/paths';
import moment from 'moment';

import * as types from './constants';

import { generateFriendlyErrorString } from 'utils/data';

import { addError } from 'containers/Errors/store/actions';
import {EMPTY_EPILOGG} from "./reducers";

const PRIVATE_EPILOGG = {
    id: -1,
    displayName: 'Mr. Michael Martinez',
    content: [
        {
            id: -1,
            type: 'it',
            navTitle: 'First',
            image1Url: '/obituary/img/sample/image.jpg',
            text2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quam dui, dignissim gravida tempus et, malesuada vitae risus. Donec imperdiet magna.',
            title2: 'about or any headline/info'
        }, {
            id: -2,
            type: 'tt',
            navTitle: 'Second',
            text1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quam dui, dignissim gravida tempus et, malesuada vitae risus. Donec imperdiet magna.',
            title1: 'lorem ipsum dolor sit',
            text2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quam dui, dignissim gravida tempus et, malesuada vitae risus. Donec imperdiet magna.',
            title2: 'lorem ipsum dolor sit'
        }, {
            id: -3,
            type: 'ti',
            navTitle: 'Last',
            image2Url: '/obituary/mg/sample/image2.jpg',
            text1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quam dui, dignissim gravida tempus et, malesuada vitae risus. Donec imperdiet magna.',
            title1: 'about or any headline/info'
        }
    ],
    publishStatus: 'published',
    dateOfBirth: moment(new Date()).format(),
    dateOfDeath: moment(new Date()).format(),
    profilePicUrl: '/obituary/img/sample/avatar.jpg',
    bannerPicUrl: '/obituary/img/sample/hero.jpg'
};

export const clearEpilogg = () => ({
    type: types.CLEAR_EPILOGG
});

const createEpiloggStart = () => ({
    type: types.CREATE_EPILOGG_START
});

const createEpiloggSuccess = (data) => ({
    type: types.CREATE_EPILOGG_SUCCESS,
    data
});

const createEpiloggFailure = (error) => ({
    type: types.CREATE_EPILOGG_FAILURE,
    error
});

const deleteCommentStart = () => ({
    type: types.DELETE_COMMENT_START
});

const deleteCommentSuccess = () => ({
    type: types.DELETE_COMMENT_SUCCESS
});

const deleteCommentFailure = (error) => ({
    type: types.DELETE_COMMENT_FAILURE,
    error
});

const deleteEpiloggStart = () => ({
    type: types.DELETE_EPILOGG_START
});

const deleteEpiloggSuccess = (data) => ({
    type: types.DELETE_EPILOGG_SUCCESS,
    data
});

const deleteEpiloggFailure = (error) => ({
    type: types.DELETE_EPILOGG_FAILURE,
    error
});

const followEpiloggStart = () => ({
    type: types.FOLLOW_EPILOGG_START
});

const followEpiloggSuccess = (data) => ({
    type: types.FOLLOW_EPILOGG_SUCCESS,
    data
});

const followEpiloggFailure = (error) => ({
    type: types.FOLLOW_EPILOGG_FAILURE,
    error
});

const getEpiloggStart = () => ({
    type: types.GET_EPILOGG_START
});

const getEpiloggSuccess = (data) => ({
    type: types.GET_EPILOGG_SUCCESS,
    data
});

const getEpiloggFailure = (error) => ({
    type: types.GET_EPILOGG_FAILURE,
    error
});

const oneClickUnfollowEpiloggStart = () => ({
    type: types.ONE_CLICK_UNFOLLOW_EPILOGG_START
});

const oneClickUnfollowEpiloggSuccess = () => ({
    type: types.ONE_CLICK_UNFOLLOW_EPILOGG_SUCCESS
});

const oneClickUnfollowEpiloggFailure = (error) => ({
    type: types.ONE_CLICK_UNFOLLOW_EPILOGG_FAILURE,
    error
});

const publishEpiloggStart = () => ({
    type: types.PUBLISH_EPILOGG_START
});

const publishEpiloggSuccess = () => ({
    type: types.PUBLISH_EPILOGG_SUCCESS
});

const publishEpiloggFailure = (error) => ({
    type: types.PUBLISH_EPILOGG_FAILURE,
    error
});

const reportEpiloggStart = () => ({
    type: types.REPORT_EPILOGG_START
});

const reportEpiloggSuccess = () => ({
    type: types.REPORT_EPILOGG_SUCCESS
});

const reportEpiloggFailure = (error) => ({
    type: types.REPORT_EPILOGG_FAILURE,
    error
});

const saveEpiloggStart = () => ({
    type: types.SAVE_EPILOGG_START
});

const saveEpiloggSuccess = (data) => ({
    type: types.SAVE_EPILOGG_SUCCESS,
    data
});

const saveEpiloggFailure = (error) => ({
    type: types.SAVE_EPILOGG_FAILURE,
    error
});

const saveNewCommentStart = () => ({
    type: types.SAVE_NEW_COMMENT_START
});

const saveNewCommentSuccess = () => ({
    type: types.SAVE_NEW_COMMENT_SUCCESS
});

const saveNewCommentFailure = (error) => ({
    type: types.SAVE_NEW_COMMENT_FAILURE,
    error
});

export const setEpilogg = (data) => ({
    type: types.SET_EPILOGG,
    data
});

export const setAllEpiloggs = (data) => ({
    type: types.SET_ALL_EPILOGGS,
    data
});

export const setFollowEmail = (data) => ({
    type: types.SET_FOLLOW_EMAIL,
    data
});

export const setNewComment = (data) => ({
    type: types.SET_NEW_COMMENT,
    data
});

export const setReportReason = (data) => ({
    type: types.SET_REPORT_REASON,
    data
});

export const setSharedEpiloggs = (data) => ({
    type: types.SET_SHARED_EPILOGGS,
    data
});

export const setViewEpilogg = (data) => ({
    type: types.SET_VIEW_EPILOGG,
    data
});

export const createEpilogg = () => (dispatch, getStore) => {
    dispatch(createEpiloggStart());

    console.log('empty empilog', EMPTY_EPILOGG)

    const request = new Request(getStore().auth.token);
    return request.post(PATHS.epiloggs.create(), EMPTY_EPILOGG)
        .then((res) => {
            console.log(res);
            dispatch(createEpiloggSuccess(res.data.data));
            return res;
        })
        .catch((err) => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem creating an epilogg. Please try again.', err.response.data);
            dispatch(createEpiloggFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const deleteComment = (comment, isAdmin) => (dispatch, getStore) => {
    dispatch(deleteCommentStart());

    let epilogg = clone(getStore().epiloggs.epilogg);
    if(isAdmin) {
        epilogg = clone(getStore().epiloggs.viewEpilogg);
    }
    let contentIndex = epilogg.content.findIndex(c => c.type === 'g');
    let contentId = epilogg.content[contentIndex].id;

    for(var i = 0; i < epilogg.content[contentIndex].comments.length; i++) {
        if(epilogg.content[contentIndex].comments[i].id === comment.id) {
            epilogg.content[contentIndex].comments.splice(i, 1);
            break;
        }
    }

    dispatch(setEpilogg(epilogg));

    const request = new Request(getStore().auth.token);

    return request.delete(PATHS.epiloggs.deleteComment(epilogg.id, contentId, comment.id))
        .then(response => {
            dispatch(deleteCommentSuccess());
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem deleting this comment. Please try again.', err.response.data);
            dispatch(deleteCommentFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const deleteEpilogg = () => (dispatch, getStore) => {
    dispatch(deleteEpiloggStart());

    const epilogg = getStore().epiloggs.epilogg;

    const request = new Request(getStore().auth.token);

    return request.delete(PATHS.epiloggs.delete(epilogg.id))
        .then((res) => {
            console.log(res);
            let epiloggs = getStore().epiloggs.all;
            epiloggs = epiloggs.filter(ep => ep.id !== epilogg.id);
            dispatch(deleteEpiloggSuccess(epiloggs));
            return res;
        })
        .catch((err) => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem deleting this epilogg. Please try again.', err.response.data);
            dispatch(deleteEpiloggFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const followEpilogg = (emailAddress) => (dispatch, getStore) => {
    dispatch(followEpiloggStart());

    let epilogg = clone(getStore().epiloggs.viewEpilogg);
    epilogg.following = !epilogg.following;
    dispatch(setViewEpilogg(epilogg));

    const request = new Request(getStore().auth.token);

    let reqFunc = request.post;
    if(!emailAddress && epilogg.following === false) {
        reqFunc = request.delete;
    }

    let body = null;
    if(emailAddress) {
        body = {emailAddress};
    }

    return reqFunc(PATHS.epiloggs.follow(epilogg.id), body)
        .then(response => {
            dispatch(followEpiloggSuccess());
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem following this epilogg. Please try again.', err.response.data);
            dispatch(followEpiloggFailure(err));
            dispatch(addError(err));
            epilogg.following = !epilogg.following;
            dispatch(setViewEpilogg(epilogg));
            throw err;
        });
};

export const getEpilogg = (id) => (dispatch, getStore) => {
    dispatch(getEpiloggStart());

    const request = new Request(getStore().auth.token);

    return request.get(PATHS.epiloggs.getById(id))
        .then(response => {
            const epilogg = response.data.data;

            let epiloggs = clone(getStore().epiloggs.all);

            for(var i = 0; i < epiloggs.length; i++) {
                if(epiloggs[i].id === epilogg.id) {
                    epiloggs[i] = epilogg;
                    dispatch(setAllEpiloggs(epiloggs));
                    break;
                }
            }

            /*epilogg.content.forEach((c) => {
                c.thirdPartyId = '8byo9t';
                c.thirdPartyState = 'A';
            })*/

            dispatch(getEpiloggSuccess(epilogg));
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem getting this epilogg. Please try again.', err.response.data);
            dispatch(getEpiloggFailure(err));
            if(err.response.status === 403) {
                dispatch(setViewEpilogg(PRIVATE_EPILOGG));
            }
            if(err.response.status !== 403 && err.response.status !== 404) {
                dispatch(addError(err));
            }
            throw err;
        });
};

export const oneClickUnfollowEpilogg = (token) => (dispatch, getStore) => {
    dispatch(oneClickUnfollowEpiloggStart());

    const request = new Request();

    return request.post(PATHS.epiloggs.oneClickUnfollow(token))
        .then(response => {
            dispatch(oneClickUnfollowEpiloggSuccess());
            return response;
        })
        .catch(err => {
            console.log(err);
            if(err.response.status === 403) {
                err.friendlyMessage = 'Your token is invalid. Please try again.';
            } else {
                err.friendlyMessage = generateFriendlyErrorString('There was a problem with your request. Please try again.', err.response.data);

            }
            dispatch(oneClickUnfollowEpiloggFailure(err));
            throw err;
        });
};

export const publishEpilogg = (useViewEpilogg) => (dispatch, getStore) => {
    dispatch(publishEpiloggStart());

    let epilogg = clone(getStore().epiloggs.epilogg);
    if(useViewEpilogg) {
        epilogg = clone(getStore().epiloggs.viewEpilogg);
    }
    let epiloggs = clone(getStore().epiloggs.all);

    const request = new Request(getStore().auth.token);

    let reqFunc = request.post;

    if(epilogg.publishStatus === 'published') {
        reqFunc = request.delete;
        epilogg.publishStatus = 'unpublished';
    } else {
        epilogg.publishStatus = 'published';
    }

    dispatch(setEpilogg(epilogg));

    for(var i = 0; i < epiloggs.length; i++) {
        if(epiloggs[i].id === epilogg.id) {
            epiloggs[i] = epilogg;
            dispatch(setAllEpiloggs(epiloggs));
            break;
        }
    }

    return reqFunc(PATHS.epiloggs.publish(epilogg.id))
        .then(response => {
            console.log(response.data);
            dispatch(publishEpiloggSuccess());
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem saving this epilogg. Please try again.', err.response.data);
            dispatch(publishEpiloggFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const reportEpilogg = () => (dispatch, getStore) => {
    dispatch(reportEpiloggStart());

    const request = new Request(getStore().auth.token);

    return request.post(PATHS.epiloggs.report(getStore().epiloggs.viewEpilogg.id), {reason: getStore().epiloggs.reportReason})
        .then((res) => {
            console.log(res);
            dispatch(reportEpiloggSuccess());
            return res;
        })
        .catch((err) => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem reporting this epilogg. Please try again.', err.response.data);
            dispatch(reportEpiloggFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const saveEpilogg = () => (dispatch, getStore) => {
    dispatch(saveEpiloggStart());

    let epilogg = getStore().epiloggs.epilogg;

    const request = new Request(getStore().auth.token);

    let reqFunc = request.post;
    let path = PATHS.epiloggs.create();

    if(epilogg.id) {
        reqFunc = request.put;
        path = PATHS.epiloggs.update(epilogg.id);
    }

    return reqFunc(path, epilogg)
        .then(response => {
            let epiloggs = getStore().epiloggs.all;
            for(var i = 0; i < epiloggs.length; i++) {
                if(epiloggs[i].id === epilogg.id) {
                    epiloggs[i] = response.data.data;
                    setAllEpiloggs(epiloggs);
                    break;
                }
            }
            dispatch(saveEpiloggSuccess(response.data.data));
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem saving this epilogg. Please try again.', err.response.data);
            dispatch(saveEpiloggFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const saveNewComment = () => (dispatch, getStore) => {
    dispatch(saveNewCommentStart());

    let epilogg = getStore().epiloggs.viewEpilogg;
    let epiloggs = clone(getStore().epiloggs.all);
    let newComment = getStore().epiloggs.newComment;
    let token = getStore().auth.token;
    let contentId;

    for(var i = 0; i < epilogg.content.length; i++) {
        if(epilogg.content[i].type === 'g') {
            if(token) {
                newComment.user = getStore().auth.profile;
            }
            epilogg.content[i].comments.unshift(newComment);
            contentId = epilogg.content[i].id;
            break;
        }
    }

    dispatch(setViewEpilogg(epilogg));

    for(var i = 0; i < epiloggs.length; i++) {
        if(epiloggs[i].id === epilogg.id) {
            epiloggs[i] = epilogg;
            dispatch(setAllEpiloggs(epiloggs));
            break;
        }
    }

    const request = new Request(getStore().auth.token);

    return request.post(PATHS.epiloggs.newComment(epilogg.id, contentId), newComment)
        .then(response => {
            dispatch(saveNewCommentSuccess());
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem saving this comment. Please try again.', err.response.data);
            dispatch(saveNewCommentFailure(err));
            throw err;
        });
};
