import './AcceptInvitationToEdit.scss';

import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import { acceptInvitation } from 'containers/Epilogg/Invitations/store/actions';
import { getLoggedInUser } from 'containers/Auth/store/actions';
import { setTitle } from 'containers/App/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import Modal from 'components/Modal';
import Hero from 'components/Hero';

const AcceptInvitationToEdit = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const { token } = useSelector(store => store.auth);

    useEffect(() => {
        const acceptInvitationToEdit = async (token) => {
            try {
                await dispatch(acceptInvitation(token));
                setShowSuccessModal(true);
                dispatch(getLoggedInUser());
            } catch(err) {
                console.log('AcceptInvitationToEdit acceptInvitation err', err);
            }
        };

        dispatch(setTitle('accept invitation'));
        document.body.classList.add('email-verification-page');

        const qs = queryString.parse(location.search);

        if(qs.t && token) {
            acceptInvitationToEdit(qs.t);
        }

        return () => {
            document.body.classList.remove('email-verification-page');
        }
    }, [dispatch, history, location]);

    return (
        <div className="email-verification">
            <Helmet>
                <title>Accept Invitation to Edit | Epilogg</title>
            </Helmet>

            <Hero/>

            <div className="accept-invitation__content">
                <div className="email-verification__activity-indicator">
                    <ActivityIndicator/> Please wait
                </div>
            </div>

            <Modal
                declineButtonOnClick={() => {
                    setShowSuccessModal(false);
                    history.push('/dashboard');
                }}
                declineButtonText="Get started"
                show={showSuccessModal}
                title="Success!"
            >
                <p>
                    This invitation has been accepted. You're now an editor on this epilogg.
                </p>

                <div className="invitations-modal__one-editor-warning">
                    *We recommend one editor working at a time to ensure all content is saved correctly. Refresh to see latest version!
                </div>
            </Modal>
        </div>
    );
};

export default AcceptInvitationToEdit;
