import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

const DeleteEpiloggModal = ({cancel, confirm, isDeletingEpilogg, show}) => {
    return (
        <Modal
            confirmButtonOnClick={confirm}
            confirmButtonText="Delete"
            declineButtonOnClick={cancel}
            declineButtonText="Cancel"
            show={show}
            showActivityIndicator={isDeletingEpilogg}
            title="confirm delete"
        >
            deleting this epilogg cannot be undone. are you sure?
        </Modal>
    );
};

DeleteEpiloggModal.propTypes = {
    cancel: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    isDeletingEpilogg: PropTypes.bool,
    show: PropTypes.bool.isRequired
};

export default DeleteEpiloggModal;
