import React from 'react';
import PropTypes from 'prop-types';
import { validated } from 'react-custom-validation';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clone from 'clone';

import { isEmail, isRequired } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';
import { LOCALSTORAGE } from 'utils/constants';

import { clearCredentials, login, setCredentials } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import Form from 'components/Form';
import FormError from 'components/FormError';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';

let HeaderLoginForm = ({closeLogin, credentials, $field, $submit, $validation}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    let { loginError, isLoggingIn } = useSelector(store => store.auth);

    const handleChange = (name, value) => {
        let newCredentialsObj = clone(credentials);
        newCredentialsObj[name] = value;
        dispatch(setCredentials(newCredentialsObj));
    };

    const handleSubmit = (isValid) => {
        if(isValid === true) {
            dispatch(login())
                .then((res) => {
                    let redirectString = localStorage.getItem(LOCALSTORAGE.REDIRECT);
                    let redirectItems = redirectString ? redirectString.split('||') : null;
                    let redirect = `/dashboard`;
                    if(redirectItems && new Date().getTime() - parseInt(redirectItems[1], 10) <= 300000) {
                        redirect = redirectItems[0];
                    }
                    localStorage.removeItem(LOCALSTORAGE.REDIRECT);
                    history.push(redirect);
                    dispatch(clearCredentials());
                    closeLogin();
                })
                .catch((err) => {
                    if(err.response.status === 403) {
                        history.push('/auth/verify');
                        closeLogin();
                    }
                });
        }
    };

    return (
        <Form className="header-login-form">
            <FormRow>
                <Textbox
                    id="txtEmailAddress"
                    label="email"
                    name="email"
                    placeholder="email"
                    required
                    size="small"
                    theme="border"
                    type="email"
                    validation={$validation.email}
                    value={credentials.email || ''}
                    {...$field('email', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtPassword"
                    label="password"
                    name="password"
                    placeholder="password"
                    required
                    size="small"
                    theme="border"
                    type="password"
                    validation={$validation.password}
                    value={credentials.password || ''}
                    {...$field('password', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow className="header-login-form__forgot-password-link">
                <A to="/auth/forgot-password" onClick={closeLogin}>
                    forgot password?
                </A>
            </FormRow>

            <FormError
                error={loginError}
            />

            <FormRow className="header-login-form__button-wrapper">
                <Button
                    className="header-login-form__login-button"
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isLoggingIn}
                    type="submit"
                >
                    Login
                </Button>
            </FormRow>

            <FormRow className="header-login-form__create-account-link">
                <A to="/auth/signup" onClick={closeLogin}>
                    create an account
                </A>
            </FormRow>
        </Form>
    );
};

HeaderLoginForm.propTypes = {
    closeLogin:PropTypes.func.isRequired,
    credentials: PropTypes.object.isRequired
};

function headerLoginFormValidationConfig(props) {
    const { email, password } = props.credentials;

    return {
        fields: ['email', 'password'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            password: [
                [isRequired, password]
            ]
        }
    }
}


export default HeaderLoginForm = validated(headerLoginFormValidationConfig)(HeaderLoginForm);
