import './ViewActionMenu.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { deleteEpilogg, followEpilogg, publishEpilogg, setFollowEmail } from 'containers/Epilogg/store/actions';

import Bubble from 'components/Bubble';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DeleteEpiloggModal from 'components/Modal/DeleteEpiloggModal';
import FollowEmailForm from '../FollowEmailForm';
import Icon from 'components/Icon';
import ReportEpiloggModal from "../ReportEpiloggModal";
import Modal from "../../../../../components/Modal";
import ShareLinks from "../../../../../components/ShareLinks";

const ViewActionMenu = ({epilogg, isMyEpilogg}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showEmailForm, setShowEmailForm] = useState(false);
    const [showFollowSuccess, setShowFollowSuccess] = useState(false);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPublishDodModal, setShowPublishDodModal] = useState(false);

    const { profile: { isAdmin }, token } = useSelector(store => store.auth);
    const { followEmail, isDeletingEpilogg, isFollowingEpilogg, isSavingEpilogg } = useSelector(store => store.epiloggs);

    const handleDeleteEpilogg = async () => {
        try {
            await dispatch(deleteEpilogg());
            history.push('/dashboard');
        } catch(err) {
            console.log(err);
        }
        setShowDeleteModal(false);
    };

    const handleFollowCheckboxChange = async () => {
        try {
            await dispatch(followEpilogg());
            setShowFollowSuccess(true);
            setTimeout(() => setShowFollowSuccess(false), 3300);
        } catch(err) {

        }
    };

    const handleFollowViaEmail = async () => {
        try {
            await dispatch(followEpilogg(followEmail));
            setShowEmailForm(false);
            dispatch(setFollowEmail(''));
            setShowFollowSuccess(true);
            return await setTimeout(() => setShowFollowSuccess(false), 3300);
        } catch(err) {
            throw err;
        }
    };

    const handlePublicCheckboxChange = async () => {
        console.log('check', epilogg);
        if(!epilogg.dateOfDeath && epilogg.publishStatus !== 'published') {
            setShowPublishDodModal(true);
        } else {
            await dispatch(publishEpilogg(true));
        }
    };

    return (
        <div className="c-action-menu c-view-action-menu">

            {token ?
                !isMyEpilogg ? (
                    <Checkbox
                        checked={epilogg.following || false}
                        disabled={isFollowingEpilogg}
                        id="chkFollow"
                        label="+ follow"
                        name="following"
                        onChange={handleFollowCheckboxChange}
                    />
                ) : (
                    <>
                        <Checkbox
                            checked={epilogg.publishStatus === 'published'}
                            className={epilogg.publishStatus !== 'published' ? 'a-checkbox--is-not-published' : null}
                            disabled={isSavingEpilogg}
                            id="chkPublished"
                            label="publish?"
                            name="publishStatus"
                            onChange={handlePublicCheckboxChange}
                        />

                        <Button
                            noStyles
                            onClick={() => history.push(`/epilogg/edit/${epilogg.id}`)}
                        >
                            <Icon type="edit" /> edit
                        </Button>
                    </>
                )
            : (
                <Button
                    noStyles
                    onClick={() => {
                        dispatch(setFollowEmail(''));
                        setShowEmailForm(!showEmailForm);
                    }}
                >
                    <Icon type="plus-skinny" /> follow
                </Button>
            )}

            {!isMyEpilogg && (
                <Button
                    noStyles
                    onClick={() => setShowReportModal(true)}
                >
                    <Icon type="flag" /> report
                </Button>
            )}

            {!isMyEpilogg && isAdmin && (
                <Button
                    noStyles
                    onClick={() => setShowDeleteModal(true)}
                    showActivityIndicator={isDeletingEpilogg}
                >
                    <Icon type="delete" /> delete
                </Button>
            )}

            <Bubble
                className="followed-bubble"
                show={showFollowSuccess}
                text={epilogg.following || !token ? 'followed!' : 'unfollowed!'}
            />

            {epilogg.publishStatus === 'published' && (
                <Button
                    className="c-view-action-menu__share"
                    noStyles
                    onClick={() => setShowShareOptions(!showShareOptions)}
                    showActivityIndicator={isDeletingEpilogg}
                >
                    <Icon type="share" /> share
                </Button>
            )}

            <FollowEmailForm
                followEmail={followEmail}
                onSubmit={handleFollowViaEmail}
                show={showEmailForm}
            />

            <div className={`c-view-action-menu__share-options ${showShareOptions ? 'c-view-action-menu__share-options--show' : ''}`}>
                <ShareLinks epilogg={epilogg} />
            </div>

            <ReportEpiloggModal
                close={() => setShowReportModal(false)}
                show={showReportModal}
            />

            <DeleteEpiloggModal
                cancel={() => setShowDeleteModal(false)}
                confirm={handleDeleteEpilogg}
                isDeletingEpilogg={isDeletingEpilogg}
                show={showDeleteModal}
            />

            {isMyEpilogg && (
                <Modal
                    declineButtonOnClick={() => {
                        setShowPublishDodModal(false);
                    }}
                    declineButtonText="OK"
                    show={showPublishDodModal}
                    title="publish error"
                >
                    Date of death is required to publish.
                </Modal>
            )}
        </div>
    );
};

ViewActionMenu.propTypes = {
    epilogg: PropTypes.object.isRequired,
    isMyEpilogg: PropTypes.bool
};

export default ViewActionMenu;
