import './Modal.scss';

import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { MODAL_STYLE_RESET } from 'utils/constants';

import Button from 'components/Button';
import H5 from 'components/H5';
import Icon from 'components/Icon';

ReactModal.setAppElement('#root');

const Modal = ({children, className, confirmButtonOnClick, confirmButtonText, contentLabel, declineButtonOnClick, declineButtonText, show, showActivityIndicator, title}) => (
    <ReactModal className={className}
                closeTimeoutMS={200}
                contentLabel={contentLabel || 'Modal'}
                isOpen={show === true}
                onRequestClose={() => declineButtonOnClick ? declineButtonOnClick() : confirmButtonOnClick()}
                style={MODAL_STYLE_RESET}>
        <div className="c-modal__content--wrapper">
            {title != null ? (
                <H5 className="c-modal__title">
                    {title}

                    {confirmButtonOnClick || declineButtonOnClick ? (
                        <Button className="c-modal__close-button"
                                onClick={() => declineButtonOnClick ? declineButtonOnClick() : confirmButtonOnClick()}>
                            <Icon type="x" />
                        </Button>
                    ) : null}
                </H5>
            ) : null}

            <div className="c-modal__content">
                {children}
            </div>

            {confirmButtonText || declineButtonText ? (
                <div className="c-modal__button-wrapper">
                    {confirmButtonText && confirmButtonOnClick ? (
                        <Button className="c-modal__confirm-button"
                                onClick={confirmButtonOnClick}
                                showActivityIndicator={showActivityIndicator}>
                            {confirmButtonText}
                        </Button>
                    ) : null}

                    {declineButtonText && declineButtonOnClick ? (
                        <Button className="c-modal__decline-button"
                                onClick={declineButtonOnClick}>
                            {declineButtonText}
                        </Button>
                    ) : null}
                </div>
            ) : null}
        </div>
    </ReactModal>
);

Modal.propTypes = {
    className: PropTypes.string,
    confirmButtonOnClick: PropTypes.func,
    confirmButtonText: PropTypes.string,
    contentLabel: PropTypes.string,
    declineButtonOnClick: PropTypes.func,
    declineButtonText: PropTypes.string,
    show: PropTypes.bool.isRequired,
    showActivityIndicator: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
};

export default Modal;
