import './Bubble.scss';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Bubble = ({children, className, show, text}) => {
    const ref = useRef();

    useEffect(() => {
        if(show) {
            ref.current.classList.add('a-bubble--show');
            setTimeout(() => ref.current.classList.add('a-bubble--animate'), 1);
        } else {
            ref.current.classList.remove('a-bubble--show');
            ref.current.classList.remove('a-bubble--animate');
        }
    }, [show]);

    return (
        <div className={`c-bubble--wrapper ${className || ''}`}>
            <div
                className="c-bubble"
                ref={ref}
            >
                {children || text}
            </div>
        </div>
    );
};

Bubble.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
};

export default Bubble;
