import './HeaderLogin.scss';

import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

import HeaderLoginForm from './HeaderLoginForm';

const HeaderLogin = ({closeLogin, showLogin}) => {
    const credentials = useSelector(store => store.auth.credentials);

    useEffect(() => {


        return () => {

        }
    }, []);

    if(showLogin) {
        return (
            <>
                <div
                    className="c-header-login"
                    onClick={() => closeLogin()}
                />

                <HeaderLoginForm
                    closeLogin={closeLogin}
                    credentials={credentials}
                />
            </>
        );
    } else {
        return null;
    }
};

HeaderLogin.propTypes = {
    closeLogin: PropTypes.func.isRequired,
    showLogin: PropTypes.bool
};

export default HeaderLogin;
