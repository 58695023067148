import * as types from './constants';
import PATHS from "../../../../utils/paths";
import Request from 'utils/request';

import { generateFriendlyErrorString } from "../../../../utils/data";
import {addError} from "../../../Errors/store/actions";

const acceptInvitationStart = () => ({
    type: types.ACCEPT_INVITATION_START,
});

const acceptInvitationSuccess = (data) => ({
    type: types.ACCEPT_INVITATION_SUCCESS
});

const acceptInvitationFailure = (error) => ({
    type: types.ACCEPT_INVITATION_FAILURE,
    error
});


const inviteEditorStart = () => ({
    type: types.INVITE_EDITOR_START,
});

const inviteEditorSuccess = (data) => ({
    type: types.INVITE_EDITOR_SUCCESS,
    data
});

const inviteEditorFailure = (error) => ({
    type: types.INVITE_EDITOR_FAILURE,
    error
});


const removeEditorStart = () => ({
    type: types.REMOVE_EDITOR_START,
});

const removeEditorSuccess = (data) => ({
    type: types.REMOVE_EDITOR_SUCCESS,
    data
});

const removeEditorFailure = (error) => ({
    type: types.REMOVE_EDITOR_FAILURE,
    error
});


export const setEmailToInvite = (data) => ({
    type: types.SET_EMAIL_TO_INVITE,
    data
});

export const acceptInvitation = (token) => (dispatch, getStore) => {
    dispatch(acceptInvitationStart());

    const request = new Request(getStore().auth.token);

    return request.post(PATHS.invitations.accept(token))
        .then((res) => {
            dispatch(acceptInvitationSuccess());
            return res;
        })
        .catch((err) => {
            console.log(err.response.data);
            err.friendlyMessage = generateFriendlyErrorString(`There was a problem accepting your invitation to edit: ${err.response.data.error}`, err.response.data);
            dispatch(addError(err));
            dispatch(acceptInvitationFailure(err));
            throw err;
        });
};

export const inviteEditor = (email) => (dispatch, getStore) => {
    dispatch(inviteEditorStart());

    const request = new Request(getStore().auth.token);
    const epiloggId = getStore().epiloggs.epilogg.id;

    if(!email) {
        email = getStore().invitations.emailToInvite;
    }

    return request.post(PATHS.invitations.add(epiloggId), {email})
        .then((res) => {
            dispatch(inviteEditorSuccess({email, status: 'invited'}));
            return res;
        })
        .catch((err) => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('There was a problem inviting a new editor. Please try again.', err.response.data);
            dispatch(inviteEditorFailure(err));
            throw err;
        });
};

export const removeEditor = (email) => (dispatch, getStore) => {
    dispatch(removeEditorStart());

    const request = new Request(getStore().auth.token);
    const epiloggId = getStore().epiloggs.epilogg.id;

    return request.post(PATHS.invitations.remove(epiloggId), {email})
        .then((res) => {
            dispatch(removeEditorSuccess(email));
            return res;
        })
        .catch((err) => {
            err.friendlyMessage = generateFriendlyErrorString('There was a problem removing this editor. Please try again.', err.response.data);
            dispatch(removeEditorFailure(err));
            throw err;
        });
};
