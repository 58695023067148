import './CreateGuestbook.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import { deleteComment } from 'containers/Epilogg/store/actions';

import Guestbook from 'components/Guestbook';
import Modal from 'components/Modal';

const CreateGuestbook = ({disabled, epilogg, section, sectionIndex}) => {
    const dispatch = useDispatch();
    const [comment, setComment] = useState({});
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const { isDeletingComment } = useSelector(store => store.epiloggs);
    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const confirmDeleteComment = async () => {
        await dispatch(deleteComment(comment));
        setShowConfirmDeleteModal(false);
    };

    const handleDeletePress = (c) => {
        setComment(c);
        setShowConfirmDeleteModal(true);
    };

    return (
        <div className="create-guestbook">
            <Guestbook
                comments={section.comments || []}
                disabled={disabled}
                handleDeletePress={handleDeletePress}
            />

            <Modal
                showActivityIndicator={isDeletingComment}
                confirmButtonOnClick={confirmDeleteComment}
                confirmButtonText="remove"
                declineButtonOnClick={() => {
                    setShowConfirmDeleteModal(false);
                    setComment({});
                }}
                declineButtonText="cancel"
                show={showConfirmDeleteModal}
                title="confirm delete"
            >
                Are you sure you want to remove this comment? This action cannot be undone.
            </Modal>
        </div>
    );
};

CreateGuestbook.propTypes = {
    epilogg: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
    sectionIndex: PropTypes.number.isRequired
};

export default CreateGuestbook;
