import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

const FileUploadErrorModal = ({close, show}) => {
    return (
        <Modal
            declineButtonOnClick={() => close(false)}
            declineButtonText="OK"
            show={show}
            title="Incorrect file type or number of files"
        >
            <p>
                Select a single image file for upload.
            </p>
            <p>
                Allowed formats: JPEG, PNG, GIF, WebP, HEIC, HEIF
            </p>
        </Modal>
    );
};

FileUploadErrorModal.propTypes = {
    close: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

export default FileUploadErrorModal;
