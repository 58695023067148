import './ViewSection.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isURL from 'validator/lib/isURL';

import {IMAGE_SIZES, TYPES} from 'utils/constants';

import A from 'components/A';
import FamilyMember from 'containers/Epilogg/components/FamilyMember';
import H1 from 'components/H1';
import H2 from 'components/H2';
import ParseLinks from 'components/ParseLinks';
import ViewGuestbook from '../ViewGuestbook';
import ViewMemorialService from "../ViewMemorialService";
import ViewFlowers from '../ViewFlowers';
import ViewDonation from '../ViewDonation';
import ViewPlantATree from "../ViewPlantATree";

const ViewSection = ({index, section}) => {
    const classes = classNames(
        'c-view-section',
        `a-view-section--${section.type}`,
        {
            'a-view-section--default': section.isDefault
        }
    );

    if((section.type !== 'd' && section.type !== 'g' && section.type !== 'f' && section.type !== 'm' && section.type !== 'flower' && section.type !== 'plant_a_tree') &&
        !section.image1Artifact &&
        !section.image2Artifact &&
        !section.image3Artifact &&
        !section.text1 &&
        !section.text2 &&
        !section.text3 &&
        !section.title1 &&
        !section.title2 &&
        !section.title3
    ) {
        // There is no content so return
        return null;
    } else if(section.type === 'f' && (!section.people || section.people.length === 0)) {
        // There are no people in the family array
        return null;
    } else if(section.type === 'd' && !section.text1 && !section.title1) {
        return null;
    } else if(section.type === 'm' &&
        !section.title1 &&
        !section.text1 &&
        !section.eventDate &&
        !section.eventTime &&
        !section.funeralHomeName &&
        !section.locationName
    ) {
        return null;
    }

    const generateSubtitle = () => {
        if(section.type === 'g') {
            return section.subtitle || 'guestbook';
        } else if(section.isDefault && section.type === 'it') {
            return 'life story';
        } else if(section.type === 'd') {
            return 'memorial donations';
        } else if(section.type === 'flower') {
            return 'support';
        } else if(section.type === 'plant_a_tree') {
            return 'honor';
        } else {
            return section.subtitle;
        }
    };

    const subtitle = generateSubtitle();

    return (
        <section className={classes} data-id={section.id}>
            <div className="c-view-section--inner">
                {subtitle ? (
                    <H1 className="c-view-section__subtitle">
                        {subtitle}
                    </H1>
                ) : null}

                {TYPES[section.type].map((column, i) => {
                    switch(column) {
                        case 'donation':
                            return (
                                <ViewDonation
                                    key={i}
                                    section={section}
                                />
                            );
                        case 'image':
                            let url = section[`image${i + 1}Artifact`] ? section[`image${i + 1}Artifact`].url : null;
                            if(url) {
                                url = section[`image${i + 1}Artifact`].url.replace('/original/', IMAGE_SIZES.PICTURE);
                                return (
                                    <div className="c-view-section__image-wrapper">
                                        <div className="c-view-section__image" key={i}>
                                            <img src={url} alt="" />
                                        </div>

                                        {section[`text${i + 1}`] ? (
                                            <ParseLinks>
                                                <div className="c-view-section__image-caption">
                                                    {section[`text${i + 1}`].split('\n').map((paragraph, paragraphIndex) => {
                                                        if (paragraph) {
                                                            return (
                                                                <p key={paragraphIndex}>
                                                                    {paragraph}
                                                                </p>
                                                            )
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            </ParseLinks>
                                        ) : null}
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        case 'text':
                            const text = section[`text${i + 1}`];
                            const title = section[`title${i + 1}`];

                            if(text || title) {
                                return (
                                    <div className="c-view-section__text" key={i}>
                                        <div className="c-view-section__text--inner">
                                            {title ? (
                                                <H2 className="c-view-section__text-title">
                                                    {title}
                                                </H2>
                                            ) : null}

                                            {text ? (
                                                <ParseLinks>
                                                    <div className="c-view-section__text-text">
                                                        {text.split('\n').map((paragraph, paragraphIndex) => {
                                                            if (paragraph) {
                                                                return (
                                                                    <p key={paragraphIndex}>
                                                                        {paragraph}
                                                                    </p>
                                                                )
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </div>
                                                </ParseLinks>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        case 'family':
                            if(section.people && section.people.length > 0) {
                                return (
                                    <div className="c-view-section__family" key={i}>
                                        {section.people.map((person) => (
                                            <FamilyMember
                                                key={person.id}
                                                person={person}
                                            />
                                        ))}

                                        <div className="c-family-member a-family-member--empty" />
                                        <div className="c-family-member a-family-member--empty" />
                                        <div className="c-family-member a-family-member--empty" />
                                        <div className="c-family-member a-family-member--empty" />
                                        <div className="c-family-member a-family-member--empty" />
                                    </div>
                                );
                            } else {
                                return null;
                            }

                        case 'flower':
                            return (
                                <ViewFlowers
                                    key={i}
                                    section={section}
                                />
                            );
                        case 'guestbook':
                            return (
                                <ViewGuestbook
                                    key={i}
                                    section={section}
                                />
                            );
                        case 'memorial':
                            return (
                                <ViewMemorialService
                                    key={i}
                                    section={section}
                                />
                            );
                        case 'plant_a_tree':
                            return (
                                <ViewPlantATree
                                    key={i}
                                    index={index}
                                    section={section}
                                />
                            )
                        default:
                            return null;
                    }
                })}
            </div>
        </section>
    );
};

ViewSection.propTypes = {
    section: PropTypes.object.isRequired
};

export default ViewSection;
