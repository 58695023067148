export const resizeImage = async (file, maxSize = 800) => {
    return new Promise((resolve, reject) => {
        try {
            let reader = new FileReader();
            reader.onload = function (readerEvent) {
                let image = new Image();
                image.onload = function (imageEvent) {
                    // Resize the image
                    let canvas = document.createElement('canvas'),
                        mime = 'image/jpeg',
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    canvas.toBlob((blob) => {
                        const resizedImage = new File([blob], file.name, {
                            type: mime,
                            lastModified: Date.now()
                        }); //output image as a file
                        resolve(resizedImage);
                    }, mime, .6);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        } catch(err) {
            reject(err);
        }
    });
};

export const getImageDimensions = async (file) => {
    return new Promise((resolve, reject) => {
        try {
            let img = document.createElement('img');
            let blob = URL.createObjectURL(file);
            img.src = blob;
            img.onload = () => {
                console.log(img.height, img.width);
                file.height = img.height;
                file.width = img.width;
                resolve(file);
            };
        } catch(err) {
            reject(err);
        }
    });

}
