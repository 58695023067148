import * as types from './constants';

export const clearErrors = () => ({
    type: types.CLEAR_ERRORS,
});

const setErrors = (data) => ({
    type: types.SET_ERRORS,
    data,
});

export const resetErrors = () => ({
    type: types.RESET_ERRORS,
});

export const addError = (error) => (dispatch, getStore) => {
    let errors = getStore().errors.errors;

    for(var i = 0; i < errors.length; i++) {
        if(errors[i].friendlyMessage === error.friendlyMessage) {
            return;
        }
    }

    errors = errors.concat([error]);
    if(errors.length > 5) {
        errors = errors.slice(1, 5);
    }

    dispatch(setErrors(errors));
};
