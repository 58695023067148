import './ForgotPassword.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { setTitle } from 'containers/App/store/actions';

import ForgotPasswordForm from './ForgotPasswordForm';
import H3 from 'components/H3';

const ForgotPassword = () => {
    const dispatch = useDispatch();

    const credentials = useSelector(store => store.auth.credentials);

    useEffect(() => {
        document.body.classList.add('forgot-password-page');
        dispatch(setTitle('reset your password'));

        return () => {
            document.body.classList.remove('forgot-password-page');
        }
    }, [dispatch]);

    return (
        <div className="forgot-password-form--wrapper">
            <Helmet>
                <title>Reset Your Epilogg Password</title>
            </Helmet>

            <H3>
                enter your email address to begin
            </H3>

            <ForgotPasswordForm
                credentials={credentials}
            />
        </div>
    );
};

export default ForgotPassword;
