import './EpiloggCard.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { IMAGE_SIZES } from 'utils/constants';

import { publishEpilogg, setEpilogg } from "../../../Epilogg/store/actions";

import Icon from 'components/Icon';
import A from "../../../../components/A";
import Button from "../../../../components/Button";

const EpiloggCard = ({epilogg, onDeleteClick}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isPublishingEpilogg } = useSelector(store => store.epiloggs);
    const createEpilogg = useSelector(store => store.epiloggs.epilogg);

    const [isPublishing, setIsPublishing] = useState(false);

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        dispatch(setEpilogg(epilogg));
        onDeleteClick();
    };

    const handleEditClick = (event) => {
        event.stopPropagation();

        dispatch(setEpilogg(epilogg));
    };

    const handlePublishClick = async (event) => {
        event.stopPropagation();

        setIsPublishing(true);

        if(createEpilogg.id !== epilogg.id) {
            dispatch(setEpilogg(epilogg));
        }

        try {
            await dispatch(publishEpilogg());
        } catch(err) {

        }

        setIsPublishing(false);
    };

    const handleViewClick = () => {
        dispatch(setEpilogg(epilogg));
        history.push(`/${epilogg.slug}`);
    };

    const findLatestDate = () => {
        let latest = epilogg.updatedAt;
        if(epilogg.content) {
            epilogg.content.forEach((c) => {
                if(c.updatedAt > latest) {
                    latest = c.updatedAt;
                }
            })
        }
        return latest;
    };

    return (
        <li className="c-epilogg-card" onClick={handleViewClick} title="click to preview epilogg">
            <header
                className="c-epilogg-card__header"
                style={epilogg.bannerPicArtifact && epilogg.bannerPicArtifact.url ? {backgroundImage: `url(${epilogg.bannerPicArtifact.url.replace('/original/', IMAGE_SIZES.BANNER)})`} : (epilogg?.id ? {backgroundImage: `url(/obituary/img/nobanner/bg${epilogg.id % 4}.jpg)`} : null)}
            >
                <div className="c-epilogg-card__avatar" style={epilogg.profilePicArtifact && epilogg.profilePicArtifact.url ? {backgroundImage: `url(${epilogg.profilePicArtifact.url.replace('/original/', IMAGE_SIZES.AVATAR)})`} : {backgroundImage: `url(/obituary/img/noimage/default_avatar_img1.svg)`}} />

                {epilogg.publishStatus !== 'published' ? (
                    <div className="c-epilogg-card__status--draft">
                        DRAFT
                    </div>
                ) : null}
            </header>

            <div className="c-epilogg-card__content-wrapper">
                <div className="c-epilogg-card__content">
                    <h2 className="c-epilogg-card__name">
                        {epilogg.displayName}
                    </h2>

                    <div className="c-epilogg-card__location">
                        {epilogg.locationAddress}
                    </div>

                    <div className="c-epilogg-card__date c-epilogg-card__updated-at">
                        Updated on: <span>{moment(findLatestDate()).format('MM/DD/YYYY')}</span>
                    </div>

                    <div className="c-epilogg-card__date">
                        Created on: <span>{moment(epilogg.createdAt).format('MM/DD/YYYY')}</span>
                    </div>
                </div>

                <div className="c-epilogg-card__actions">
                    <div className="c-epilogg-card__links">
                        <A to={`/epilogg/edit/${epilogg.id}`} onClick={handleEditClick}>EDIT</A>
                        {' '}
                        <Button
                            className="c-link"
                            noStyles
                            onClick={handleDeleteClick}
                        >
                            DELETE
                        </Button>
                    </div>

                    {epilogg.publishStatus !== 'published' ? (
                        <Button
                            disabled={isPublishingEpilogg}
                            onClick={handlePublishClick}
                            showActivityIndicator={isPublishing}
                        >
                            Publish
                        </Button>
                    ) : null}
                </div>
            </div>
        </li>
    );
};

EpiloggCard.propTypes = {
    epilogg: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired
};

export default EpiloggCard;
