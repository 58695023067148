import './Unfollow.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";

import { setTitle } from 'containers/App/store/actions';
import { oneClickUnfollowEpilogg } from 'containers/Epilogg/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import Hero from 'components/Hero';

const Unfollow = () => {
    const dispatch = useDispatch();
    const { token } = useParams();
    const [showSuccessText, setShowSuccessText] = useState(false);

    const { isUnfollowingEpilogg, oneClickUnfollowEpiloggError } = useSelector(store => store.epiloggs);

    useEffect(() => {
        const unfollow = async () => {
            try {
                await dispatch(oneClickUnfollowEpilogg(token));
                setShowSuccessText(true);
            } catch(err) {
                console.log(err);
            }

        };

        document.body.classList.add('unfollow-page');
        dispatch(setTitle('unfollow epilogg'));
        unfollow();

        return () => {
            document.body.classList.remove('unfollow-page');
        }
    }, [dispatch, token]);

    return (
        <div className="unfollow">
            <Helmet>
                <title>Unfollow</title>
            </Helmet>

            <Hero />

            {isUnfollowingEpilogg ? (
                <div className="unfollow__activity-indicator">
                    <ActivityIndicator /> Thank you for your request. Please wait.
                </div>
            ) : (
                <div className="unfollow__content">
                    {showSuccessText ? (
                        <p>
                            Thank you. Your request to unfollow an epilogg is complete.
                        </p>
                    ) : (
                        <p>
                            {oneClickUnfollowEpiloggError.friendlyMessage || oneClickUnfollowEpiloggError.message}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Unfollow;
