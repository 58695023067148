import './ActionMenu.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import clone from 'clone';
import { useHistory } from "react-router";

import {deleteEpilogg, publishEpilogg, saveEpilogg, setEpilogg} from 'containers/Epilogg/store/actions';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import DeleteEpiloggModal from 'components/Modal/DeleteEpiloggModal';
import Modal from "../../../../../components/Modal";
import InvitationsModal from '../../../Invitations/InvitationsModal';

const ActionMenu = ({section, sectionIndex}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showActivityIndicator, setShowActivityIndicator] = useState(false);
    const [showAddEditorsModal, setShowAddEditorsModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPublishDodModal, setShowPublishDodModal] = useState(false);

    const { epilogg, isDeletingEpilogg, isSavingEpilogg } = useSelector(store => store.epiloggs);
    const { emailToInvite } = useSelector(store => store.invitations);

    const handleDeleteEpilogg = async () => {
        try {
            await dispatch(deleteEpilogg());
            history.push('/dashboard');
        } catch(err) {
            console.log(err);
        }
        setShowDeleteModal(false);
    };

    const handleDeleteSection = () => {
        let newEpiloggObj = clone(epilogg);
        if(section.id) {
            newEpiloggObj.content = epilogg.content.filter(s => s.id !== section.id);
        } else {
            newEpiloggObj.content.splice(sectionIndex, 1);
        }
        dispatch(setEpilogg(newEpiloggObj));
        dispatch(saveEpilogg());
    };

    const handleMoveUp = () => {
        let newEpiloggObj = clone(epilogg);
        let newSectionsArray = clone(newEpiloggObj.content);
        newSectionsArray.splice(sectionIndex, 1);
        newSectionsArray.splice(sectionIndex - 1, 0, section);
        console.log(epilogg.content, newSectionsArray);
        newEpiloggObj.content = newSectionsArray;
        dispatch(setEpilogg(newEpiloggObj));
        dispatch(saveEpilogg());
    };

    const handleMoveDown = () => {
        let newEpiloggObj = clone(epilogg);
        let newSectionsArray = clone(newEpiloggObj.content);
        newSectionsArray.splice(sectionIndex, 1);
        newSectionsArray.splice(sectionIndex + 1, 0, section);
        console.log(epilogg.content, newSectionsArray);
        newEpiloggObj.content = newSectionsArray;
        dispatch(setEpilogg(newEpiloggObj));
        dispatch(saveEpilogg());
    };

    const handleSave = async () => {
        setShowActivityIndicator(true);
        await dispatch(saveEpilogg());
        setShowActivityIndicator(false);
    };

    const handlePublicCheckboxChange = async () => {
        if((!epilogg.dateOfDeath || (!epilogg.locationName && !epilogg.locationAddress)) && epilogg.publishStatus !== 'published') {
            setShowPublishDodModal(true);
        } else {
            await dispatch(publishEpilogg());
        }
    };

    return (
        <div className="c-action-menu">
            <Button
                disabled={(isSavingEpilogg && !showActivityIndicator) || isDeletingEpilogg}
                noStyles
                showActivityIndicator={showActivityIndicator}
                onClick={handleSave}
            >
                <Icon type="save" /> save
            </Button>

            {section ? (
                <>
                    {section.type !== 'g' ? (
                        <Button
                            disabled={!section || isSavingEpilogg || isDeletingEpilogg}
                            noStyles
                            onClick={handleDeleteSection}
                        >
                            <Icon type="delete" /> delete
                        </Button>
                    ) : null}

                    <div className="c-action-menu__move-buttons">
                        <Button
                            disabled={sectionIndex === 0 || isSavingEpilogg || isDeletingEpilogg}
                            noStyles
                            onClick={handleMoveUp}
                        >
                            <Icon type="circle-up" />
                        </Button>

                        <Button
                            disabled={sectionIndex + 1 === epilogg.content.length || isSavingEpilogg || isDeletingEpilogg}
                            noStyles
                            onClick={handleMoveDown}
                        >
                            <Icon type="circle-down" />
                        </Button>

                        <span style={{opacity: !section || isSavingEpilogg || isDeletingEpilogg ? .4 : 1}}>
                            move section
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <Button
                        disabled={isDeletingEpilogg || isSavingEpilogg}
                        noStyles
                        onClick={async () => {
                            setShowActivityIndicator(true);
                            await dispatch(saveEpilogg());
                            setShowActivityIndicator(false);
                            history.push(`/${epilogg.slug}`);
                        }}
                    >
                        <Icon type="preview" /> preview
                    </Button>

                    <Button
                        disabled={isSavingEpilogg}
                        noStyles
                        onClick={() => setShowDeleteModal(true)}
                        showActivityIndicator={isDeletingEpilogg}
                    >
                        <Icon type="delete" /> delete
                    </Button>

                    <Button
                        disabled={isSavingEpilogg}
                        noStyles
                        onClick={() => setShowAddEditorsModal(true)}
                    >
                        <Icon type="share-editing" /> editors
                    </Button>

                    <Checkbox
                        checked={epilogg.publishStatus === 'published'}
                        disabled={isSavingEpilogg || isDeletingEpilogg}
                        id="chkPublished"
                        label="publish?"
                        name="publishStatus"
                        onChange={handlePublicCheckboxChange}
                    />
                </>
            )}

            <DeleteEpiloggModal
                cancel={() => setShowDeleteModal(false)}
                confirm={handleDeleteEpilogg}
                isDeletingEpilogg={isDeletingEpilogg}
                show={showDeleteModal}
            />

            {!section && (
                <>
                    <Modal
                        declineButtonOnClick={() => {
                            setShowPublishDodModal(false);
                        }}
                        declineButtonText="OK"
                        show={showPublishDodModal}
                        title="publish error"
                    >
                        {!epilogg.dateOfDeath && (
                            <p>
                                <strong>Date of death</strong> is required to publish.
                            </p>
                        )}
                        {(!epilogg.locationName && !epilogg.locationAddress) && (
                            <p>
                                <strong>Location</strong> is required to publish.
                            </p>
                        )}
                    </Modal>

                    <InvitationsModal
                        close={() => setShowAddEditorsModal(false)}
                        emailToInvite={emailToInvite}
                        show={showAddEditorsModal}
                    />
                </>
            )}
        </div>
    );
};

ActionMenu.propTypes = {
    section: PropTypes.object,
    sectionIndex: PropTypes.number
};

export default ActionMenu;
