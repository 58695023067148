import './ViewMemorialService.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getTimezoneAbbreviation } from 'utils/utils';

import A from 'components/A';
import H2 from 'components/H2';
import H5 from 'components/H5';
import Map from 'components/Map';
import ParseLinks from 'components/ParseLinks';

const ViewMemorialService = ({section}) => {
    return (
        <div className="c-view-section__memorial-service">
            <div className="c-view-section--inner">
                <div className="c-view-section__text">
                    <div className="c-view-section__text--inner">
                        {section.title1 ? (
                            <H2 className="c-view-section__text-title">
                                {section.title1}
                            </H2>
                        ) : null}

                        {section.locationName || section.locationAddress || section.eventDate ? (
                            <div className="c-view-section__memorial-service-location-time">
                                <H5>
                                    Time & Location:
                                </H5>

                                {section.eventDate ? (
                                    <div className="c-view-section__memorial-service-time">
                                        {moment(section.eventDate.substring(0, 10)).format('MMMM DD, YYYY')} {section.eventTime ? `at ${moment(section.eventTime).format('h:mma')}` : ''} {getTimezoneAbbreviation(new Date())}
                                    </div>
                                ) : null}

                                {section.locationName || section.locationAddress ? (
                                    <div className="c-view-section__memorial-service-location">
                                        {section.locationName} {section.locationAddress}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {section.locationAddress ? (
                            <div className="c-view-section__memorial-service-directions">
                                <A className="c-button" href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(section.locationAddress)}`}>Directions</A>
                            </div>
                        ) : null}

                        {section.text1 ? (
                            <ParseLinks>
                                <div className="c-view-section__text-text">
                                    {section.text1.split('\n').map((paragraph, paragraphIndex) => {
                                        if (paragraph) {
                                            return (
                                                <p key={paragraphIndex}>
                                                    {paragraph}
                                                </p>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </ParseLinks>
                        ) : null}

                        {section.funeralHomeName || section.funeralHomeAddress ? (
                            <div className="c-view-section__memorial-service-location-time c-view-section__memorial-service-funeral">
                                <H5>
                                    Funeral/Cremation Provider:
                                </H5>

                                {section.funeralHomeName || section.funeralHomeAddress ? (
                                    <div className="c-view-section__memorial-service-location">
                                        {section.funeralHomeName} {section.funeralHomeAddress}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {section.funeralHomeAddress || section.funeralHomeWebsite ? (
                            <div className="c-view-section__memorial-service-directions">
                                {section.funeralHomeWebsite ? (
                                    <A className="c-button" href={section.funeralHomeWebsite}>Website</A>
                                ) : null}

                                {section.funeralHomeAddress ? (
                                    <A className="c-button" href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(section.funeralHomeAddress)}`}>Directions</A>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="c-view-section__memorial-service-map">
                    {section.locationLatitude && section.locationLongitude ? (
                        <Map
                            location={{lat: section.locationLatitude, lng: section.locationLongitude}}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

ViewMemorialService.propTypes = {
    section: PropTypes.object.isRequired
};

export default ViewMemorialService;
