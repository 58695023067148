import './Logo.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({className}) => (
    <div className={`c-logo ${className || ''}`}>
        <svg viewBox="0 0 1475.41 306.69">
            <path fill="#003401" d="M353.28,223.3V83.4a9,9,0,0,1,8.8-9h98.15a8.13,8.13,0,1,1,0,16.25H371.1v53.93h79a8.23,8.23,0,0,1,8.12,8.12,8.09,8.09,0,0,1-8.12,8.12h-79v55.29h90.26a8.12,8.12,0,1,1,0,16.24H362.08A9,9,0,0,1,353.28,223.3Z"/>
            <path fill="#003401" d="M529.16,83.4a9,9,0,0,1,8.8-9h50.32c35.65,0,59.12,19,59.12,50.09v.46c0,34.07-28.43,51.67-62,51.67H547v47.83a8.92,8.92,0,1,1-17.83,0ZM586,160.34c26.18,0,43.32-14,43.32-34.75v-.45c0-22.56-16.92-34.3-42.42-34.3H547v69.5Z"/>
            <path fill="#003401" d="M706.39,82.27a8.91,8.91,0,1,1,17.82,0V224.42a8.91,8.91,0,1,1-17.82,0Z"/>
            <path fill="#003401" d="M799,82.27a8.91,8.91,0,1,1,17.82,0V215.85H891.3a8.29,8.29,0,0,1,8.13,8.35,8.1,8.1,0,0,1-8.13,8.12H807.83a9,9,0,0,1-8.8-9Z"/>
            <path fill="#003401" d="M923.09,153.8v-.45c0-43.55,32.72-81.69,80.78-81.69s80.33,37.69,80.33,81.24v.45c0,43.55-32.72,81.68-80.78,81.68S923.09,197.35,923.09,153.8Zm142.61,0v-.45c0-35.88-26.18-65.21-62.28-65.21S941.59,117,941.59,152.9v.45c0,35.87,26.18,65.21,62.28,65.21S1065.7,189.68,1065.7,153.8Z"/>
            <path fill="#003401" d="M1134.87,153.8v-.45c0-43.33,31.82-81.69,79.21-81.69,23.91,0,39.48,6.32,53.92,16.93a9,9,0,0,1-5.19,16.24,9.44,9.44,0,0,1-5.64-2.25c-11.28-8.58-23.92-14.44-43.77-14.44-35,0-60,29.56-60,64.76v.45c0,37.68,24.14,65.66,62.5,65.66,18,0,34.75-7,45.58-15.8V164H1219a8,8,0,0,1,0-16h50.77a9,9,0,0,1,8.8,9V205a12.72,12.72,0,0,1-6.09,10.83A96.33,96.33,0,0,1,1215.2,235C1165.56,235,1134.87,198.93,1134.87,153.8Z"/>
            <path fill="#003401" d="M1331.68,153.8v-.45c0-43.33,31.81-81.69,79.2-81.69,23.92,0,39.49,6.32,53.93,16.93a9,9,0,0,1-5.19,16.24,9.42,9.42,0,0,1-5.64-2.25c-11.29-8.58-23.92-14.44-43.78-14.44-35,0-60,29.56-60,64.76v.45c0,37.68,24.14,65.66,62.5,65.66,18.06,0,34.75-7,45.58-15.8V164h-42.42a8,8,0,0,1,0-16h50.77a9,9,0,0,1,8.8,9V205a12.72,12.72,0,0,1-6.09,10.83A96.32,96.32,0,0,1,1412,235C1362.37,235,1331.68,198.93,1331.68,153.8Z"/>
            <path fill="#01ad97" d="M256.61,144.71H50.28a25,25,0,0,1,18.08-17.07L243,80.85a8.14,8.14,0,1,0-4.21-15.73L64.15,111.91c-19.09,5.12-31.42,21.19-31.42,41s12.33,35.83,31.42,40.94l174.64,46.8a8.48,8.48,0,0,0,2.11.27,8.14,8.14,0,0,0,2.1-16L68.36,178.07A25,25,0,0,1,50.28,161H256.61a8.15,8.15,0,0,0,0-16.29Z"/>
            <path fill="#003401" d="M203.31,306.69a8.12,8.12,0,0,1-3.83-1L40.63,220.84a76.85,76.85,0,0,1,.19-135.66L199.5,1a8.14,8.14,0,0,1,7.63,14.39L48.46,99.56a60.56,60.56,0,0,0-.15,106.92l158.84,84.89a8.14,8.14,0,0,1-3.84,15.32Z"/>
        </svg>
    </div>
);

Logo.propTypes = {
    className: PropTypes.string
};

export default Logo;
