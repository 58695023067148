import React from 'react';
import { connect } from 'react-redux';
import {LOCALSTORAGE} from "../../utils/constants";

export default function(WrappedComponent) {
    class requireAuth extends React.Component {
        componentDidMount() {
            if (!this.props.auth.token) {
                this.setRedirectAndLogout();
            }
        }

        componentWillUpdate(nextProps) {
            if (!nextProps.auth.token) {
                this.setRedirectAndLogout();
            }
        }

        setRedirectAndLogout = () => {
            localStorage.setItem(LOCALSTORAGE.REDIRECT, `${window.location.pathname}${window.location.search || ''}||${new Date().getTime()}`)
            this.props.history.push('/auth/logout');
        };

        render = () => <WrappedComponent {...this.props} />;
    }

    const mapStateToProps = state => {
        return {
            auth: state.auth,
        };
    };

    return connect(mapStateToProps)(requireAuth);
}
