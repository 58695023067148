import clone from 'clone';

import Request from 'utils/request';
import PATHS from 'utils/paths';

import { LOCALSTORAGE } from 'utils/constants';
import { generateFriendlyErrorString } from 'utils/data';

import {setAllEpiloggs, setEpilogg, setSharedEpiloggs} from 'containers/Epilogg/store/actions';
import { addError } from 'containers/Errors/store/actions';

import * as types from './constants';

const changePasswordStart = () => ({
    type: types.CHANGE_PASSWORD_START,
});

const changePasswordSuccess = () => ({
    type: types.CHANGE_PASSWORD_SUCCESS
});

const changePasswordFailure = error => ({
    type: types.CHANGE_PASSWORD_FAILURE,
    error
});

export const clearChangePasswordData = () => ({
    type: types.CLEAR_CHANGE_PASSWORD_DATA,
});

export const clearCredentials = () => ({
    type: types.CLEAR_CREDENTIALS,
});

export const clearForgotPasswordData = () => ({
    type: types.CLEAR_FORGOT_PASSWORD_DATA,
});

export const clearLoginError = () => ({
    type: types.CLEAR_LOGIN_ERROR,
});

export const clearSaveProfileError = () => ({
    type: types.CLEAR_SAVE_PROFILE_ERROR,
});

export const clearSignupData = () => ({
    type: types.CLEAR_SIGNUP_DATA
});

const getLoggedInUserStart = () => ({
    type: types.GET_LOGGED_IN_USER_START,
});

const getLoggedInUserSuccess = (data) => ({
    type: types.GET_LOGGED_IN_USER_SUCCESS,
    data
});

const getLoggedInUserFailure = error => ({
    type: types.GET_LOGGED_IN_USER_FAILURE,
    error,
});

const loginStart = () => ({
    type: types.LOGIN_START,
});

const loginSuccess = (profile, token) => ({
    type: types.LOGIN_SUCCESS,
    profile,
    token
});

const loginFailure = error => ({
    type: types.LOGIN_FAILURE,
    error,
});

/*const logoutStart = () => ({
    type: types.LOGOUT_START,
});

const logoutSuccess = () => ({
    type: types.LOGOUT_SUCCESS,
});

const logoutFailure = error => ({
    type: types.LOGOUT_FAILURE,
    error,
});*/

const resendVerificationEmailStart = () => ({
    type: types.RESEND_VERIFICATION_EMAIL_START
});

const resendVerificationEmailSuccess = () => ({
    type: types.RESEND_VERIFICATION_EMAIL_SUCCESS
});

const resendVerificationEmailFailure = (error) => ({
    type: types.RESEND_VERIFICATION_EMAIL_FAILURE,
    error
});

const resetPasswordStart = () => ({
    type: types.RESET_PASSWORD_START,
});

const resetPasswordSuccess = () => ({
    type: types.RESET_PASSWORD_SUCCESS,
});

const resetPasswordFailure = error => ({
    type: types.RESET_PASSWORD_FAILURE,
    error,
});

const saveProfileStart = () => ({
    type: types.SAVE_PROFILE_START,
});

const saveProfileSuccess = (data) => ({
    type: types.SAVE_PROFILE_SUCCESS,
    data
});

const saveProfileFailure = error => ({
    type: types.SAVE_PROFILE_FAILURE,
    error,
});

const sendForgotPasswordEmailStart = () => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_START,
});

const sendForgotPasswordEmailSuccess = () => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
});

const sendForgotPasswordEmailFailure = error => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
    error,
});

export const setChangePasswordData = (data) => ({
    type: types.SET_CHANGE_PASSWORD_DATA,
    data
});

export const setCredentials = data => ({
    type: types.SET_CREDENTIALS,
    data,
});

export const setProfile = data => ({
    type: types.SET_PROFILE,
    data,
});

export const setSignupData = (data) => ({
    type: types.SET_SIGNUP_DATA,
    data,
});

export const setSignupError = (error) => ({
    type: types.SET_SIGNUP_ERROR,
    error
});

export const setToken = data => ({
    type: types.SET_TOKEN,
    data,
});

const signupStart = () => ({
    type: types.SIGNUP_START
});

const signupSuccess = () => ({
    type: types.SIGNUP_SUCCESS
});

const signupFailure = (error) => ({
    type: types.SIGNUP_FAILURE,
    error
});

const signupWithFacebookStart = () => ({
    type: types.SIGNUP_WITH_FACEBOOK_START
});

const signupWithFacebookSuccess = (data) => ({
    type: types.SIGNUP_WITH_FACEBOOK_SUCCESS,
    data
});

const signupWithFacebookFailure = (error) => ({
    type: types.SIGNUP_WITH_FACEBOOK_FAILURE,
    error
});

const signupWithGoogleStart = () => ({
    type: types.SIGNUP_WITH_GOOGLE_START
});

const signupWithGoogleSuccess = (data) => ({
    type: types.SIGNUP_WITH_GOOGLE_SUCCESS,
    data
});

const signupWithGoogleFailure = (error) => ({
    type: types.SIGNUP_WITH_GOOGLE_FAILURE,
    error
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

const verifyEmailStart = () => ({
    type: types.VERIFY_EMAIL_START
});

const verifyEmailSuccess = (profile, token) => ({
    type: types.VERIFY_EMAIL_SUCCESS,
    profile,
    token
});

const verifyEmailFailure = (error) => ({
    type: types.VERIFY_EMAIL_FAILURE,
    error
});

export const changePassword = () => (dispatch, getStore) => {
    dispatch(changePasswordStart());

    const changePasswordData = clone(getStore().auth.changePasswordData);
    delete changePasswordData.newPassword2;

    const request = new Request(getStore().auth.token);

    return request.post(PATHS.auth.changePassword(), changePasswordData)
        .then(response => {
            dispatch(changePasswordSuccess());
            return response;
        })
        .catch(err => {
            console.log('changePasswordError', err);
            err.friendlyMessage = generateFriendlyErrorString('Error changing your password. Please try again.', err.response.data);
            dispatch(changePasswordFailure(err));
            throw err;
        });
};

export const getLoggedInUser = token => (dispatch, getStore) => {
    dispatch(getLoggedInUserStart());

    if (token == null) {
        token = getStore().auth.token;
    }

    const request = new Request(token);

    return request.get(PATHS.auth.getLoggedInUser())
        .then(response => {
            dispatch(getLoggedInUserSuccess(response.data.data.user));

            if(response.data.data.epiloggs.length > 0) {
                dispatch(setAllEpiloggs(response.data.data.epiloggs));
            }

            if(response.data.data.shared?.length > 0) {
                dispatch(setSharedEpiloggs(response.data.data.shared));
            }

            return response;
        })
        .catch(err => {
            console.log('getLoggedInUserError', err);
            err.friendlyMessage = generateFriendlyErrorString('Error getting your information. Please log in again.', err.response.data);
            dispatch(getLoggedInUserFailure(err));
            dispatch(addError(err));
            throw err;
        });
};

export const login = () => (dispatch, getStore) => {
    dispatch(loginStart());

    let credentials = getStore().auth.credentials;

    const request = new Request();

    return request.post(PATHS.auth.login(), credentials)
        .then(response => {
            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, response.data.data.token);
            dispatch(loginSuccess(response.data.data.user, response.data.data.token));
            if(response.data.data.epiloggs.length > 0) {
                dispatch(setAllEpiloggs(response.data.data.epiloggs));
            }
            if(response.data.data.shared?.length > 0) {
                dispatch(setSharedEpiloggs(response.data.data.shared));
            }
            return response;
        })
        .catch(err => {
            console.log(err);
            if(err.response.status === 401) {
                err.friendlyMessage = generateFriendlyErrorString('Incorrect username or password. Please try again.', err.response.data);
            } else {
                err.friendlyMessage = generateFriendlyErrorString('There was an error when trying to log in. Please try again.', err.response.data);
            }

            dispatch(loginFailure(err));
            throw err;
        });
};

export const resendVerificationEmail = (email) => (dispatch, getStore) => {
    dispatch(resendVerificationEmailStart());

    if(!email) {
        email = getStore().auth.signupData.user.email;
    }

    const resendVerificationEmailRequest = new Request();

    return resendVerificationEmailRequest.post(PATHS.auth.resendVerificationEmail(email))
        .then((response) => {
            dispatch(resendVerificationEmailSuccess());
            return response;
        })
        .catch((err) => {
            console.log('resend verification email failure', err, err.response);
            err.friendlyMessage = 'Error resending your verification email. Please try again.';
            dispatch(resendVerificationEmailFailure(err));
            throw err;
        });
};

export const resetPassword = token => (dispatch, getStore) => {
    dispatch(resetPasswordStart());

    let password = getStore().auth.credentials.password;

    const request = new Request();

    return request.post(PATHS.auth.resetPassword(), {password, token})
        .then(response => {
            dispatch(resetPasswordSuccess());
            return response;
        })
        .catch(err => {
            console.log(err);
            err.friendlyMessage = generateFriendlyErrorString('Unable to reset password. Please try again.', err.response.data);
            dispatch(resetPasswordFailure(err));
            throw err;
        });
};

export const saveProfile = () => (dispatch, getStore) => {
    dispatch(saveProfileStart());

    const profile = getStore().auth.profile;

    const request = new Request(getStore().auth.token);

    return request.put(PATHS.auth.saveProfile(), profile)
        .then((response) => {
            dispatch(saveProfileSuccess(response.data.data));
            return response;
        })
        .catch((err) => {
            console.log('saveProfile Error', err);
            err.friendlyMessage = generateFriendlyErrorString('Error saving your information. Please try agian.', err.response.data);
            dispatch(saveProfileFailure(err));
            throw err;
        });
};

export const sendForgotPasswordEmail = () => (dispatch, getStore) => {
    dispatch(sendForgotPasswordEmailStart());

    let email = getStore().auth.credentials.email;

    const request = new Request();

    return request.post(PATHS.auth.forgotPassword(), {email})
        .then(response => {
            dispatch(sendForgotPasswordEmailSuccess());
            return response;
        })
        .catch(err => {
            console.log(err.response);
            err.friendlyMessage = generateFriendlyErrorString('Unable to reset password. Please try again.', err.response.data);
            dispatch(sendForgotPasswordEmailFailure(err));
            throw err;
        });
};

export const signup = () => (dispatch, getStore) => {
    dispatch(signupStart());

    let signupData = clone(getStore().auth.signupData);

    const request = new Request();
    return request.post(PATHS.auth.signup(), signupData)
        .then((res) => {
            dispatch(signupSuccess());
            return res;
        })
        .catch((err) => {
            console.log(err, err.response);
            if(err.response.status === 400) {
                err.friendlyMessage = err.response.data.error;
            } else {
                err.friendlyMessage = generateFriendlyErrorString('There was an error signing up. Please try again.', err.response.data);
            }
            dispatch(signupFailure(err));
            throw err;
        });

};

export const signupWithFacebook = ({accessToken, email, userID}) => (dispatch, getStore) => {
    dispatch(signupWithFacebookStart());

    const request = new Request();

    const data = {
        accessToken,
        email,
        userId: userID
    };

    return request.post(PATHS.auth.signupWithFacebook(), data)
        .then((res) => {
            console.log('login with facebook response', res);
            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, res.data.data.token);
            dispatch(signupWithFacebookSuccess(res.data.data));
            if(res.data.data.epiloggs.length > 0) {
                dispatch(setAllEpiloggs(res.data.data.epiloggs));
            }
            return res;
        })
        .catch((err) => {
            console.log('login with facebook err', err, err.response);
            if(err.response.status === 400) {
                err.friendlyMessage = err.response.data.error;
            } else {
                err.friendlyMessage = generateFriendlyErrorString('There was an error signing up with Facebook. Please try again.', err.response.data);
            }
            dispatch(signupWithFacebookFailure(err));
            throw err;
        });
};

export const signupWithGoogle = (data) => (dispatch, getStore) => {
    dispatch(signupWithGoogleStart());

    const request = new Request();

    return request.post(PATHS.auth.signupWithGoogle(), data)
        .then((res) => {
            console.log('login with google response', res);
            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, res.data.data.token);
            dispatch(signupWithGoogleSuccess(res.data.data));
            if(res.data.data.epiloggs.length > 0) {
                dispatch(setAllEpiloggs(res.data.data.epiloggs));
            }
            return res;
        })
        .catch((err) => {
            console.log('login with facebook err', err, err.response);
            if(err.response.status === 400) {
                err.friendlyMessage = err.response.data.error;
            } else {
                err.friendlyMessage = generateFriendlyErrorString('There was an error signing up with Google. Please try again.', err.response.data);
            }
            dispatch(signupWithGoogleFailure(err));
            throw err;
        });
};

export const verifyEmail = (token) => (dispatch) => {
    dispatch(verifyEmailStart());

    const request = new Request();

    return request.post(PATHS.auth.verifyEmail(token))
        .then((res) => {
            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, res.data.data.token);
            dispatch(verifyEmailSuccess(res.data.data.user, res.data.data.token));
            return res;
        })
        .catch((err) => {
            console.log('verifyEmailErr', err.response.data);
            if(err.response && err.response.data && err.response.data.error) {
                err.friendlyMessage = generateFriendlyErrorString(`There was an error verifying your email address: ${err.response.data.error}`, err.response.data);
            } else {
                err.friendlyMessage = generateFriendlyErrorString('There was an error verifying your email address. Please try again.', err.response.data);
            }
            dispatch(verifyEmailFailure(err));
            dispatch(addError(err));
            throw err;
        });
};
