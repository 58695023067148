import './CreateFamily.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import clone from 'clone';

import { saveEpilogg, setEpilogg } from 'containers/Epilogg/store/actions';

import Button from 'components/Button';
import CreateFamilyForm from './CreateFamilyForm';
import FamilyList from 'containers/Epilogg/components/FamilyList';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import CropModal from "../../../../../components/Modal/CropModal";
import FileUploadErrorModal from "../CreateHeader/CreateHeaderAvatarUpload";

const CreateFamily = ({section, sectionIndex}) => {
    const dispatch = useDispatch();

    const [familyMember, setFamilyMember] = useState({});
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const { epilogg } = useSelector(store => store.epiloggs);
    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const confirmDelete = () => {
        const newEpiloggObj = clone(epilogg);

        for(var i = 0; i < newEpiloggObj.content[sectionIndex].people.length; i++) {
            if(newEpiloggObj.content[sectionIndex].people[i].id === familyMember.id) {
                newEpiloggObj.content[sectionIndex].people.splice(i, 1);
            }
        }

        dispatch(setEpilogg(newEpiloggObj));
        dispatch(saveEpilogg());
        setShowConfirmDeleteModal(false);
    };

    const handleAddEditClick = (person) => {
        setFamilyMember(person);
        setShowForm(true);
    };

    const handleCancelClick = () => {
        setShowForm(false);
        setFamilyMember({});
    };

    const handleDeleteClick = (person) => {
        setShowForm(false);
        setFamilyMember(person);
        setShowConfirmDeleteModal(true);
    };

    const handleSaveClick = () => {
        let newEpiloggObj = clone(epilogg);

        if(familyMember.id) {
            section.people.forEach((person, i) => {
                if(person.id === familyMember.id) {
                    section.people[i] = familyMember;
                }
            })
        } else {
            if(!section.people) {
                section.people = [];
            }
            section.people.push(familyMember);

        }

        newEpiloggObj.content[sectionIndex] = section;
        dispatch(setEpilogg(newEpiloggObj));
        setShowForm(false);
        setFamilyMember({});
        dispatch(saveEpilogg());
    };

    return (
        <div className="create-family">
            <FamilyList
                familyMembers={section.people}
                onDeletePress={handleDeleteClick}
                onEditPress={handleAddEditClick}
            />

            <div className="create-family__form-wrapper">
                {showForm ? (
                    <CreateFamilyForm
                        cancel={handleCancelClick}
                        disabled={isUploading || isSavingEpilogg}
                        familyMember={familyMember}
                        handleSaveClick={handleSaveClick}
                        setFamilyMember={setFamilyMember}
                    />
                ) : (
                    <Button
                        className="create-family__add-button"
                        disabled={isUploading || isSavingEpilogg}
                        noStyles
                        onClick={() => handleAddEditClick({})}
                    >
                        <div className="create-family__add-button-plus">
                            <Icon type="plus" />
                        </div>

                        Add {section.people && section.people.length > 0 ? 'Another' : 'First'}<br />Connection
                    </Button>
                )}
            </div>

            <Modal
                confirmButtonOnClick={confirmDelete}
                confirmButtonText="remove"
                declineButtonOnClick={() => {
                    setShowConfirmDeleteModal(false);
                    setFamilyMember({});
                }}
                declineButtonText="cancel"
                show={showConfirmDeleteModal}
                title="confirm delete"
            >
                Are you sure you want to remove this person? This action cannot be undone.
            </Modal>
        </div>
    );
};

CreateFamily.propTypes = {
    section: PropTypes.object.isRequired,
    sectionIndex: PropTypes.number.isRequired
};

export default CreateFamily;
