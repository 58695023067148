import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validated } from 'react-custom-validation';
import { useSelector } from "react-redux";
import clone from 'clone';

import { handleTextChange } from 'utils/handle-changes';
import { isRequired } from 'utils/validations';

import Button from 'components/Button';
import CreateFamilyImageUpload from './CreateFamilyImageUpload';
import Form from 'components/Form';
import FormError from 'components/FormError';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';

let CreateFamilyForm = ({cancel, disabled, familyMember, handleSaveClick, setFamilyMember, $field, $submit, $validation}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const { saveEpiloggError } = useSelector(store => store.epiloggs);

    const handleChange = (name, value) => {
        console.log(name, value);
        let newFamilyMemberObj = clone(familyMember);
        newFamilyMemberObj[name] = value;
        setFamilyMember(newFamilyMemberObj);
        console.log(newFamilyMemberObj);
    };

    const handleUpload = (values) => {
        let newFamilyMemberObj = clone(familyMember);
        Object.keys(values).forEach((key) => {
            newFamilyMemberObj[key] = values[key];
        });
        setFamilyMember(newFamilyMemberObj);
    };

    const handleSubmit  = async (isValid) => {
        if(isValid === true) {
            setIsSaving(true);
            //Delaying 2000ms to give images time to process
            setTimeout(async () => {
                await handleSaveClick();
                setIsSaving(false);
            }, 2000);

        }
    };

    return (
        <Form className="create-family-form">
            <FormRow>
                <CreateFamilyImageUpload
                    disabled={disabled}
                    familyMember={familyMember}
                    handleChange={handleUpload}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={disabled}
                    id="txtName"
                    label="name"
                    name="displayName"
                    placeholder="name"
                    required
                    type="text"
                    validation={$validation.displayName}
                    value={familyMember.displayName || ''}
                    {...$field('displayName', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={disabled}
                    id="txtRelation"
                    label="relation"
                    name="description"
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder="relation"
                    type="text"
                    value={familyMember.description || ''}
                />
            </FormRow>

            <FormError
                error={saveEpiloggError}
            />

            <FormRow className="create-family-form__button-wrapper">
                <Button
                    disabled={disabled || isUploading}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isSaving}
                    type="submit"
                >
                    Save
                </Button>

                <Button
                    disabled={disabled || isSaving || isUploading}
                    onClick={cancel}
                    type="button"
                >
                    Cancel
                </Button>
            </FormRow>

        </Form>
    );
};

CreateFamilyForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    familyMember: PropTypes.object.isRequired,
    handleSaveClick: PropTypes.func.isRequired
};

function createFamilyFormValidationConfig(props) {
    const { displayName } = props.familyMember;

    return {
        fields: ['displayName'],
        validations: {
            displayName: [
                [isRequired, displayName]
            ]
        }
    }
}


export default CreateFamilyForm = validated(createFamilyFormValidationConfig)(CreateFamilyForm);
