import clone from 'clone';

import * as types from './constants';

const initialState = {
    emailToInvite: '',
    isAcceptingInvitation: false,
    isInvitingEditor: false,
    isRemovingEditor: false,
    acceptInvigationError: {},
    inviteEditorError: {},
    removeEditorError: {}
};

function invitationsReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.ACCEPT_INVITATION_START:
            return { ...state, isAcceptingInvitation: true, acceptInvigationError: {} };
        case types.ACCEPT_INVITATION_SUCCESS:
            return { ...state, isAcceptingInvitation: false };
        case types.ACCEPT_INVITATION_FAILURE:
            return { ...state, isAcceptingInvitation: false, acceptInvigationError: action.error };

        case types.INVITE_EDITOR_START:
            return { ...state, isInvitingEditor: true, inviteEditorError: {} };
        case types.INVITE_EDITOR_SUCCESS:
            return { ...state, isInvitingEditor: false, emailToInvite: '' };
        case types.INVITE_EDITOR_FAILURE:
            return { ...state, isInvitingEditor: false, inviteEditorError: action.error };

        case types.REMOVE_EDITOR_START:
            return { ...state, isRemovingEditor: true, removeEditorError: {} };
        case types.REMOVE_EDITOR_SUCCESS:
            return { ...state, isRemovingEditor: false };
        case types.REMOVE_EDITOR_FAILURE:
            return { ...state, isRemovingEditor: false, removeEditorError: action.error };

        case types.SET_EMAIL_TO_INVITE:
            return { ...state, emailToInvite: action.data };

        default:
            return state;
    }
}

export default invitationsReducer;
