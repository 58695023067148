import './Label.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = ({children, className, disabled, htmlFor, isWrappingLabel, ...otherProps}) => {
    let classes = classNames(
        'c-label',
        className,
        {
            'a-label--disabled': otherProps.disabled === true
        }
    );

    if(htmlFor != null || isWrappingLabel === true) {
        return (
            <label
                {...otherProps}
                className={classes}
                htmlFor={htmlFor}
            >
                {children}
            </label>
        );
    } else {
        return (
            <div className={classes}>
                {children}
            </div>
        )
    }
};

Label.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    htmlFor: PropTypes.string,
    isWrappingLabel: PropTypes.bool
};

export default Label;
