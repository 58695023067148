import {combineReducers} from 'redux';

import app from 'containers/App/store/reducers';
import auth from 'containers/Auth/store/reducers';
import epiloggs from 'containers/Epilogg/store/reducers';
import errors from 'containers/Errors/store/reducers';
import invitations from 'containers/Epilogg/Invitations/store/reducers';
import upload from 'containers/Upload/reducers';

import * as types from './constants';

const appReducer = combineReducers({
    app,
    auth,
    epiloggs,
    errors,
    invitations,
    upload
});

export const rootReducer = (state, action) => {
    if (action.type === types.RESET_GLOBAL_STATE) {
        state = undefined;
    }
    return appReducer(state, action);
};
