import './CreateTextColumn.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

import { handleTextChange } from 'utils/handle-changes';

import Textbox from 'components/Textbox';
import FormRow from 'components/FormRow';

const CreateTextColumn = ({columnIndex, handleChange, section}) => {
    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const disabled = isUploading || isSavingEpilogg;

    const titleName = `title${columnIndex + 1}`;
    const textName = `text${columnIndex + 1}`;

    const generateTitlePlaceholder = () => {
        if(section.isDefault) {
            if (section.type === 'it') {
                return 'headline (ex:  A loving mother and feisty Texan)';
            } else if(section.type === 't') {
                return 'headline (ex: About Amy)';
            }
        }

        return 'story title'
    };

    const generateTextPlaceholder = () => {
        if(section.isDefault) {
            if (section.type === 'it') {
                return 'short summary or special quote/message';
            } else if(section.type === 't') {
                return 'type a story of your loved one’s life';
            }
        }
        return 'text';
    };

    return (
        <div className="c-create-section-column a-create-section-column--text">
            <FormRow>
                <Textbox
                    disabled={disabled}
                    label={generateTitlePlaceholder()}
                    name={titleName}
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder={generateTitlePlaceholder()}
                    value={section[titleName]}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={disabled}
                    label={generateTextPlaceholder()}
                    name={textName}
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder={generateTextPlaceholder()}
                    type="textarea"
                    value={section[textName]}
                />
            </FormRow>
        </div>
    );
};

CreateTextColumn.propTypes = {
    columnIndex: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired
};

export default CreateTextColumn;
