import './LoginWithFacebook.scss';

import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { setSignupError, signupWithFacebook } from 'containers/Auth/store/actions';

import Button from 'components/Button';
import Icon from 'components/Icon';

const LoginWithFacebook = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleCallback = async (response) => {
        console.log('facebook response', response);
        if(response && response.userID && response.accessToken && response.email) {
            try {
                const signupRes = await dispatch(signupWithFacebook(response));
                if(signupRes.data.data.epiloggs && signupRes.data.data.epiloggs.length > 0) {
                    history.push('/dashboard');
                } else {
                    history.push('/auth/complete');
                }
            } catch(err) {

            }

        } else {
            dispatch(setSignupError({
                friendlyMessage: "There was an error with Facebook. Please try again."
            }));
        }
    };

    return (
        <div className="c-login-with-facebook">
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_API_KEY}
                autoLoad={false}
                callback={handleCallback}
                fields="name,email,picture"
                onClick={() => null}
                render={(renderProps) => {
                    if(renderProps.isSdkLoaded) {
                        return (
                            <Button
                                disabled={!renderProps.isSdkLoaded}
                                onClick={renderProps.onClick}
                            >
                                <Icon type="facebook"/> Continue with Facebook
                            </Button>
                        );
                    } else {
                        return (
                            <div className="c-login-with-facebook__unavailable">
                                Facebook Login is not compatible with your browser's privacy configuration.
                            </div>
                        );
                    }
                }}
            />
        </div>
    );
};

export default LoginWithFacebook;
