import './Auth.scss';

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import ForgotPassword from 'containers/Auth/ForgotPassword';
import Hero from 'components/Hero';
import Login from 'containers/Auth/Login';
import Logout from 'containers/Auth/Logout';
import ResetPassword from 'containers/Auth/ResetPassword';
import Signup from 'containers/Auth/Signup';
import VerifyEmail from 'containers/Auth/VerifyEmail';

const Auth = () => {
    useEffect(() => {
        document.body.classList.add('auth-page');

        return () => {
            document.body.classList.remove('auth-page');
        }
    }, []);

    return (
        <>
            <Hero />

            <div className="auth-form--wrapper">
                <div className="auth-form__content">
                    <Switch>
                        <Route path="/auth/forgot-password" component={ForgotPassword} />
                        <Route path="/auth/login" component={Login} />
                        <Route path="/auth/logout" component={Logout} />
                        <Route path="/auth/reset-password" component={ResetPassword} />
                        <Route path="/auth/signup" component={Signup} />
                        <Route path="/auth/verify" component={VerifyEmail} />
                        <Route path="/auth/complete" component={VerifyEmail} />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default Auth;
