import './CreateSection.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import clone from 'clone';

import { handleTextChange } from 'utils/handle-changes';
import { TYPES } from 'utils/constants';

import { saveEpilogg } from "../../../store/actions";

import ActionMenu from '../ActionMenu';
import AddMenu from '../AddMenu';
import CreateFamily from '../CreateFamily';
import CreateGuestbook from '../CreateGuestbook';
import CreateImageColumn from '../CreateImageColumn';
import CreateMemorialService from "../CreateMemorialService";
import CreateTextButtonColumn from '../CreateTextButtonColumn';
import CreateTextColumn from '../CreateTextColumn';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';
import CreateDonation from "../CreateDonation";
import ViewFlowers from "../../../View/components/ViewFlowers";

const CreateSection = ({handleChange, lastSection, section, sectionIndex}) => {
    const dispatch = useDispatch();

    const { epilogg } = useSelector(store => store.epiloggs);
    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const disabled = isUploading || isSavingEpilogg;

    const classes = classNames(
        'c-create-section',
        `a-create-section--${section.type}`
    );

    const handleContentChange = (name, value) => {
        let newSectionObj = clone(section);
        let newSectionsArray = clone(epilogg.content);
        newSectionObj[name] = value;
        if(name.indexOf('ArtifactId') !== -1 && value === null) {
            delete newSectionObj[name.replace('Id', '')];
        }
        newSectionsArray[sectionIndex] = newSectionObj;
        handleChange('content', newSectionsArray);
        if(name.indexOf('ArtifactId') !== -1 && value !== null) {
            dispatch(saveEpilogg());
        }
    };

    const handlePlaceSelected = (place, type) => {
        console.log('place', place)
        let newSectionObj = clone(section);
        let newSectionsArray = clone(epilogg.content);

        if(!place) {
            if (type === 'funeralHome') {
                delete newSectionObj.funeralHomeLatitude;
                delete newSectionObj.funeralHomeLongitude;
                delete newSectionObj.funeralHomeAddress;
                delete newSectionObj.funeralHomeName;
                delete newSectionObj.funeralHomeWebsite;
                newSectionObj.funeralHomeGooglePlacesId = null;
            } else {
                delete newSectionObj.locationLatitude;
                delete newSectionObj.locationLongitude;
                delete newSectionObj.locationAddress;
                delete newSectionObj.locationName;
                newSectionObj.locationGooglePlacesId = null;
            }
        } else {
            if (type === 'funeralHome') {
                newSectionObj.funeralHomeLatitude = place.geometry.location.lat();
                newSectionObj.funeralHomeLongitude = place.geometry.location.lng();
                newSectionObj.funeralHomeAddress = place.formatted_address;
                newSectionObj.funeralHomeName = place.name;
                newSectionObj.funeralHomeWebsite = place.website;
                newSectionObj.funeralHomeGooglePlacesId = place.place_id;
            } else {
                newSectionObj.locationLatitude = place.geometry.location.lat();
                newSectionObj.locationLongitude = place.geometry.location.lng();
                newSectionObj.locationAddress = place.formatted_address;
                newSectionObj.locationName = place.name;
                newSectionObj.locationGooglePlacesId = place.place_id;
            }
        }
        newSectionsArray[sectionIndex] = newSectionObj;
        handleChange('content', newSectionsArray);
    };

    /*const generateSectionTitlePlaceholder = () => {
        if(section.isDefault) {
            if(section.type === 'it') {
                return 'section title (ex: Remembering Amy)';
            } else if(section.type === 't') {
                return 'section title (ex: Life story)';
            } else if(section.type === 'iii') {
                return 'section title (ex: Favorite memories)';
            } else if(section.type === 'f') {
                return 'section title (ex:  Family & Friends)';
            } else if(section.type === 'g') {
                return 'section title (ex: Service information)';
            }
        }

        return 'section title';
    };*/

    const generateSectionHeaderPlaceholder = () => {
        if(section.isDefault) {
            if(section.type === 'g') {
                return 'headline (ex: Share your memories of Amy)';
            }
        }

        return 'section header';
    };

    if(section.type === 'plant_a_tree') {
        return null;
    }

    return (
        <div className={classes}>
            <ActionMenu
                section={section}
                sectionIndex={sectionIndex}
            />

            <div className="c-create-section__titles">
                {/*<FormRow>
                    <Textbox
                        disabled={section.type === 'g'}
                        id={`txtNavTitle${sectionIndex}`}
                        label="Section Title"
                        name="navTitle"
                        onChange={(event) => handleTextChange(handleContentChange, event)}
                        placeholder={generateSectionTitlePlaceholder()}
                        type="text"
                        value={section.navTitle || ''}
                    />
                </FormRow>*/}

                {/^f$|g/.test(section.type) ? (
                    <FormRow>
                        <Textbox
                            disabled={disabled}
                            id={`txtSubtitle${sectionIndex}`}
                            label="Section Header"
                            name="subtitle"
                            onChange={(event) => handleTextChange(handleContentChange, event)}
                            placeholder={generateSectionHeaderPlaceholder()}
                            type="text"
                            value={section.subtitle || ''}
                        />
                    </FormRow>
                ) : null}
            </div>

            {TYPES[section.type].map((column, i) => {
                switch(column) {
                    case 'donation':
                        return (
                            <CreateDonation
                                handleChange={handleContentChange}
                                key={i}
                                section={section}
                            />
                        )
                    case 'image':
                        return (
                            <CreateImageColumn
                                columnIndex={i}
                                handleChange={handleContentChange}
                                key={i}
                                section={section}
                            />
                        );
                    case 'text':
                        return (
                            <CreateTextColumn
                                columnIndex={i}
                                handleChange={handleContentChange}
                                key={i}
                                section={section}
                            />
                        );
                    case 'textbutton':
                        return (
                            <CreateTextButtonColumn
                                columnIndex={i}
                                handleChange={handleContentChange}
                                key={i}
                                section={section}
                            />
                        );
                    case 'family':
                        return (
                            <CreateFamily
                                key={i}
                                section={section}
                                sectionIndex={sectionIndex}
                            />
                        );
                    case 'flower':
                        return (
                            <ViewFlowers
                                key={i}
                                section={section}
                            />
                        );
                    case 'guestbook':
                        return (
                            <CreateGuestbook
                                epilogg={epilogg}
                                key={i}
                                section={section}
                                sectionIndex={sectionIndex}
                            />
                        );
                    case 'memorial':
                        return (
                            <CreateMemorialService
                                handleChange={handleContentChange}
                                handlePlaceSelected={handlePlaceSelected}
                                key={i}
                                section={section}
                                sectionIndex={sectionIndex}
                            />
                        );
                    default:
                        return null;
                }
            })}
            {lastSection && (
                <AddMenu
                    section={section}
                />
            )}
        </div>
    );
};

CreateSection.propTypes = {
    handleChange: PropTypes.func.isRequired,
    lastSection: PropTypes.bool,
    section: PropTypes.object.isRequired,
    sectionIndex: PropTypes.number.isRequired
};

export default CreateSection;
