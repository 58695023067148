import './MainFooter.scss';

import React from 'react';

import A from 'components/A';
import Icon from 'components/Icon';

const NAV_LINKS = [
    {
        text: 'about us',
        href: `${process.env.REACT_APP_WEBSITE_URL}/about`
    }, {
        text: 'blog',
        href: `${process.env.REACT_APP_WEBSITE_URL}/blog`
    }, {
        text: 'industry partners',
        href: `${process.env.REACT_APP_WEBSITE_URL}/industry-partners`
    }, {
        text: 'terms of use',
        href: `${process.env.REACT_APP_WEBSITE_URL}/terms-of-service`
    }, {
        text: 'privacy policy',
        href: `${process.env.REACT_APP_WEBSITE_URL}/privacy-policy`
    }
];

const QUICK_LINKS = [
    {
        text: 'faqs',
        href: `${process.env.REACT_APP_WEBSITE_URL}/faqs`
    }, {
        text: 'contact us',
        href: `${process.env.REACT_APP_WEBSITE_URL}/contact`
    }, {
        text: 'careers',
        href: `${process.env.REACT_APP_WEBSITE_URL}/join-our-team`
    }
];

const SOCIAL_LINKS = [
    {
        icon: () => <Icon type="facebook" />,
        href: 'https://www.facebook.com/Epilogg-2421563051200686/'
    }, {
        icon: () => <Icon type="twitter" />,
        href: 'https://twitter.com/epilogg2'
    }, {
        icon: () => <Icon type="linkedin" />,
        href: 'https://www.linkedin.com/company/epilogg/about/'
    }, {
        icon: () => <Icon type="instagram" />,
        href: 'https://www.instagram.com/epilogg/'
    }
]

const MainFooter = () => {
    return (
        <footer className="c-main-footer">
            <nav className="c-main-footer__nav">
                {NAV_LINKS.map((link) => (
                    <div className="c-main-footer__nav-link" key={link.href}>
                        <A href={link.href}>
                            {link.text}
                        </A>
                    </div>
                ))}
            </nav>

            <div className="c-main-footer__quick-nav-copyright-social--wrapper">
                <nav className="c-main-footer__quick-nav">
                    {QUICK_LINKS.map((link) => (
                        <div className="c-main-footer__quick-link" key={link.href}>
                            <A href={link.href}>
                                {link.text}
                            </A>
                        </div>
                    ))}
                </nav>

                <nav className="c-main-footer__social-nav">
                    {SOCIAL_LINKS.map((link) => (
                        <div className="c-main-footer__social-link" key={link.href}>
                            <A href={link.href}>
                                {link.icon()}
                            </A>
                        </div>
                    ))}
                </nav>
            </div>

            <div className="c-main-footer__copyright">
                © {new Date().getFullYear()} Epilogg, Inc. All Rights Reserved.
            </div>
        </footer>
    );
};

export default MainFooter;
