import './Logout.scss';

import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { LOCALSTORAGE } from 'utils/constants';

import { resetGlobalState } from 'store/actions';

import ActivityIndicator from 'components/ActivityIndicator';

const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        document.body.classList.add('logout-page');

        dispatch(resetGlobalState());
        localStorage.removeItem(LOCALSTORAGE.ID_TOKEN);
        history.push('/auth/login');

        return () => {
            document.body.classList.remove('logout-page');
        }
    }, [dispatch, history]);

    return (
        <div className="logout-wrapper">
            Logging out... <ActivityIndicator />
        </div>
    );
};

export default Logout;
