import './ReportEpiloggModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import { reportEpilogg, setReportReason } from 'containers/Epilogg/store/actions';

import Modal from 'components/Modal';
import ReportEpiloggForm from './ReportEpiloggForm';

const ReportEpiloggModal = ({close, show}) => {
    const dispatch = useDispatch();

    const { isReportingEpilogg, reportEpiloggError, reportReason } = useSelector(store => store.epiloggs);

    const handleClose = () => {
        close();
        dispatch(setReportReason(''));
    };

    const handleSubmit = async () => {
        let res = await dispatch(reportEpilogg());
        handleClose();
        return res;
    };

    return (
        <Modal
            className="report-epilogg-modal"
            declineButtonOnClick={handleClose}
            show={show}
            title="report this epilogg"
        >
            <ReportEpiloggForm
                close={close}
                error={reportEpiloggError}
                isReportingEpilogg={isReportingEpilogg}
                onSubmit={handleSubmit}
                reportReason={reportReason}
            />
        </Modal>
    );
};

ReportEpiloggModal.propTypes = {
    close: PropTypes.func.isRequired,
    show: PropTypes.bool
};

export default ReportEpiloggModal;
