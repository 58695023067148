import './CropModal.scss';
import 'react-image-crop/lib/ReactCrop.scss';

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';

import Modal from 'components/Modal';

const CropModal = ({aspectRatio, confirm, close, image, show}) => {
    const [crop, setCrop] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);

    const generateFileName = (name) => {
        let nameArr = name.split('.');
        nameArr[nameArr.length - 1] = 'jpg';
        return nameArr.join('.');
    };

    const getCroppedImage = () => {
        const img = new Image();
        img.src = image.preview;

        const canvas = document.createElement('canvas');
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        canvas.width = (crop.width / 100) * img.width;
        canvas.height = (crop.height / 100) * img.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            img,
            (crop.x / 100) * img.width * scaleX,
            (crop.y / 100) * img.height * scaleY,
            (crop.width / 100) * img.width * scaleX,
            (crop.height / 100) * img.height * scaleY,
            0,
            0,
            (crop.width / 100) * img.width,
            (crop.height / 100) * img.height,
        );

        const newFileName = generateFileName(image.name);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = newFileName;
                blob.path = newFileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    };

    const handleConfirm = async () => {
        setIsProcessing(true);
        try {
            let croppedBlob = await getCroppedImage();
            confirm(croppedBlob);
        } catch(err) {
            console.log('processing error', err);
        }
        setIsProcessing(false);
    };

    const onLoad = useCallback(img => {
        let height, width;
        if(!aspectRatio) {
            height = 80;
            width = 80;
        } else {
            if (img.width >= img.height) {
                height = 80;
                width = (((img.height * .8) * (1 / aspectRatio)) / img.width) * 100;

                //console.log('width 1', width);

                if(width > 100) {
                    let ratio = 100 / width;
                    width = 100;
                    height = height * ratio;
                }

                //console.log('width 2', width);
            } else if (img.width < img.height) {
                width = 80;
                height = (((img.width * .8) * aspectRatio) / img.height) * 100;

                if(height > 100) {
                    let ratio = 100 / height;
                    height = 100;
                    width = width * ratio;
                }
            }
        }

        const y = (100 - height) / 2;
        const x = (100 - width) / 2;

        setCrop({
            unit: '%',
            width,
            height,
            x,
            y,
            aspect: 1/aspectRatio,
        });

        //console.log(width, height, x, y);

        return false; // Return false if you set crop state in here.*/
    }, []);

    return (
        <Modal
            className="crop-modal"
            confirmButtonOnClick={handleConfirm}
            confirmButtonText="DONE"
            declineButtonOnClick={close}
            declineButtonText="CANCEL"
            show={show}
            showActivityIndicator={isProcessing}
            title="Crop Image"
        >
            <ReactCrop
                crop={crop}
                onChange={(newCrop, percentCrop) => {
                    setCrop(percentCrop)
                }}
                onImageLoaded={onLoad}
                src={image.preview}
            />
        </Modal>
    );
};

CropModal.propTypes = {
    aspectRatio: PropTypes.number,
    confirm: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    image: PropTypes.object,
    show: PropTypes.bool.isRequired
};

export default CropModal;
