import './CreateDonation.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import isURL from 'validator/lib/isURL';

import { handleTextChange } from "../../../../../utils/handle-changes";

import FormRow from "../../../../../components/FormRow";
import Textbox from "../../../../../components/Textbox";
import H1 from 'components/H1';
import FormError from "../../../../../components/FormError";

const CreateDonation = ({handleChange, section}) => {
    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const disabled = isUploading || isSavingEpilogg;
    return (
        <div className="create-donation">
            <H1 className="c-view-section__subtitle">
                memorial donations
            </H1>

            <FormRow>
                <Textbox
                    disabled={disabled}
                    label="add charity title"
                    name="title1"
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder="add charity title, ex:  American Cancer Society in honor of Henry"
                    type="text"
                    value={section.title1}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={disabled}
                    label="This cause is special because"
                    name="text1"
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder={`"This cause is special because..." or  "On our loved one’s behalf, donations are welcome to this organization..."`}
                    type="textarea"
                    value={section.text1}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={disabled}
                    label="add charity URL"
                    name="thirdPartyUrl"
                    onChange={(event) => handleTextChange(handleChange, event)}
                    placeholder="add charity URL"
                    type="text"
                    value={section.thirdPartyUrl}
                />
            </FormRow>

            {section.thirdPartyUrl && !isURL(section.thirdPartyUrl) && (
                <FormError
                    error={{friendlyMessage: 'This is not a valid URL. Your content will not be visible to readers until it is. Please update it or contact support.'}}
                />
            )}
        </div>
    );
};

CreateDonation.propTypes = {
    handleChange: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired
};

export default CreateDonation;
