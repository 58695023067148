import clone from 'clone';

import * as types from './constants';

const initialState = {
    errors: []
};

function errorsReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_ERRORS:
            return { ...state, errors: [] };
        case types.RESET_ERRORS:
            return clone(initialState);
        case types.SET_ERRORS:
            return { ...state, errors: action.data };
        default:
            return state;
    }
}

export default errorsReducer;
