import './CreateFamilyImageUpload.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import Dropzone from 'react-dropzone';
import { useDispatch } from "react-redux";

import { resizeImage } from "../../../../../../utils/images";

import { uploadFiles } from 'containers/Upload/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import CropModal from 'components/Modal/CropModal';
import FileUploadErrorModal from 'components/Modal/FileUploadErrorModal';
import Icon from 'components/Icon';
import heic2any from "heic2any";
import {saveEpilogg} from "../../../../store/actions";

const CreateFamilyImageUpload = ({disabled, familyMember, handleChange, isUploading, setIsUploading}) => {
    const dispatch = useDispatch();

    const [activeDrag, setActiveDrag] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [preview, setPreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);

    const handleCrop = async (blob) => {
        setIsUploading(true);
        blob.preview = URL.createObjectURL(blob);
        setPreview(blob);
        setShowCropModal(false);
        setSelectedImage(null);
        let resizedImage = await resizeImage(blob);
        uploadImage(resizedImage);
    };

    const handleDrop = async (goodFiles, badFiles) => {
        setActiveDrag(false);
        if(badFiles.length > 0) {
            setShowErrorModal(true);
        } else {
            let convertedImage;
            if(goodFiles[0].type.toLowerCase() === 'image/heic') {
                try {
                    setIsUploading(true);
                    convertedImage = await heic2any({blob: goodFiles[0], });
                    setIsUploading(false);
                } catch(err) {
                    console.log(err);
                }

            }

            goodFiles[0].preview = URL.createObjectURL(convertedImage || goodFiles[0]);
            setSelectedImage(goodFiles[0]);
            setShowCropModal(true);
        }
    };

    const uploadImage = async (file) => {
        try {
            let uploadResult = await dispatch(uploadFiles(file));
            console.log('uploadResultInUpload', uploadResult);
            handleChange({
                'profilePicArtifactId': uploadResult.file.artifactId,
                'profilePicPreview': file.preview
            });
        } catch(err) {
            console.log('upload avatar err', err);
            setPreview(null);
        }

        setIsUploading(false);
    };

    const classes = classNames(
        'create-family__avatar',
        {
            'create-family__avatar--active': activeDrag,
            'create-family__avatar__has-preview': preview != null
        }
    );

    return (
        <div className={classes}>
            <Dropzone accept="image/jpeg,image/png,image/gif,image/webp,image/heic,image/heif"
                      disabled={disabled || isUploading}
                      multiple={false}
                      name="avatar"
                      onDragEnter={() => setActiveDrag(true)}
                      onDragLeave={() => setActiveDrag(false)}
                      onDrop={handleDrop}>
                {({getRootProps, getInputProps}) => (
                    <div className="create-family__avatar__content" {...getRootProps()}>
                        <input {...getInputProps()} />

                        {isUploading ? (
                            <div className="c-file-upload__uploading">
                                <ActivityIndicator />  uploading
                            </div>
                        ) : (
                           <div style={(preview && preview.preview) || (familyMember.profilePicArtifact && familyMember.profilePicArtifact.url) ? {backgroundImage: `url(${preview ? preview.preview : familyMember.profilePicArtifact.url}`} : {backgroundImage: `url(/obituary/img/noimage/default_avatar_img1.svg)`}} className="c-file-upload__avatar" />
                        )}

                        {activeDrag ? (
                            <div className="create-family__avatar--active-drag">
                                drop here<br />to upload
                            </div>
                        ) : null}
                    </div>
                )}
            </Dropzone>

            {!isUploading ? (
                <div className="create-family__avatar-text">
                    drop or upload <Icon type="upload" />
                </div>
            ) : null}

            <FileUploadErrorModal
                close={() => setShowErrorModal(false)}
                show={showErrorModal}
            />


            <CropModal
                aspectRatio={1}
                close={() => setShowCropModal(false)}
                confirm={handleCrop}
                image={selectedImage || {}}
                show={showCropModal}
            />
        </div>
    );
};

CreateFamilyImageUpload.propTypes = {
    disabled: PropTypes.bool,
    familyMember: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    isUploading: PropTypes.bool.isRequired,
    setIsUploading: PropTypes.func.isRequired
};

export default CreateFamilyImageUpload;
