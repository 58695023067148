import React from 'react';
import PropTypes from 'prop-types';
import { validated } from 'react-custom-validation';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clone from 'clone';

import { isEmail, isRequired } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';
import { getRedirectAfterAuth } from 'utils/utils';

import { clearCredentials, login, setCredentials } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import Form from 'components/Form';
import FormError from 'components/FormError';
import FormRow from 'components/FormRow';
import LoginWithFacebook from 'components/LoginWithFacebook';
import LoginWithGoogle from 'components/LoginWithGoogle';
import Textbox from 'components/Textbox';

let LoginForm = ({credentials, $field, $submit, $validation}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    let { loginError, isLoggingIn } = useSelector(store => store.auth);

    const handleChange = (name, value) => {
        let newCredentialsObj = clone(credentials);
        newCredentialsObj[name] = value;
        dispatch(setCredentials(newCredentialsObj));
    };

    const handleSubmit = (isValid) => {
        if(isValid === true) {
            dispatch(login())
                .then((res) => {
                    let redirect = getRedirectAfterAuth('/dashboard');
                    history.push(redirect);
                    dispatch(clearCredentials());
                })
                .catch((err) => {
                    if(err.response.status === 403) {
                        history.push('/auth/verify');
                    }
                });
        }
    };

    return (
        <Form className="login-form auth-form--skinny">
            <FormRow>
                <Textbox
                    id="txtEmailAddress"
                    label="email"
                    name="email"
                    placeholder="email"
                    required
                    type="email"
                    validation={$validation.email}
                    value={credentials.email || ''}
                    {...$field('email', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtPassword"
                    label="password"
                    name="password"
                    placeholder="password"
                    required
                    type="password"
                    validation={$validation.password}
                    value={credentials.password || ''}
                    {...$field('password', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow className="header-login-form__forgot-password-link">
                <A to="/auth/forgot-password">
                    forgot password?
                </A>
            </FormRow>

            <FormError
                error={loginError}
            />

            <FormRow className="login-form__button-wrapper auth-form__button-wrapper">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isLoggingIn}
                    type="submit"
                >
                    Continue
                </Button>
            </FormRow>

            <div className="signup-form__or">
                <span>OR</span>
            </div>

            <FormRow className="signup-form__button-wrapper auth-form__button-wrapper">
                <LoginWithGoogle />
            </FormRow>

            <FormRow className="signup-form__button-wrapper auth-form__button-wrapper">
                <LoginWithFacebook />
            </FormRow>

            <FormRow className="header-login-form__create-account-link">
                <A to="/auth/signup">
                    create an account
                </A>
            </FormRow>
        </Form>
    );
};

LoginForm.propTypes = {
    credentials: PropTypes.object.isRequired
};

function loginFormValidationConfig(props) {
    const { email, password } = props.credentials;

    return {
        fields: ['email', 'password'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            password: [
                [isRequired, password]
            ]
        }
    }
}


export default LoginForm = validated(loginFormValidationConfig)(LoginForm);
