import './FamilyList.scss';

import React  from 'react';
import PropTypes from 'prop-types';

import FamilyMember from 'containers/Epilogg/components/FamilyMember';

const FamilyList = ({familyMembers, onDeletePress, onEditPress}) => {
    return (
        <div className="c-family-list">
            {familyMembers && familyMembers.length > 0 && familyMembers.map((person) => (
                <FamilyMember
                    key={person.id}
                    onDeletePress={onDeletePress ? () => onDeletePress(person) : null}
                    onEditPress={onEditPress ? () => onEditPress(person) : null}
                    person={person}
                />
            ))}
        </div>
    );
};

FamilyList.propTypes = {
    familyMembers: PropTypes.array.isRequired,
    onDeletePress: PropTypes.func,
    onEditPress: PropTypes.func
};

export default FamilyList;
