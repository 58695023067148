import React from 'react';
import Linkify from 'react-linkify';

const ParseLinks = ({children}) => {
    return (
        <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="_blank" href={decoratedHref} key={key}>
                    {decoratedText}
                </a>
            )}
        >
            {children}
        </Linkify>
    );
};

export default ParseLinks;
