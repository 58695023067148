import './A.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const A = ({children, className, state, to, ...otherProps}) => {
    if(to != null) {
        return (
            <Link
                className={`c-link a-link--to ${className || ''}`}
                to={{
                    pathname: to,
                    state
                }}
                {...otherProps}
            >
                {children}
            </Link>
        );
    } else {
        return (
            <a
                className={`c-link a-link--href ${className || ''}`}
                {...otherProps}
            >
                {children}
            </a>
        );
    }
};

A.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    state: PropTypes.object,
    target: PropTypes.string,
    to: PropTypes.string
};

export default A;
