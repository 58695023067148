import React from 'react';
import PropTypes from 'prop-types';
import { validated } from "react-custom-validation";
import { useDispatch, useSelector } from "react-redux";

import { handleTextChange } from 'utils/handle-changes';
import { isEmail, isRequired } from 'utils/validations';

import { setFollowEmail } from 'containers/Epilogg/store/actions';

import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';

let FollowEmailForm = ({followEmail, onSubmit, show, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();

    const { isFollowingEpilogg } = useSelector(store => store.epiloggs);

    const handleChange = (name, value) => {
        dispatch(setFollowEmail(value));
    };

    const handleSubmit = async (isValid) => {
        if(isValid) {
            try {
                await onSubmit();
                $fieldEvent('reset');
            } catch(err) {

            }
        }
    };

    return (
        <Form className={`follow-email-form ${show ? 'follow-email-form--show' : null}`}>
            <FormRow>
                <Textbox
                    id="txtFollowEmail"
                    label="your email"
                    name="followEmail"
                    placeholder="your email"
                    required
                    type="email"
                    validation={$validation.followEmail}
                    value={followEmail || ''}
                    {...$field('followEmail', (event) => handleTextChange(handleChange, event))}
                />

                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isFollowingEpilogg}
                    type="submit"
                >
                    follow
                </Button>
            </FormRow>
        </Form>
    );
};

FollowEmailForm.propTypes = {
    followEmail: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};



function followEmailFormValidationConfig(props) {
    const { followEmail } = props;

    return {
        fields: ['followEmail'],
        validations: {
            followEmail: [
                [isRequired, followEmail],
                [isEmail, followEmail]
            ]
        }
    }
}


export default FollowEmailForm = validated(followEmailFormValidationConfig)(FollowEmailForm);
