import './Hero.scss';

import React, { useEffect } from 'react';
import { useSelector } from "react-redux";

import H1 from 'components/H1';

const BANNER_IMAGES = ['banner-bg1.jpg', 'banner-bg2.jpg', 'banner-bg3.jpg']

let bannerImage;

const Hero = () => {
    const title = useSelector(store => store.app.title);

    useEffect(() => {
        bannerImage = BANNER_IMAGES[Math.floor(Math.random() * 3)];
    }, []);

    return (
        <div className="c-hero">
            <H1>
                {title}
            </H1>
        </div>
    );
};

export default Hero;
