export const CLEAR_EPILOGG = 'Epilogg/CLEAR_EPILOGG';

export const CREATE_EPILOGG_START = 'Epilogg/CREATE_EPILOGG_START';
export const CREATE_EPILOGG_SUCCESS = 'Epilogg/CREATE_EPILOGG_SUCCESS';
export const CREATE_EPILOGG_FAILURE = 'Epilogg/CREATE_EPILOGG_FAILURE';

export const DELETE_COMMENT_START = 'Epilogg/DELETE_COMMENT_START';
export const DELETE_COMMENT_SUCCESS = 'Epilogg/DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'Epilogg/DELETE_COMMENT_FAILURE';

export const DELETE_EPILOGG_START = 'Epilogg/DELETE_EPILOGG_START';
export const DELETE_EPILOGG_SUCCESS = 'Epilogg/DELETE_EPILOGG_SUCCESS';
export const DELETE_EPILOGG_FAILURE = 'Epilogg/DELETE_EPILOGG_FAILURE';

export const FOLLOW_EPILOGG_START = 'Epilogg/FOLLOW_EPILOGG_START';
export const FOLLOW_EPILOGG_SUCCESS = 'Epilogg/FOLLOW_EPILOGG_SUCCESS';
export const FOLLOW_EPILOGG_FAILURE = 'Epilogg/FOLLOW_EPILOGG_FAILURE';

export const GET_EPILOGG_START = 'Epilogg/GET_EPILOGG_START';
export const GET_EPILOGG_SUCCESS = 'Epilogg/GET_EPILOGG_SUCCESS';
export const GET_EPILOGG_FAILURE = 'Epilogg/GET_EPILOGG_FAILURE';

export const ONE_CLICK_UNFOLLOW_EPILOGG_START = 'Epilogg/ONE_CLICK_UNFOLLOW_EPILOGG_START';
export const ONE_CLICK_UNFOLLOW_EPILOGG_SUCCESS = 'Epilogg/ONE_CLICK_UNFOLLOW_EPILOGG_SUCCESS';
export const ONE_CLICK_UNFOLLOW_EPILOGG_FAILURE = 'Epilogg/ONE_CLICK_UNFOLLOW_EPILOGG_FAILURE';

export const PUBLISH_EPILOGG_START = 'Epilogg/PUBLISH_EPILOGG_START';
export const PUBLISH_EPILOGG_SUCCESS = 'Epilogg/PUBLISH_EPILOGG_SUCCESS';
export const PUBLISH_EPILOGG_FAILURE = 'Epilogg/PUBLISH_EPILOGG_FAILURE';

export const REPORT_EPILOGG_START = 'Epilogg/REPORT_EPILOGG_START';
export const REPORT_EPILOGG_SUCCESS = 'Epilogg/REPORT_EPILOGG_SUCCESS';
export const REPORT_EPILOGG_FAILURE = 'Epilogg/REPORT_EPILOGG_FAILURE';

export const SAVE_EPILOGG_START = 'Epilogg/SAVE_EPILOGG_START';
export const SAVE_EPILOGG_SUCCESS = 'Epilogg/SAVE_EPILOGG_SUCCESS';
export const SAVE_EPILOGG_FAILURE = 'Epilogg/SAVE_EPILOGG_FAILURE';

export const SAVE_NEW_COMMENT_START = 'Epilogg/SAVE_NEW_COMMENT_START';
export const SAVE_NEW_COMMENT_SUCCESS = 'Epilogg/SAVE_NEW_COMMENT_SUCCESS';
export const SAVE_NEW_COMMENT_FAILURE = 'Epilogg/SAVE_NEW_COMMENT_FAILURE';

export const SET_EPILOGG = 'Epilogg/SET_EPILOGG';
export const SET_ALL_EPILOGGS = 'Epilogg/SET_ALL_EPILOGGS';
export const SET_FOLLOW_EMAIL = 'Epilogg/SET_FOLLOW_EMAIL';
export const SET_NEW_COMMENT = 'Epilogg/SET_NEW_COMMENT';
export const SET_REPORT_REASON = 'Epilogg/SET_REPORT_REASON';
export const SET_SHARED_EPILOGGS = 'Epilogg/SET_SHARED_EPILOGGS';
export const SET_VIEW_EPILOGG = 'Epilogg/SET_VIEW_EPILOGG';
