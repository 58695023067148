import clone from 'clone';

import * as types from './constants';

const EMPTY_CHANGE_PASSWORD_DATA = {
    newPassword: '',
    newPassword2: '',
    oldPassword: ''
};

const EMPTY_CREDENTIALS = {
    email: '',
    password: '',
};

const EMPTY_SIGNUP_DATA = {
    dontShareInformation: false,
    email: '',
    firstName: '',
    lastName: '',
    wantsNewsletter: true,
    password: '',
    password2: ''
};

export const initialState = {
    changePasswordData: clone(EMPTY_CHANGE_PASSWORD_DATA),
    credentials: clone(EMPTY_CREDENTIALS),
    profile: {},
    signupData: clone(EMPTY_SIGNUP_DATA),
    token: null,
    isChangingPassword: false,
    isFetchingProfile: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isResendingVerificationEmail: false,
    isResettingPassword: false,
    isSavingProfile: false,
    isSendingForgotPasswordEmail: false,
    isSigningUp: false,
    isSigningUpWithFacebook: false,
    isSigningUpWithGoogle: false,
    isVerifyingEmail: false,
    changePasswordError: {},
    loginError: {},
    loggedInUserError: {},
    logoutError: {},
    resendVerificationEmailError: {},
    resetPasswordError: {},
    saveProfileError: {},
    sendForgotPasswordEmailError: {},
    signupError: {},
    signupWithFacebookError: {},
    signupWithGoogleError: {},
    verifyEmailError: {}
};

function authReducer(state = clone(initialState), action) {
    switch (action.type) {
        case types.CHANGE_PASSWORD_START:
            return { ...state, isChangingPassword: true, changePasswordError: {} };
        case types.CHANGE_PASSWORD_SUCCESS:
            return { ...state, isChangingPassword: false, changePasswordData: clone(EMPTY_CHANGE_PASSWORD_DATA) };
        case types.CHANGE_PASSWORD_FAILURE:
            return { ...state, isChangingPassword: false, changePasswordError: action.error };
        case types.CLEAR_CHANGE_PASSWORD_DATA:
            return { ...state, changePasswordData: clone(EMPTY_CHANGE_PASSWORD_DATA), changePasswordError: {} };
        case types.CLEAR_CREDENTIALS:
            return { ...state, credentials: clone(EMPTY_CREDENTIALS) };
        case types.CLEAR_FORGOT_PASSWORD_DATA:
            return { ...state, forgotPasswordData: {} };
        case types.CLEAR_LOGIN_ERROR:
            return { ...state, loginError: {} };
        case types.CLEAR_SAVE_PROFILE_ERROR:
            return { ...state, saveProfileError: {} };
        case types.CLEAR_SIGNUP_DATA:
            return { ...state, signupData: clone(EMPTY_SIGNUP_DATA) };
        case types.GET_LOGGED_IN_USER_START:
            return { ...state, isGettingLoggedInUser: true, loggedInUserError: {} };
        case types.GET_LOGGED_IN_USER_SUCCESS:
            return { ...state, isGettingLoggedInUser: false, profile: action.data  };
        case types.GET_LOGGED_IN_USER_FAILURE:
            return { ...state, isGettingLoggedInUser: false, loggedInUserError: action.error };
        case types.LOGIN_START:
            return { ...state, isLoggingIn: true, loginError: {} };
        case types.LOGIN_SUCCESS:
            return { ...state, isLoggingIn: false, profile: action.profile, token: action.token };
        case types.LOGIN_FAILURE:
            return { ...state, isLoggingIn: false, loginError: action.error };
        /*case types.LOGOUT_START:
            return { ...state, isLoggingOut: true, logoutError: {} };
        case types.LOGOUT_SUCCESS:
            return { ...state, isLoggingOut: false, token: null, isAuthenticated: false };
        case types.LOGOUT_FAILURE:
            return { ...state, isLoggingOut: false, logoutError: action.error };*/
        case types.RESEND_VERIFICATION_EMAIL_START:
            return { ...state, isResendingVerificationEmail: true, resendVerificationEmailError: {} };
        case types.RESEND_VERIFICATION_EMAIL_SUCCESS:
            return { ...state, isResendingVerificationEmail: false };
        case types.RESEND_VERIFICATION_EMAIL_FAILURE:
            return { ...state, isResendingVerificationEmail: false, resendVerificationEmailError: action.error };
        case types.RESET_PASSWORD_START:
            return { ...state, isResettingPassword: true, resetPasswordError: {} };
        case types.RESET_PASSWORD_SUCCESS:
            return { ...state, isResettingPassword: false, forgotPasswordData: {} };
        case types.RESET_PASSWORD_FAILURE:
            return { ...state, isResettingPassword: false, resetPasswordError: action.error };
        case types.SAVE_PROFILE_START:
            return { ...state, isSavingProfile: true, saveProfileError: {} };
        case types.SAVE_PROFILE_SUCCESS:
            return { ...state, isSavingProfile: false, profile: action.data };
        case types.SAVE_PROFILE_FAILURE:
            return { ...state, isSavingProfile: false, saveProfileError: action.error };
        case types.SEND_FORGOT_PASSWORD_EMAIL_START:
            return { ...state, isSendingForgotPasswordEmail: true, sendForgotPasswordEmailError: {} };
        case types.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
            return { ...state, isSendingForgotPasswordEmail: false };
        case types.SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
            return { ...state, isSendingForgotPasswordEmail: false, sendForgotPasswordEmailError: action.error };
        case types.SET_CHANGE_PASSWORD_DATA:
            return { ...state, changePasswordData: action.data };
        case types.SET_CREDENTIALS:
            return { ...state, credentials: action.data };
        case types.SET_PROFILE:
            return { ...state, profile: action.data };
        case types.SET_SIGNUP_DATA:
            return { ...state, signupData: action.data};
        case types.SET_SIGNUP_ERROR:
            return { ...state, signupError: action.error};
        case types.SET_TOKEN:
            return { ...state, token: action.data };
        case types.SIGNUP_START:
            return { ...state, isSigningUp: true, signupError: {} };
        case types.SIGNUP_SUCCESS:
            return { ...state, isSigningUp: false };
        case types.SIGNUP_FAILURE:
            return { ...state, isSigningUp: false, signupError: action.error };
        case types.SIGNUP_WITH_FACEBOOK_START:
            return { ...state, isSigningUpWithFacebook: true, signupWithFacebookError: {} };
        case types.SIGNUP_WITH_FACEBOOK_SUCCESS:
            return { ...state, isSigningUpWithFacebook: false, profile: action.data.user, token: action.data.token };
        case types.SIGNUP_WITH_FACEBOOK_FAILURE:
            return { ...state, isSigningUpWithFacebook: false, signupWithFacebookError: action.error };
        case types.SIGNUP_WITH_GOOGLE_START:
            return { ...state, isSigningUpWithGoogle: true, signupWithGoogleError: {} };
        case types.SIGNUP_WITH_GOOGLE_SUCCESS:
            return { ...state, isSigningUpWithGoogle: false, profile: action.data.user, token: action.data.token };
        case types.SIGNUP_WITH_GOOGLE_FAILURE:
            return { ...state, isSigningUpWithGoogle: false, signupWithGoogleError: action.error };
        case types.UPDATE_AUTH:
            return action.data;
        case types.VERIFY_EMAIL_START:
            return { ...state, isVerifyingEmail: true, verifyEmailError: {} };
        case types.VERIFY_EMAIL_SUCCESS:
            return { ...state, isVerifyingEmail: false, profile: action.profile, token: action.token };
        case types.VERIFY_EMAIL_FAILURE:
            return { ...state, isVerifyingEmail: false, verifyEmailError: action.error };
        case types.RESET_STORE:
            return { ...state, ...clone(initialState) };
        default:
            return state;
    }
}

export default authReducer;
