import validator from 'validator';
import moment from 'moment';

export const isEmail = (email, errorMessage) => {
    return email != null && validator.isEmail(email) ? null : errorMessage || `Please enter a valid email.`;
};

export const isEmailIfTrue = (value, booleanValue, errorMessage) => {
    if (booleanValue === true) {
        return isEmail(value, errorMessage);
    } else {
        return null;
    }
};

export const isRequired = (value, errorMessage) => {
    if (Array.isArray(value) === true && value.length === 0) {
        return errorMessage || '* required';
    }
    return value != null && value !== '' ? null : errorMessage || '* required';
};

export const isRequiredIfTrue = (value, booleanValue, errorMessage) => {
    if (booleanValue === true) {
        return value != null && value !== '' ? null : errorMessage || '* required';
    } else {
        return null;
    }
};

export const isRequiredIfOther = (value, valueOfSelect) => {
    if (valueOfSelect != null && valueOfSelect.toLowerCase() === 'other') {
        return validator.isEmpty(value) === false ? null : '* required';
    } else {
        return true;
    }
};

export const isRequiredIfSiblingNotNull = (value, valueOfSibling, nameOfSibling) => {
    if (valueOfSibling) {
        return validator.isEmpty(value) === false ? null : `This field is required when ${nameOfSibling} has a value.`;
    } else {
        return null;
    }
};

export const isPhoneNumber = value => {
    let onlyNumbers = '';
    if (value != null) {
        onlyNumbers = value.replace(/\D/g, '');
    }
    return value != null && (onlyNumbers.length === 9 || onlyNumbers.length === 10)
        ? null
        : `Please enter a valid phone number`;
};

export const isDate = value => {
    return validator.isDate(new Date(value).toString()) ? null : `Please enter a valid date.`;
};

export const isDateInFuture = value => {
    return moment(value).isSameOrAfter(moment(), 'day') ? null : 'Please choose a date that is in the future.';
};

export const isTrue = (value, message) => {
    return value === true ? null : message;
};

export const isEqual = (value1, value2, message) => {
    return value1 === value2 ? null : message;
};

export const isGreaterThanOrEqualTo = (value1, value2, message) => {
    return value1 >= value2 ? null : message;
};

export const isStringShorterThan = (value, maxLength, errorMessage) => {
    return value == null || value === '' || value.length <= maxLength
        ? null
        : errorMessage || `Shorten to ${maxLength} characters.`;
};

export const isStringLongerThan = (value, minLength, errorMessage) => {
    return value == null || value === '' || value.length >= minLength
        ? null
        : errorMessage || `Lengthen to ${minLength} characters.`;
};

export const isStringNLong = (value, lengthsArray, errorMessage) => {
    if (value == null || value === '') {
        return null;
    } else {
        let matches = false;

        lengthsArray.forEach(length => {
            if (value.length === length) {
                matches = true;
            }
        });

        return matches ? null : errorMessage || 'Match required length';
    }
};
