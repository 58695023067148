import './CreateMemorialService.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import moment from 'moment';

import { handleDateChange, handleTextChange } from 'utils/handle-changes';
import { useSelector } from "react-redux";

import A from 'components/A';
import Button from 'components/Button';
import Datepicker from 'components/Datepicker';
import FormColumn from 'components/FormColumn';
import FormRow from 'components/FormRow';
import Map from 'components/Map';
import Textbox from 'components/Textbox';
import Icon from "../../../../../components/Icon";

const CreateMemorialService = ({handleChange, handlePlaceSelected, section}) => {
    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const disabled = isUploading || isSavingEpilogg;

    let eventDate = typeof(section.eventDate) === 'string' ? moment(section.eventDate.substring(0, 10)).format() : section.eventDate;

    return (
        <div className="create-memorial-service">
            <div className="create-memorial-service__content">
                <FormRow>
                    <Textbox
                        disabled={disabled}
                        label="service/event"
                        name="title1"
                        onChange={(event) => handleTextChange(handleChange, event)}
                        placeholder="service/event"
                        value={section.title1}
                    />
                </FormRow>

                <FormRow className="create-memorial-service__datetime" columnCount={2}>
                    <FormColumn>
                        <Datepicker
                            dateFormat="MMMM dd, yyyy"
                            disabled={disabled}
                            id="dateEventDate"
                            label="event date xx/xx/xxxx"
                            name="eventDate"
                            onChange={(newDate) => handleDateChange(handleChange, 'eventDate', null, newDate)}
                            placeholderText="event date xx/xx/xxxx"
                            selected={eventDate || null}
                            showMonthDropdown
                            showYearDropdown
                        />
                    </FormColumn>

                    <FormColumn>
                        <Datepicker
                            dateFormat="hh:mmaa"
                            disabled={disabled}
                            id="dateEventTime"
                            label="event time"
                            name="eventTime"
                            onChange={(newDate) => handleDateChange(handleChange, 'eventTime', null, newDate)}
                            placeholderText="event time"
                            selected={section.eventTime || null}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="start at"
                            timeIntervals={15}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <div className="o-textbox crate-memorial-service__autocomplete">
                        <Autocomplete
                            className="c-textbox"
                            fields={[
                                'geometry.location',
                                'formatted_address',
                                'name',
                                'place_id'
                            ]}
                            onPlaceSelected={(place) => {
                                handlePlaceSelected(place);
                            }}
                            placeholder="location"
                            types={['establishment']}
                        />
                    </div>
                </FormRow>

                {section.locationName ? (
                    <FormRow className="create-memorial-service__saved-location">
                        <div>
                            <A href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(section.locationAddress)}`}><strong>{section.locationName}</strong></A><br />
                            {section.locationAddress}
                        </div>

                        <Button
                            noStyles
                            onClick={() => handlePlaceSelected(null)}
                        >
                            <Icon type="x" />
                        </Button>
                    </FormRow>
                ) : null}

                <FormRow>
                    <Textbox
                        disabled={disabled}
                        label="additional details"
                        name="text1"
                        onChange={(event) => handleTextChange(handleChange, event)}
                        placeholder="additional details"
                        type="textarea"
                        value={section.text1}
                    />
                </FormRow>

                <FormRow>
                    <div className="o-textbox crate-memorial-service__autocomplete">
                        <Autocomplete
                            className="c-textbox"
                            fields={[
                                'geometry.location',
                                'formatted_address',
                                'name',
                                'website',
                                'place_id'
                            ]}
                            onPlaceSelected={(place) => {
                                console.log(place);
                                handlePlaceSelected(place, 'funeralHome');
                            }}
                            placeholder="funeral/cremation provider"
                            types={['establishment']}
                        />
                    </div>
                </FormRow>

                {section.funeralHomeName ? (
                    <FormRow className="create-memorial-service__saved-location">
                        <div>
                            {section.funeralHomeWebsite ? (
                                <A href={section.funeralHomeWebsite}><strong>{section.funeralHomeName}</strong></A>
                            ) : <strong>{section.funeralHomeName}</strong>}
                            {' '}(<A href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(section.funeralHomeAddress)}`}>directions</A>)<br />
                            {section.funeralHomeAddress}
                        </div>

                        <Button
                            noStyles
                            onClick={() => handlePlaceSelected(null, 'funeralHome')}
                        >
                            <Icon type="x" />
                        </Button>
                    </FormRow>
                ) : null}

            </div>

            <div className="create-memorial-service__map">
                {section.locationLatitude && section.locationLongitude ? (
                    <Map
                        location={{lat: section.locationLatitude, lng: section.locationLongitude}}
                    />
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};

CreateMemorialService.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handlePlaceSelected: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired
};

export default CreateMemorialService;
