import './ProfileModal.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import clone from 'clone';
import MediaQuery from 'react-responsive';
import { validated } from "react-custom-validation";

import { breakpoints, PROFILE_MODAL_NAV_OPTIONS } from 'utils/constants';
import { isRequired } from 'utils/validations';

import { clearChangePasswordData, clearSaveProfileError, setProfile } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import H5 from 'components/H5';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ProfileModalProfileForm from "./ProfileModalProfileForm";
import ProfileModalSecurityForm from "./ProfileModalSecurityForm";

let startingProfile;

let ProfileModal = ({closeModal, profile, show}) => {
    const dispatch = useDispatch();

    const [openTab, setOpenTab] = useState(PROFILE_MODAL_NAV_OPTIONS[0].tab);

    const { changePasswordData } = useSelector(store => store.auth);

    useEffect(() => {
        if(show && !startingProfile) {
            startingProfile = clone(profile);
        }
    }, [profile, show]);

    const handleCancel = () => {
        closeModal();
        dispatch(setProfile(startingProfile));
        dispatch(clearChangePasswordData());
        dispatch(clearSaveProfileError());
        startingProfile = null;
    };

    return (
        <Modal
            className="profile-modal"
            declineButtonOnClick={handleCancel}
            show={show}
        >
            <MediaQuery maxWidth={breakpoints.maxTabletBreakpoint}>
                {(matches) => {
                    if (matches) {
                        return (
                            <>
                                <H5 className="c-modal__title">
                                    {openTab === 'profile' ? 'Profile Info' : 'Change Password'}

                                    <Button className="c-modal__close-button"
                                            onClick={handleCancel}>
                                        <Icon type="x" />
                                    </Button>
                                </H5>
                            </>
                        );
                    } else {
                        return null;
                    }
                }}
            </MediaQuery>

            <div className="profile-modal__nav-wrapper">
                <nav className="profile-modal__nav">
                    {PROFILE_MODAL_NAV_OPTIONS.map((o) => (
                        <Button
                            className={`profile-modal__nav-option ${openTab === o.tab ? 'active-tab' : ''}`}
                            key={o.tab}
                            noStyles
                            onClick={() => setOpenTab(o.tab)}
                        >
                            {o.label}
                        </Button>
                    ))}
                </nav>

                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <A
                                    className="c-button"
                                    onClick={handleCancel}
                                    to="/auth/logout"
                                >
                                    Sign Out
                                </A>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>
            </div>

            {openTab === 'profile' && (
                <ProfileModalProfileForm
                    closeModal={closeModal}
                    handleCancel={handleCancel}
                    profile={profile}
                />
            )}

            {openTab === 'security' && (
                <ProfileModalSecurityForm
                    changePasswordData={changePasswordData}
                    closeModal={closeModal}
                    handleCancel={handleCancel}
                />
            )}
        </Modal>
    );
};

ProfileModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired
};

function profileModalValidationConfig(props) {
    const { firstName, lastName } = props.profile;

    return {
        fields: ['firstName', 'lastName'],
        validations: {
            firstName: [
                [isRequired, firstName]
            ],
            lastName: [
                [isRequired, lastName]
            ]
        }
    }
}


export default ProfileModal = validated(profileModalValidationConfig)(ProfileModal);
