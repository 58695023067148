import './ShareLinks.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'react-clipboard.js';

import Bubble from 'components/Bubble';
import Icon from 'components/Icon';

const ShareLinks = ({className, epilogg}) => {
    const [showCopySuccess, setShowCopySuccess] = useState(false);

    const generateTitle = () => {
        return `Epilogg ${epilogg.displayName ? `for ${encodeURIComponent(epilogg.displayName)}` : ''}`;
    };

    const handleCopy = () => {
        setShowCopySuccess(true);
        setTimeout(() => setShowCopySuccess(false), 3300);
    };

    return (
        <div className={`c-share-links ${className || ''}`}>
            <a className="c-share-links__link" href={`mailto:?subject=${generateTitle()}&body=${window.location.href}`} target="_blank">
                <Icon type="email" />
            </a>

            <a className="c-share-links__link" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank">
                <Icon type="facebook" />
            </a>

            <a className="c-share-links__link" href={`https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${generateTitle()}&source=Epilogg`} target="_blank">
                <Icon type="linkedin" />
            </a>

            <a className="c-share-links__link" href={`https://twitter.com/share?url=${window.location.href}&text=${generateTitle()}&via=epilogg2`} target="_blank">
                <Icon type="twitter" />
            </a>

            <Clipboard
                className="c-share-links__link"
                component="a"
                data-clipboard-text={window.location.href}
                onSuccess={handleCopy}
            >
                <Icon type="copy" />
            </Clipboard>

            <Bubble
                show={showCopySuccess}
            >
                copied <Icon type="check" />
            </Bubble>
        </div>
    );
};

ShareLinks.propTypes = {
    className: PropTypes.string,
    epilogg: PropTypes.object.isRequired
};

export default ShareLinks;
