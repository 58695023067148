import './View.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Helmet } from "react-helmet";
import MediaQuery from 'react-responsive';

import { breakpoints } from "../../../utils/constants";
import { buildDescription, buildTitle } from "../../../utils/build-metadata";

import { getEpilogg, setViewEpilogg } from 'containers/Epilogg/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import Modal from 'components/Modal';
import ShareLinks from 'components/ShareLinks';
import ViewHeader from './components/ViewHeader';
import ViewSection from './components/ViewSection';

const View = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [hasShownOwnershipModal, setHasShownOwnershipModal] = useState(false);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [showPrivateEpiloggModal, setShowPrivateEpiloggModal] = useState(false);
    const [isPrivateEpilogg, setIsPrivateEpilogg] = useState(false);
    const [isMyEpilogg, setIsMyEpilogg] = useState(false);

    const { slug } = useParams();
    const { all, epilogg, isGettingEpilogg, shared, viewEpilogg } = useSelector(store => store.epiloggs);
    const { token } = useSelector(store => store.auth);

    useEffect(() => {
        const initialize = async () => {
            let epiloggId,
                slugParts = slug.split('-');

            if(slugParts.length > 0) {
                epiloggId = parseInt(slugParts.pop());
            }

            if(epiloggId != null && epiloggId === epilogg.id) {
                dispatch(setViewEpilogg(epilogg));
                if(epilogg.publishStatus !== 'published' && !hasShownOwnershipModal) {
                    setShowOwnershipModal(true);
                }
            } else {
                try {
                    await dispatch(getEpilogg(epiloggId));
                } catch(err) {
                    if(err.response.status === 404) {
                        console.log('navigating to 404');
                        history.push('/404');
                    } else if(err.response.status === 403) {
                        setIsPrivateEpilogg(true);
                        setShowPrivateEpiloggModal(true);
                        document.body.classList.add('view-epilogg-page--private');
                    }
                }
            }

            if(all) {
                const matchingEpilogg = all.find(e => e.id === epiloggId);
                const matchingSharedEpilogg = shared.find(e => e.id === epiloggId);
                setIsMyEpilogg(matchingEpilogg != null || matchingSharedEpilogg != null);
            }

            setHasShownOwnershipModal(true);
        };

        document.body.classList.add('view-epilogg-page');
        initialize();

        return () => {
            document.body.classList.remove('view-epilogg-page');
            document.body.classList.remove('view-epilogg-page--private');
        }
    }, [dispatch, epilogg, slug, history]);

    const generateTitle = () => {
        let title = 'epilogg';

        if(viewEpilogg.displayName) {
            title = `${viewEpilogg.displayName} | epilogg`;
        }

        return title;
    };

    return (
        <div className={`view-epilogg ${isPrivateEpilogg ? 'view-epilogg--private' : ''}`}>
            <Helmet>
                <title>{buildTitle(viewEpilogg)}</title>
                <meta name="description" content={buildDescription(viewEpilogg)} />
            </Helmet>

            {isGettingEpilogg ? (
                <div className="view-epilogg__activity-indicator">
                    <ActivityIndicator />
                </div>
            ) : (
                <>
                    <ViewHeader
                        epilogg={viewEpilogg}
                        isMyEpilogg={isMyEpilogg}
                    />

                    {viewEpilogg && viewEpilogg.content && viewEpilogg.content.map((section, index) => {
                        return (
                            <ViewSection
                                key={section.id}
                                index={index}
                                section={section}
                            />
                    )})}

                </>
            )}

            <Modal
                declineButtonOnClick={() => {
                    setShowOwnershipModal(false);
                }}
                declineButtonText="OK"
                show={showOwnershipModal}
                title="your epilogg"
            >
                This is your epilogg. It is not publicly viewable yet, you can preview it now - selecting the PUBLISH button in the EDITOR will make it viewable by all.
            </Modal>

            <Modal
                declineButtonOnClick={() => {
                    setShowPrivateEpiloggModal(false);
                    setTimeout(() => {
                        if(token) {
                            history.push('/dashboard');
                        } else {
                            history.push('/auth/signup');
                        }
                    }, 100);
                }}
                declineButtonText="OK"
                show={showPrivateEpiloggModal}
                title="private epilogg"
            >
                This epilogg is not publicly viewable yet. Please check back again later.
            </Modal>

            {viewEpilogg && Object.keys(viewEpilogg).length > 0 && viewEpilogg.publishStatus === 'published' && (
                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <div className="view-epilogg__share-links">
                                    <ShareLinks epilogg={viewEpilogg} />
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>
            )}
        </div>
    );
};

export default View;
