import clone from 'clone';

import * as types from './constants';

export const initialState = {
    isUploading: false,
    uploadError: {},
    isGettingPresignedUrl: false,
    getPresignedUrlError: {}
};

function uploadReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.GET_PRESIGNED_URL_START:
            return { ...state, isGettingPresignedUrl: true, getPresignedUrlError: {} };
        case types.GET_PRESIGNED_URL_SUCCESS:
            return { ...state, isGettingPresignedUrl: false };
        case types.GET_PRESIGNED_URL_FAILURE:
            return { ...state, isGettingPresignedUrl: false, getPresignedUrlError: action.error };
        case types.UPLOAD_FILE_START:
            return { ...state, isUploading: true, uploadError: {} };
        case types.UPLOAD_FILE_SUCCESS:
            return { ...state, isUploading: false };
        case types.UPLOAD_FILE_FAILURE:
            return { ...state, isUploading: false, uploadError: action.error };
        default:
            return state;
    }
}

export default uploadReducer;
