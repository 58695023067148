import './AddMenu.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import clone from 'clone';

import { saveEpilogg, setEpilogg } from 'containers/Epilogg/store/actions';

import Button from 'components/Button';
import H5 from 'components/H5';
import Icon from 'components/Icon';
import H6 from "../../../../../components/H6";

const SECTION_TYPES = [
    {
        title: 'create more sections',
        subtitle: 'photos, military, hobbies, awards, recipes, phrases, etc',
        options: [
            /*{
                label: 'guestbook',
                layout: ['guestbook'],
                type: 'g',
                shouldDisable: (sections) => sections.filter(s => s.type === 'g').length > 0
            },*/ {
                label: 'service/events',
                layout: ['image', 'textbutton'],
                type: 'm'
            }, {
                label: 'memorial donation',
                layout: ['image'],
                type: 'd'
            }, {
                label: 'support',
                layout: ['flowers', 'text'],
                type: 'flower',
                shouldDisable: (sections) => sections.filter(s => s.type === 'flower').length > 0
            }
        ]
    }, {
        options: [
            {
                label: '1 image',
                layout: ['image'],
                type: 'i',
            }, {
                label: '2 images',
                layout: ['image', 'image'],
                type: 'ii',
            }, {
                label: '3 images',
                layout: ['image', 'image', 'image'],
                type: 'iii',
            }, {
                label: 'family & loved ones',
                layout: ['family'],
                type: 'f',
            }
        ]
    }, {
        options: [
            {
                label: 'split image right and text left',
                layout: ['text', 'image'],
                type: 'ti',
            }, {
                label: 'split image left and text right',
                layout: ['image', 'text'],
                type: 'it',
            }, {
                label: '2/3rd layout image right',
                layout: ['text23', 'image'],
                type: 't23i',
            }, {
                label: '2/3rd layout image left',
                layout: ['image', 'text23'],
                type: 'it23',
            }
        ]
    }, {
        options: [
            {
                label: '1 column section',
                layout: ['text'],
                type: 't',
            }, {
                label: '2 column section',
                layout: ['text', 'text'],
                type: 'tt',
            }, {
                label: '3 column section',
                layout: ['text', 'text', 'text'],
                type: 'ttt',
            }
        ]
    }
]

const AddMenu = ({isInHeader, section}) => {
    const dispatch = useDispatch();

    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const { epilogg } = useSelector(store => store.epiloggs);

    const handleClick = (option) => {
        let newEpiloggObj = clone(epilogg);
        let newSectionObj = {
            type: option.type
        };

        if(option.type === 'f') {
            newSectionObj.subtitle = 'family and friends';
        } else if(option.type === 'g') {
            newSectionObj.navTitle = 'guestbook';
        } else if(option.type === 'flowers') {
            newSectionObj.navTitle = 'support';
        }

        newEpiloggObj.content.push(newSectionObj);
        dispatch(setEpilogg(newEpiloggObj));

        setMenuIsOpened(false);
        dispatch(saveEpilogg());
    };

    return (
        <div className={`c-add-menu ${menuIsOpened ? 'a-add-menu--open' : ''}`}>
            <div className="c-add-menu__background" onClick={() => setMenuIsOpened(false)} />

            <div className="c-add-menu--wrapper">
                <div className="c-add-menu--inner">
                    {SECTION_TYPES.map((row, i) => (
                        <div className="c-add-menu__row" key={i}>
                            {row.title ? (
                                <H5 className="c-add-menu__row-title">
                                    {row.title}
                                </H5>
                            ) : null}

                            {row.subtitle ? (
                                <H6 className="c-add-menu__row-subtitle">
                                    {row.subtitle}
                                </H6>
                            ) : null}

                            <div className="c-add-menu__columns">
                                {row.options.map((option) => (
                                    <Button
                                        className="c-add-menu__option"
                                        disabled={option.shouldDisable ? option.shouldDisable(epilogg.content) : false}
                                        key={option.type}
                                        noStyles
                                        onClick={() => handleClick(option)}
                                    >
                                        <div className={`c-add-menu__icons a-add-menu__icons--${option.type}`}>
                                            {option.layout.map((icon, index) => (
                                                <Icon className={icon.includes('23') ? 'a-icon--two-thirds' : null} type={`layout-${icon.replace('23', '')}`} key={index} />
                                            ))}
                                        </div>
                                        {option.label}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Button
                noStyles
                onClick={() => setMenuIsOpened(!menuIsOpened)}
                title="add another section?"
            >
                <Icon type="plus" />
            </Button>
        </div>
    );
};

AddMenu.propTypes = {
    isInHeader: PropTypes.bool,
    section: PropTypes.object
};

export default AddMenu;
