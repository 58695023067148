import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import { breakpoints } from 'utils/constants';
import { handleTextChange } from 'utils/handle-changes';
import clone from "clone";
import { useDispatch, useSelector } from "react-redux";

import { isEmail, isRequired } from 'utils/validations';

import { saveProfile, setProfile } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import H5 from 'components/H5';
import Form from 'components/Form';
import FormError from 'components/FormError';
import FormRow from 'components/FormRow';
import Icon from 'components/Icon';
import ProfileModalAvatarUpload from './ProfileModalAvatarUpload';
import Textbox from 'components/Textbox';
import {validated} from "react-custom-validation";

let ProfileModalProfileForm = ({closeModal, handleCancel, profile, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();

    const { isSavingProfile, saveProfileError } = useSelector(store => store.auth);
    const { isGettingPresignedUrl, isUploading } = useSelector(store => store.upload);

    const handleChange = (name, value) => {
        let newProfileObj = clone(profile);
        newProfileObj[name] = value;
        dispatch(setProfile(newProfileObj));
    };

    const handleSubmit = async (isValid) => {
        if(isValid) {
            try {
                await dispatch(saveProfile());
                closeModal();
            } catch(err) {
                console.log(err);
            }
        }
    };

    return (
        <Form>
            <FormRow
                className="profile-modal__avatar-info-wrapper"
            >
                <div className="profile-modal__avatar-wrapper">
                    <ProfileModalAvatarUpload
                        profile={profile}
                    />
                </div>

                <div className="profile-modal__info-wrapper">
                    <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                        {(matches) => {
                            if (matches) {
                                return (
                                    <H5 className="c-modal__title">
                                        Profile Info

                                        <Button className="c-modal__close-button"
                                                onClick={handleCancel}>
                                            <Icon type="x" />
                                        </Button>
                                    </H5>
                                );
                            } else {
                                return null;
                            }
                        }}
                    </MediaQuery>

                    <FormRow>
                        <Textbox
                            id="txtFirstName"
                            label="First Name"
                            name="firstName"
                            placeholder="first name"
                            required
                            type="text"
                            validation={$validation.firstName}
                            value={profile.firstName || ''}
                            {...$field('firstName', (event) => handleTextChange(handleChange, event))}
                        />
                    </FormRow>

                    <FormRow>
                        <Textbox
                            id="txtLastName"
                            label="Last Name"
                            name="lastName"
                            placeholder="last name"
                            required
                            type="text"
                            validation={$validation.lastName}
                            value={profile.lastName || ''}
                            {...$field('lastName', (event) => handleTextChange(handleChange, event))}
                        />
                    </FormRow>

                    <FormRow>
                        <Textbox
                            id="txtEmail"
                            label="Email"
                            name="email"
                            placeholder="email"
                            required
                            type="email"
                            validation={$validation.email}
                            value={profile.email || ''}
                            {...$field('email', (event) => handleTextChange(handleChange, event))}
                        />
                    </FormRow>

                    <FormError
                        error={saveProfileError}
                    />

                    <FormRow className="profile-modal__button-wrapper">
                        <Button
                            disabled={isGettingPresignedUrl || isUploading}
                            onClick={(event) => {
                                event.preventDefault();
                                $submit(() => handleSubmit(true), () => handleSubmit(false));
                            }}
                            showActivityIndicator={isSavingProfile}
                        >
                            Save
                        </Button>

                        <Button
                            disabled={isSavingProfile || isGettingPresignedUrl || isUploading}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </FormRow>

                    <MediaQuery maxWidth={breakpoints.maxTabletBreakpoint}>
                        {(matches) => {
                            if (matches) {
                                return (
                                    <FormRow className="profile-modal__mobile-logout-button">
                                        <A
                                            className="c-button"
                                            onClick={handleCancel}
                                            to="/auth/logout"
                                        >
                                            Sign Out
                                        </A>
                                    </FormRow>
                                );
                            } else {
                                return null;
                            }
                        }}
                    </MediaQuery>
                </div>
            </FormRow>
        </Form>
    );
};

ProfileModalProfileForm.propTypes = {

};

function profileModalProfileFormValidationConfig(props) {
    const { email, firstName, lastName } = props.profile;

    return {
        fields: ['email', 'firstName', 'lastName'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            firstName: [
                [isRequired, firstName]
            ],
            lastName: [
                [isRequired, lastName]
            ]
        }
    }
}


export default ProfileModalProfileForm = validated(profileModalProfileFormValidationConfig)(ProfileModalProfileForm);
