import './Login.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { setTitle } from 'containers/App/store/actions';

import LoginForm from './LoginForm';

const Login = () => {
    const dispatch = useDispatch();

    const credentials = useSelector(store => store.auth.credentials);

    useEffect(() => {
        document.body.classList.add('login-page');
        dispatch(setTitle('log in'));

        return () => {
            document.body.classList.remove('login-page');
        }
    }, [dispatch]);

    return (
        <div className="login-form--wrapper">
            <Helmet>
                <title>Log In to Your Epilogg Account</title>
            </Helmet>

            <LoginForm
                credentials={credentials}
            />
        </div>
    );
};

export default Login;
