import './ViewPlantATree.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from "../../../../../components/Button";
import H5 from "../../../../../components/H5";
import H3 from "../../../../../components/H3";
import H2 from "../../../../../components/H2";
import {getTreePurchaseUrl} from "../../../../TreePurchase/store/actions";
import {useDispatch} from "react-redux";
import Modal from "../../../../../components/Modal";
import {useHistory} from "react-router";

const RESULT_TITLE = {
    cancel: 'purchase cancelled',
    other: 'purchase unsuccessful',
    success: 'success!',
};

const RESULT_CONTENT = {
    cancel: 'You cancelled your payment. To continue, select an option again.',
    other: 'We were unable to complete your purchase. Please select an option to try again.',
    success: 'Success! You have successfully planted tree(s) to honor your loved one. Thank you. You will also receive an email confirmation of this purchase.',
};

const ViewPlantATree = ({index, section}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    console.log(index);

    const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [result, setResult] = useState('');

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('tree_result')) {
            setResult(query.get('tree_result'));
            setShowResultsModal(true);
            history.push({search: ''});
        }
    }, []);

    const handlePurchasePress = async (priceId) => {
        if(!priceId) {
            return;
        }

        let res = await dispatch(getTreePurchaseUrl(priceId));

        console.log('res', res)

        if(res.data.data.checkoutUrl) {
            window.location.href = res.data.data.checkoutUrl;
        }
    };

    return (
        <div className="c-view-section__plant_a_tree">
            {showPurchaseOptions ? (
                <div className="c-view-section--plant_a_tree-inner">
                    <div className="tree__purchase">
                        <H2>
                            plant a tree in honor of your loved one.
                        </H2>

                        <div className="tree__options-container">

                            {section.products.map((product) => (
                                <div className="tree__option">
                                    <img src={`/obituary/img/tree--${index % 2 === 0 ? 'white' : 'grey'}-bg.jpg`} alt="Picture of a tree" className="tree__tree-picture" />

                                    <Button
                                        className="tree__purchase-option-button"
                                        onClick={() => handlePurchasePress(product.priceId)}
                                    >
                                        {product.name}

                                        <span>
                                            ${product.price}
                                        </span>
                                    </Button>
                                </div>
                            ))}
                        </div>

                        <img src="/obituary/img/arbor-day-foundation-logo.png" alt="Arbor Day Foundation logo" className="tree__arbor-day-logo" />
                    </div>
                </div>
            ) : (
                <div className="c-view-section--plant_a_tree-inner">
                    <div className="tree__left-col">
                        <img src={`/obituary/img/tree--${index % 2 === 0 ? 'white' : 'grey'}-bg.jpg`} alt="Picture of a tree" className="tree__tree-picture" />

                        <H3>
                            plant a tree in honor of your loved one.
                        </H3>

                        <p>
                            In partnership with: <br />
                            <img src="/obituary/img/arbor-day-foundation-logo.png" alt="Arbor Day Foundation logo" className="tree__arbor-day-logo" />
                        </p>

                        <H5>
                            Honor your loved one and help forest restoration by planting a memorial tree.
                        </H5>
                    </div>

                    <div className="tree__right-col">
                        <Button
                            className="tree__show-options-button"
                            onClick={() => setShowPurchaseOptions(true)}
                        >
                            plant a tree
                        </Button>
                    </div>
                </div>
            )}

            <Modal
                className="plant-a-tree__success"
                declineButtonOnClick={() => {
                    setShowResultsModal(false);
                    setResult(null);
                }}
                declineButtonText="OK"
                show={showResultsModal}
                title={RESULT_TITLE[result]}
            >
                <p>
                    {RESULT_CONTENT[result]}
                </p>
            </Modal>
        </div>
    );
};

export default ViewPlantATree;
