import './LoginWithGoogle.scss';

import React from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { setSignupError, signupWithGoogle } from 'containers/Auth/store/actions';

import Button from 'components/Button';
import Icon from 'components/Icon';

const LoginWithGoogle = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleCallback = async (response) => {
        if(response && response.tokenObj && response.tokenObj.id_token && response.profileObj && response.profileObj.email) {
            try {
                let data = {
                    idToken: response.tokenObj.id_token,
                    email: response.profileObj.email
                };
                const signupRes = await dispatch(signupWithGoogle(data));
                if (signupRes.data.data.epiloggs && signupRes.data.data.epiloggs.length > 0) {
                    history.push('/dashboard');
                } else {
                    history.push('/auth/complete');
                }
            } catch(err) {

            }
        } else {
            dispatch(setSignupError({
                friendlyMessage: "There was an error with Google. Data is missing. Please try again."
            }));
        }
    };

    return (
        <div className="c-login-with-google">
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Login"
                onSuccess={handleCallback}
                onFailure={(err) => console.log('google failed', err)}
                cookiePolicy={'single_host_origin'}
                render={(renderProps) => {
                    return (
                        <Button
                            disabled={renderProps.disabled}
                            onClick={renderProps.onClick}
                        >
                            <Icon type="google"/> Continue with Google
                        </Button>
                    );
                }}
            />
        </div>
    );
};

export default LoginWithGoogle;
