import './CreateImageColumn.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import heic2any from "heic2any";

import { handleTextChange } from 'utils/handle-changes';
import { resizeImage } from 'utils/images';

import { uploadFiles } from 'containers/Upload/actions';
import { saveEpilogg } from 'containers/Epilogg/store/actions';

import FileUpload from 'components/FileUpload';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';

const CreateImageColumn = ({columnIndex, handleChange, section}) => {
    const dispatch = useDispatch();

    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [preview, setPreview] = useState(null);
    const [cachedImageUrl, setCachedImageUrl] = useState(null);

    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const name = `image${columnIndex + 1}`;
    const textName = `text${columnIndex + 1}`;

    useEffect(() => {
        if(!cachedImageUrl && section[`${name}Artifact`]) {
            setCachedImageUrl(section[`${name}Artifact`].url);
        }
    }, [cachedImageUrl, name, section, setCachedImageUrl]);

    const onDelete = () => {
        handleChange(`${name}ArtifactId`, null);
        setPreview(null);
        setCachedImageUrl(null);
        dispatch(saveEpilogg());
    };

    const onDrop = async (file) => {
        setIsUploadingImage(true);
        let convertedImage;
        if(file.type.toLowerCase() === 'image/heic') {
            try {
                convertedImage = await heic2any({blob: file, });
            } catch(err) {
                console.log(err);
            }

        }
        file.preview = URL.createObjectURL(convertedImage || file);
        setPreview(file);

        try {
            let resizedImage = await resizeImage(file, 1000);
            let uploadResult = await dispatch(uploadFiles(resizedImage));
            console.log(uploadResult);
            handleChange(`${name}ArtifactId`, uploadResult.file.artifactId);
            setIsUploadingImage(false);
        } catch(err) {
            console.log('upload file err', err);
            //setPreview(null);
            setIsUploadingImage(false);
        }
    };

    return (
        <div className="c-create-section-column a-create-section-column--image">
            <FormRow>
                <FileUpload
                    disabled={isUploadingImage || isUploading || isSavingEpilogg}
                    imageUrl={cachedImageUrl}
                    isUploadingImage={isUploadingImage}
                    name={name}
                    onDelete={onDelete}
                    onDrop={onDrop}
                    preview={preview}
                    uploadPercentage={0}
                />
            </FormRow>

            {['i', 'ii', 'iii'].indexOf(section.type) !== -1 ? (
                <FormRow>
                    <Textbox
                        disabled={isUploadingImage || isUploading || isSavingEpilogg}
                        label="caption"
                        name={textName}
                        onChange={(event) => handleTextChange(handleChange, event)}
                        placeholder="caption"
                        type="textarea"
                        value={section[textName]}
                    />
                </FormRow>
            ) : null}
        </div>
    );
};

CreateImageColumn.propTypes = {
    columnIndex: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired
};

export default CreateImageColumn;
