import * as types from './constants';
import Request from "../../../utils/request";
import PATHS from "../../../utils/paths";
import {generateFriendlyErrorString} from "../../../utils/data";

const getTreePurchaseUrlStart = () => ({
    type: types.GET_TREE_PURCHASE_URL_START
});

const getTreePurchaseUrlSuccess = () => ({
    type: types.GET_TREE_PURCHASE_URL_SUCCESS
});

const getTreePurchaseUrlFailure = () => ({
    type: types.GET_TREE_PURCHASE_URL_FAILURE
});

export const getTreePurchaseUrl = (priceId) => (dispatch, getStore) => {
    dispatch(getTreePurchaseUrlStart());

    let request = new Request(getStore().auth.token);

    let epiloggId = getStore().epiloggs.viewEpilogg.id;

    return request.post(PATHS.treePurchase.getUrl(epiloggId), {priceId})
        .then((res) => {
            dispatch(getTreePurchaseUrlSuccess());
            return res;
        })
        .catch((err) => {
            console.log(err, err.response);
            err.friendlyMessage = generateFriendlyErrorString('We were unable to begin the checkout process. Please try again.', err.response.data);
            dispatch(getTreePurchaseUrlFailure(err));
        })
};
