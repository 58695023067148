import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { validated } from "react-custom-validation";
import clone from 'clone';

import { handleTextChange } from 'utils/handle-changes';
import { isRequired, isRequiredIfTrue } from 'utils/validations';

import { saveNewComment } from 'containers/Epilogg/store/actions';

import Form from 'components/Form';
import FormRow from 'components/FormRow';
import Modal from 'components/Modal';
import Textbox from 'components/Textbox';
import Button from 'components/Button';
import FormError from 'components/FormError';

let NewCommentModal = ({closeModal, isAuthenticated, newComment, setNewComment, show, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();
    const { isSavingNewComment, saveNewCommentError } = useSelector(store => store.epiloggs);

    const handleChange = (name, value) => {
        let newCommentObj = clone(newComment);
        newCommentObj[name] = value;
        setNewComment(newCommentObj);
    };

    const handleClose = () => {
        closeModal();
        $fieldEvent('reset');
    };

    const handleSubmit = async (isValid) => {
        if(isValid) {
            try {
                await dispatch(saveNewComment());
                closeModal();
            } catch(err) {

            }
        }
    };

    return (
        <Modal
            className="new-comment-modal"
            declineButtonOnClick={handleClose}
            show={show}
            title="Leave New Comment"
        >
            <Form>
                {!isAuthenticated ? (
                    <FormRow>
                        <Textbox
                            id="txtDisplayName"
                            label="Your Name"
                            name="displayName"
                            placeholder="your name"
                            required
                            type="text"
                            validation={$validation.displayName}
                            value={newComment.displayName || ''}
                            {...$field('displayName', (event) => handleTextChange(handleChange, event))}
                        />
                    </FormRow>
                ) : null}

                <FormRow>
                    <Textbox
                        id="txtText"
                        label="Comment"
                        name="text"
                        placeholder="comment"
                        required
                        type="textarea"
                        validation={$validation.text}
                        value={newComment.text || ''}
                        {...$field('text', (event) => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormError
                    error={saveNewCommentError}
                />

                <FormRow className="new-comment-modal__button-wrapper">
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleSubmit(true), () => handleSubmit(false));
                        }}
                        showActivityIndicator={isSavingNewComment}
                    >
                        Submit
                    </Button>

                    <Button
                        disabled={isSavingNewComment}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </FormRow>
            </Form>
        </Modal>
    );
};

NewCommentModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    newComment: PropTypes.object.isRequired,
    setNewComment: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

function newCommentModalValidationConfig(props) {
    const { isAuthenticated } = props;
    const { displayName, text } = props.newComment;

    return {
        fields: ['displayName', 'text'],
        validations: {
            displayName: [
                [isRequiredIfTrue, displayName, !isAuthenticated]
            ],
            text: [
                [isRequired, text]
            ]
        }
    }
}


export default NewCommentModal = validated(newCommentModalValidationConfig)(NewCommentModal);
