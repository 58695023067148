import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import requireAuth from 'containers/Auth/requireAuth';

import AcceptInvitationToEdit from "./Invitations/AcceptInvitationToEdit";
import Create from 'containers/Epilogg/Create';
import Unfollow from "containers/Epilogg/Unfollow";
import View from 'containers/Epilogg/View';

const Epilogg = () => {
    useEffect(() => {
        document.body.classList.add('epilogg-page');

        return () => {
            document.body.classList.remove('epilogg-page');
        }
    }, []);

    return (
        <Switch>
            <Route path="/epilogg/edit/:epiloggId" component={requireAuth(Create)} />
            <Route path="/epilogg/collaborator/accept" component={requireAuth(AcceptInvitationToEdit)} />
            <Route path="/epilogg/unfollow/:token" component={Unfollow} />
        </Switch>
    );
};

Epilogg.propTypes = {

};

export default Epilogg;
