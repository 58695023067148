import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import { breakpoints } from 'utils/constants';
import { handleTextChange } from 'utils/handle-changes';
import clone from "clone";
import { useDispatch, useSelector } from "react-redux";
import { validated } from "react-custom-validation";

import {isEqual, isRequired, isStringLongerThan} from 'utils/validations';

import { changePassword, setChangePasswordData } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import H5 from 'components/H5';
import Form from 'components/Form';
import FormError from 'components/FormError';
import FormRow from 'components/FormRow';
import Icon from 'components/Icon';
import Textbox from 'components/Textbox';

let ProfileModalSecurityForm = ({changePasswordData, closeModal, handleCancel, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const { changePasswordError, isChangingPassword } = useSelector(store => store.auth);

    const handleChange = (name, value) => {
        let newChangePasswordData = clone(changePasswordData);
        newChangePasswordData[name] = value;
        dispatch(setChangePasswordData(newChangePasswordData));
    };

    const handleSubmit = async (isValid) => {
        setShowSuccessMessage(false);
        if(isValid) {
            try {
                await dispatch(changePassword());
                setShowSuccessMessage(true);
                $fieldEvent('reset');
            } catch(err) {
                console.log(err);
            }
        }
    };

    return (
        <Form>
            <div className="profile-modal__info-wrapper">
                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <H5 className="c-modal__title">
                                    Change Password

                                    <Button className="c-modal__close-button"
                                            onClick={handleCancel}>
                                        <Icon type="x" />
                                    </Button>
                                </H5>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>

                <FormRow>
                    <Textbox
                        id="txtOldPassword"
                        label="Your Current Password"
                        name="oldPassword"
                        placeholder="your current password"
                        required
                        type="password"
                        validation={$validation.oldPassword}
                        value={changePasswordData.oldPassword || ''}
                        {...$field('oldPassword', (event) => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        id="txtPassword"
                        label="new password"
                        name="newPassword"
                        placeholder="new password"
                        required
                        type="password"
                        validation={$validation.newPassword}
                        value={changePasswordData.newPassword || ''}
                        {...$field('newPassword', (event) => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        id="txtPassword2"
                        label="confirm new password"
                        name="newPassword2"
                        placeholder="confirm new password"
                        required
                        type="password"
                        validation={$validation.newPassword2}
                        value={changePasswordData.newPassword2 || ''}
                        {...$field('newPassword2', (event) => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormError
                    error={changePasswordError}
                />

                <FormRow className="profile-modal__button-wrapper">
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleSubmit(true), () => handleSubmit(false));
                        }}
                        showActivityIndicator={isChangingPassword}
                    >
                        Change
                    </Button>

                    <Button
                        disabled={isChangingPassword}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </FormRow>

                {showSuccessMessage && (
                    <FormRow>
                        You have successfully updated your password.
                    </FormRow>
                )}

                <MediaQuery maxWidth={breakpoints.maxTabletBreakpoint}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <FormRow className="profile-modal__mobile-logout-button">
                                    <A
                                        className="c-button"
                                        onClick={handleCancel}
                                        to="/auth/logout"
                                    >
                                        Sign Out
                                    </A>
                                </FormRow>
                            );
                        } else {
                            return null;
                        }
                    }}
                </MediaQuery>
            </div>
        </Form>
    );
};

ProfileModalSecurityForm.propTypes = {

};

function profileModalSecurityFormValidationConfig(props) {
    const { newPassword, newPassword2, oldPassword } = props.changePasswordData;

    return {
        fields: ['newPassword', 'newPassword2', 'oldPassword'],
        validations: {
            newPassword: [
                [isRequired, newPassword],
                [isStringLongerThan, newPassword, 7, 'Password must be at least 8 characters long']
            ],
            newPassword2: [
                [isRequired, newPassword2],
                [isEqual, newPassword2, newPassword, 'New passwords must match']
            ],
            oldPassword: [
                [isRequired, oldPassword]
            ]
        }
    }
}


export default ProfileModalSecurityForm = validated(profileModalSecurityFormValidationConfig)(ProfileModalSecurityForm);
