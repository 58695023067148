import './ViewHeader.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from 'components/Button';
import H1 from 'components/H1';
import Icon from 'components/Icon';
import ViewActionMenu from 'containers/Epilogg/View/components/ViewActionMenu';
import {IMAGE_SIZES} from "../../../../../utils/constants";

const ViewHeader = ({epilogg, isMyEpilogg}) => {
    const [showTreeSection, setShowTreeSection] = useState(false);

    const navItems = epilogg && epilogg.content ? epilogg.content.filter((section) => /g|m/.test(section.type)) : [];
    const flowersSection = epilogg?.content?.find((section) => section.type === 'flower');
    const treeSection = epilogg?.content?.find((section) => section.type === 'plant_a_tree');

    const generateNavText = (type) => {
        switch(type) {
            case 'g':
                return 'guestbook';
            case 'm':
                return 'events';
            default:
                return '';
        }
    };

    const handleJumpNavClick = (section, type) => {
        if(!section && type) {
            section = epilogg.content.find((section) => section.type === type);
        }

        if(!section) {
            return;
        }

        const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

        const targetEl = document.querySelector(`.c-view-section[data-id="${section.id}"]`);
        if(!targetEl) {
            return;
        }
        const scrollToValue = distanceToTop(targetEl);
        window.scrollTo({left: 0, top: scrollToValue, behavior: 'smooth'});
    };

    return (
        <header className="view-epilogg__header">
            <div className="view-epilogg__header-background" style={epilogg.bannerPicArtifact && epilogg.bannerPicArtifact.url ? {backgroundImage: `url(${epilogg.bannerPicArtifact.url.replace('/original/', IMAGE_SIZES.BANNER)})`} : (epilogg?.id ? {backgroundImage: `url(/obituary/img/nobanner/bg${epilogg.id % 4}.jpg)`} : null)}>
                <ViewActionMenu
                    epilogg={epilogg}
                    isMyEpilogg={isMyEpilogg}
                />

                <H1 className="view-epilogg__header-display-name">
                    {epilogg.displayName || ''}
                </H1>
            </div>

            <div className="view-epilogg__header-avatar" style={epilogg.profilePicArtifact && epilogg.profilePicArtifact.url ? {backgroundImage: `url(${epilogg.profilePicArtifact.url.replace('/original/', IMAGE_SIZES.AVATAR)})`} : {backgroundImage: `url(/obituary/img/noimage/default_avatar_img1.svg)`}} />

            <div className="view-epilogg__header-date-location-wrapper">
                <div className="view-epilogg__header-dates">
                    {epilogg.dateOfBirth ? moment(epilogg.dateOfBirth.substring(0, 10)).format('MMMM DD, YYYY') : ''}
                    {epilogg.dateOfBirth && epilogg.dateOfDeath ? ' - ' : ''}
                    {epilogg.dateOfDeath ? moment(epilogg.dateOfDeath.substring(0, 10)).format('MMMM DD, YYYY') : ''}

                    {epilogg.dateOfBirth ? (
                        <span className="view-epilogg__header-age">
                            age: {moment(epilogg.dateOfDeath || new Date()).diff(epilogg.dateOfBirth, 'years')}
                        </span>
                    ) : null}
                </div>

                <div className="view-epilogg__header-location">
                    {epilogg.locationAddress || ''}
                </div>
            </div>

            {navItems && navItems.length > 0 ? (
                <nav className="view-epilogg__header-jump-nav">
                    <div className="view-epilogg__header-jump-nav-inner">
                        {navItems.map((section, i) => (
                            <Button
                                className="view-epilogg__header-jump-button"
                                key={i}
                                noStyles
                                onClick={() => handleJumpNavClick(section)}
                            >
                                {generateNavText(section.type)}
                            </Button>
                        ))}

                        {treeSection && (
                            <Button
                                className="view-epilogg__header-jump-button"
                                noStyles
                                onClick={() => handleJumpNavClick(treeSection)}
                            >
                                plant a tree
                            </Button>
                        )}

                        {flowersSection && (
                            <Button
                                className="view-epilogg__header-jump-button"
                                noStyles
                                onClick={() => handleJumpNavClick(flowersSection)}
                            >
                                gifts/flowers
                            </Button>
                        )}
                    </div>
                </nav>
            ) : null}
        </header>
    );
};

ViewHeader.propTypes = {
    epilogg: PropTypes.object.isRequired,
    isMyEpilogg: PropTypes.bool
};

export default ViewHeader;
