import './FamilyMember.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';
import {IMAGE_SIZES} from "../../../../utils/constants";

const FamilyMember = ({onDeletePress, onEditPress, person}) => {
    const [cachedImageUrl, setCachedImageUrl] = useState(null);

    useEffect(() => {
        setCachedImageUrl(person.profilePicPreview || (person.profilePicArtifact ? person.profilePicArtifact.url : null));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [person.profilePicArtifactId]);

    return (
        <div className="c-family-member">
            {person.profilePicArtifact && person.profilePicArtifact.status === 'P' ? (
                <div className="c-family-member__image c-family-member__image--pending">
                    Image is processing. Please refresh.
                </div>
            ) : (
                <div className="c-family-member__image"
                     style={{backgroundImage: `url(${cachedImageUrl ? cachedImageUrl.replace('/original/', IMAGE_SIZES.PICTURE) : null})`}}
                />
            )}


            <div className="c-family-member__name">
                {person.displayName}
            </div>

            <div className="c-family-member__relation">
                {person.description}
            </div>

            {onDeletePress ? (
                <Button
                    className="c-family-member__delete-button"
                    onClick={onDeletePress}
                >
                    <Icon type="x" />
                </Button>
            ) : null}

            {onEditPress ? (
                <Button
                    className="c-family-member__edit-button"
                    onClick={onEditPress}
                >
                    <Icon type="edit" />
                </Button>
            ) : null}
        </div>
    );
};

FamilyMember.propTypes = {
    onDeletePress: PropTypes.func,
    onEditPress: PropTypes.func,
    person: PropTypes.object.isRequired
};

export default FamilyMember;
