import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validated } from 'react-custom-validation';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clone from 'clone';

import { isEqual, isRequired } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';

import { clearCredentials, resetPassword, setCredentials } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import Form from 'components/Form';
import FormError from 'components/FormError';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';
import Modal from 'components/Modal';

let ResetPasswordForm = ({credentials, token, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    let { resetPasswordError, isResettingPassword } = useSelector(store => store.auth);

    const handleChange = (name, value) => {
        let newCredentialsObj = clone(credentials);
        newCredentialsObj[name] = value;
        dispatch(setCredentials(newCredentialsObj));
    };

    const handleSubmit = (isValid) => {
        if(isValid === true && token) {
            dispatch(resetPassword(token))
                .then((res) => {
                    setShowSuccessModal(true);
                    dispatch(clearCredentials());
                    $fieldEvent('reset');
                });
        }
    };

    return (
        <Form className="reset-password-form auth-form--skinny">
            <FormRow>
                <Textbox
                    id="txtPassword"
                    label="Password"
                    name="password"
                    placeholder="password"
                    required
                    type="password"
                    validation={$validation.password}
                    value={credentials.password || ''}
                    {...$field('password', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtPassword2"
                    label="Confirm Password"
                    name="password2"
                    placeholder="confirm password"
                    required
                    type="password"
                    validation={$validation.password2}
                    value={credentials.password2 || ''}
                    {...$field('password2', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormError
                error={resetPasswordError}
            />

            <FormRow className="reset-password-form__button-wrapper auth-form__button-wrapper">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isResettingPassword}
                >
                    Save New Password
                </Button>
            </FormRow>

            <FormRow className="auth-form__link-wrapper">
                <A to="/auth/login">
                    back to login
                </A>
                <>&nbsp;&nbsp;&nbsp;</>
                <A to="/auth/signup">
                    <strong>
                        create an account
                    </strong>
                </A>
            </FormRow>

            <Modal
                declineButtonOnClick={() => {
                    setShowSuccessModal(false);
                    history.push('/auth/login');
                }}
                declineButtonText="OK"
                show={showSuccessModal}
                title="reset successful"
            >
                Please login with your new password to continue.
            </Modal>
        </Form>
    );
};

ResetPasswordForm.propTypes = {
    credentials: PropTypes.object.isRequired,
    token: PropTypes.string
};

function ResetPasswordFormValidationConfig(props) {
    const { password, password2 } = props.credentials;

    return {
        fields: ['password', 'password2'],
        validations: {
            password: [
                [isRequired, password]
            ],
            password2: [
                [isRequired, password2],
                [isEqual, password, password2, 'Passwords must match']
            ]
        }
    }
}


export default ResetPasswordForm = validated(ResetPasswordFormValidationConfig)(ResetPasswordForm);
