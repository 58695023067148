import './ResetPassword.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import { setTitle } from 'containers/App/store/actions';

import H3 from 'components/H3';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const credentials = useSelector(store => store.auth.credentials);
    const [token, setToken] = useState(null);

    useEffect(() => {
        document.body.classList.add('reset-password-page');
        dispatch(setTitle('reset your password'));

        const qs = queryString.parse(location.search);

        if(!qs.t) {
            history.push('/auth/forgot-password');
        } else {
            setToken(qs.t);
        }

        return () => {
            document.body.classList.remove('reset-password-page');
        }
    }, [dispatch, history, location]);

    return (
        <div className="reset-password-form--wrapper">
            <Helmet>
                <title>Reset Your Epilogg Password</title>
            </Helmet>

            <H3>
                choose a new password for your account
            </H3>

            <ResetPasswordForm
                credentials={credentials}
                token={token}
            />
        </div>
    );
};

export default ResetPassword;
