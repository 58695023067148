import './Map.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker } from "@react-google-maps/api";

const Map = ({location}) => {
    const [cachedLocation, setCachedLocation] = useState(null);

    useEffect(() => {
        setCachedLocation(location);
    }, [location, location.lat, location.lng, setCachedLocation]);

    if(cachedLocation) {
        return (
            <div className="c-map">
                <GoogleMap
                    zoom={14}
                    center={cachedLocation}
                    mapContainerStyle={{position: 'absolute', height: '100%', width: '100%'}}
                >
                    <Marker
                        position={cachedLocation}
                    />
                </GoogleMap>
            </div>
        );
    } else {
        return null;
    }
};

Map.propTypes = {
    location: PropTypes.object.isRequired
};

export default Map;
