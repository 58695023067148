import './CreateHeaderAvatarUpload.scss';

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import Dropzone from 'react-dropzone';
import {useDispatch, useSelector} from "react-redux";
import heic2any from "heic2any";

import { resizeImage } from 'utils/images';

import { uploadFiles } from 'containers/Upload/actions';
import { saveEpilogg } from 'containers/Epilogg/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import CropModal from 'components/Modal/CropModal';
import FileUploadErrorModal from 'components/Modal/FileUploadErrorModal';
import Icon from 'components/Icon';

const CreateHeaderAvatarUpload = ({epilogg, handleChange}) => {
    const dispatch = useDispatch();
    const dropRef = useRef(null);

    const [activeDrag, setActiveDrag] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [preview, setPreview] = useState(null);
    const [cachedImageUrl, setCachedImageUrl] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);

    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    useEffect(() => {
        return () => {
            setSelectedImage(null);
            setPreview(null);
            setCachedImageUrl(null);
        }
    }, []);

    useEffect(() => {
        if(!cachedImageUrl && epilogg.profilePicArtifact) {
            setCachedImageUrl(epilogg.profilePicArtifact.url)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [epilogg.profilePicArtifact]);

    const handleCrop = async (blob) => {
        blob.preview = URL.createObjectURL(blob);
        setPreview(blob);
        setShowCropModal(false);
        setSelectedImage(null);
        let resizedImage = await resizeImage(blob);
        uploadImage(resizedImage);
    };

    const handleDelete = () => {
        setPreview(null);
        setCachedImageUrl(null);
        handleChange('profilePicStorageKey', null);
        dispatch(saveEpilogg());
    };

    const handleDrop = async (goodFiles, badFiles) => {
        setActiveDrag(false);
        if(badFiles.length > 0) {
            setShowErrorModal(true);
        } else {
            let convertedImage;
            if(goodFiles[0].type.toLowerCase() === 'image/heic') {
                try {
                    setIsUploadingImage(true);
                    convertedImage = await heic2any({blob: goodFiles[0], });
                    setIsUploadingImage(false);
                } catch(err) {
                    console.log(err);
                }

            }

            console.log(goodFiles[0])

            goodFiles[0].preview = URL.createObjectURL(convertedImage || goodFiles[0]);
            setSelectedImage(goodFiles[0]);
            setShowCropModal(true);
        }
    };

    const uploadImage = async (file) => {
        setIsUploadingImage(true);

        try {
            let uploadResult = await dispatch(uploadFiles(file));
            handleChange('profilePicArtifactId', uploadResult.file.artifactId);
            dispatch(saveEpilogg());
        } catch(err) {
            console.log('upload avatar err', err);
            setPreview(null);
        }

        setIsUploadingImage(false);
    };

    const classes = classNames(
        'create-epilogg__header-avatar',
        {
            'create-epilogg__header-avatar--active': activeDrag,
            'create-epilogg__header-avatar__has-preview': epilogg.preview != null,
            'create-epilogg__header-avatar--is-uploading': isUploadingImage
        }
    );

    return (
        <div className={classes}>
            <div className="create-epilogg__header-avatar--inner">
                <Dropzone accept="image/jpeg,image/png,image/gif,image/webp,image/heic,image/heif"
                          disabled={isUploading || isSavingEpilogg}
                          multiple={false}
                          name="avatar"
                          onDragEnter={() => setActiveDrag(true)}
                          onDragLeave={() => setActiveDrag(false)}
                          onDrop={handleDrop}
                          ref={dropRef}
                >
                    {({getRootProps, getInputProps}) => (
                        <div className="create-epilogg__header-avatar__content" {...getRootProps()}>
                            <input {...getInputProps()} />

                            {isUploadingImage ? (
                                <div className="c-file-upload__uploading">
                                    <ActivityIndicator />  uploading
                                </div>
                            ) : (
                                <div style={(preview && preview.preview) || cachedImageUrl ? {backgroundImage: `url(${preview ? preview.preview : cachedImageUrl}`} : {backgroundImage: `url(/obituary/img/noimage/default_avatar_img1.svg)`}} className="c-file-upload__avatar" />
                            )}

                            {activeDrag ? (
                                <div className="create-epilogg__header-avatar--active-drag">
                                    drop here<br />to upload
                                </div>
                            ) : null}
                        </div>
                    )}
                </Dropzone>
            </div>

            {!isUploadingImage ? (
                <div
                    className="create-epilogg__header-avatar-text"
                    onClick={() => {
                        if(dropRef.current) {
                            dropRef.current.open();
                        }
                    }}
                >
                    drop or upload <Icon type="upload" />
                </div>
            ) : null}

            {(preview || cachedImageUrl) && !isUploadingImage ? (
                <Button
                    className="c-file-upload__delete"
                    onClick={handleDelete}
                >
                    <Icon type="x" />
                </Button>
            ) : null}

            <FileUploadErrorModal
                close={() => setShowErrorModal(false)}
                show={showErrorModal}
            />

            <CropModal
                aspectRatio={1}
                close={() => setShowCropModal(false)}
                confirm={handleCrop}
                image={selectedImage || {}}
                show={showCropModal}
            />
        </div>
    );
};

CreateHeaderAvatarUpload.propTypes = {
    epilogg: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default CreateHeaderAvatarUpload;
