import './MainNav.scss';

import React, { useEffect } from 'react';

import A from 'components/A';
import Button from 'components/Button';
import Icon from 'components/Icon';

const LINKS = [
    {
        text: 'samples',
        href: `${process.env.REACT_APP_WEBSITE_URL}/samples-and-tips`
    }, {
        text: 'browse',
        href: `${process.env.REACT_APP_WEBSITE_URL}/search`
    }
];

const MainNav = () => {
    useEffect(() => {

        return () => {

        }
    }, []);

    return (
        <nav className="c-main-nav">
            <Button
                className="c-main-nav__close-button"
                noStyles
                onClick={() => document.body.classList.remove('open-menu')}
            >
                <Icon type="x" />
            </Button>
            {LINKS.map((link) => (
                <div className="main-nav__link" key={link.href}>
                    <A href={link.href}>
                        {link.text}
                    </A>
                </div>
            ))}
        </nav>
    );
};

export default MainNav;
