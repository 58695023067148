import './ViewFlowers.scss';

import React from 'react';
import PropTypes from 'prop-types';

import H2 from 'components/H2';
import Button from "../../../../../components/Button";

const ViewFlowers = () => {
    return (
        <div className="c-view-section__flowers">
            <div className="c-view-section--inner">
                <div className="c-view-section__flowers__left-col">
                    <div className="c-view-section__flowers-graphic">
                        <a href="https://www.beyondwordsco.com/shop?ss_source=partners&ss_campaign_name=epilogg&utm_source=partners&utm_medium=affiliate&utm_campaign=epilogg" target="_blank">
                            <img src="/obituary/img/bw.png" alt="Order from Beyond Words Co" />
                        </a>
                    </div>
                    <div className="c-view-section__text">
                        <div className="c-view-section__text--inner">
                            <H2 className="c-view-section__text-title">
                                express sympathy
                            </H2>

                            <div className="c-view-section__flowers-button">
                                <Button
                                    href="https://www.beyondwordsco.com/shop?ss_source=partners&ss_campaign_name=epilogg&utm_source=partners&utm_medium=affiliate&utm_campaign=epilogg"
                                    target="_blank"
                                >
                                    send care package
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="c-view-section__flowers__right-col">
                    <div className="c-view-section__flowers-graphic">
                        <a href="https://www.pntrac.com/t/TUJGRUlLSkJHRk5NSUpCRkhFRkpK" target="_blank">
                            <img src="/obituary/img/affiliate-banner.jpg" alt="Order from Bouq's: Cut to Order and Free Delivery" />
                        </a>
                    </div>

                    <div className="c-view-section__text">
                        <div className="c-view-section__text--inner">
                            <H2 className="c-view-section__text-title">
                                send flowers
                            </H2>

                            <div className="c-view-section__flowers-button">
                                <Button
                                    href="https://www.pntrac.com/t/TUJGRUlLSkJHRk5NSUpCRkhFRkpK"
                                    target="_blank"
                                >
                                    send flowers
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="c-view-section__text-text c-view-section__text-subtext">
                    * Please Note: When you purchase here, Epilogg receives a fee that helps us keep our site available and free for everyone’s life story.
                </div>
            </div>
        </div>
    );
};

ViewFlowers.propTypes = {
    section: PropTypes.object.isRequired
};

export default ViewFlowers;
