import './CreateHeader.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import clone from 'clone';
import Autocomplete from 'react-google-autocomplete';
import moment from 'moment';

import { handleDateChange, handleTextChange } from 'utils/handle-changes';

import { setEpilogg } from 'containers/Epilogg/store/actions';

import ActionMenu from '../ActionMenu';
import AddMenu from '../AddMenu';
import CreateHeaderAvatarUpload from './CreateHeaderAvatarUpload';
import CreateHeaderBannerUpload from "./CreateHeaderBackgroundUpload";
import Datepicker from 'components/Datepicker';
import Textbox from 'components/Textbox';
import Button from 'components/Button';
import Icon from 'components/Icon';

const CreateHeader = ({epilogg, setTimer}) => {
    const dispatch = useDispatch();

    const { isUploading } = useSelector(store => store.upload);
    const { isSavingEpilogg } = useSelector(store => store.epiloggs);

    const clearPlaceSelected = () => {
        let newEpiloggObj = clone(epilogg);
        newEpiloggObj.locationLatitude = null;
        newEpiloggObj.locationLongitude = null;
        newEpiloggObj.locationAddress = null;
        newEpiloggObj.locationName = null;
        newEpiloggObj.googlePlacesId = null;
        dispatch(setEpilogg(newEpiloggObj));
        setTimer();
    };

    const handleChange = (name, value) => {
        let newEpiloggObj = clone(epilogg);
        newEpiloggObj[name] = value;
        dispatch(setEpilogg(newEpiloggObj));
        setTimer();
    };

    const handlePlaceSelected = (place) => {
        let newEpiloggObj = clone(epilogg);
        newEpiloggObj.locationLatitude = place.geometry.location.lat();
        newEpiloggObj.locationLongitude = place.geometry.location.lng();
        newEpiloggObj.locationAddress = place.formatted_address;
        newEpiloggObj.locationName = place.name;
        newEpiloggObj.googlePlacesId = place.place_id;
        dispatch(setEpilogg(newEpiloggObj));
        setTimer();
    };

    let birth = typeof(epilogg.dateOfBirth) === 'string' ? moment(epilogg.dateOfBirth.substring(0, 10)).format() : epilogg.dateOfBirth;
    let death = typeof(epilogg.dateOfDeath) === 'string' ? moment(epilogg.dateOfDeath.substring(0, 10)).format() : epilogg.dateOfDeath;

    return (
        <div className="create-epilogg__header">
            <ActionMenu />

            <div className="create-epilogg__header-background">
                <CreateHeaderBannerUpload
                    epilogg={epilogg}
                    handleChange={handleChange}
                />

                <div className="create-epilogg__header-display-name">
                    <Textbox
                        disabled={isUploading || isSavingEpilogg}
                        label="Display Name"
                        name="displayName"
                        onChange={(event) => handleTextChange(handleChange, event)}
                        placeholder="display name"
                        value={epilogg.displayName || ''}
                    />
                </div>
            </div>

            <CreateHeaderAvatarUpload
                epilogg={epilogg}
                handleChange={handleChange}
            />

            <div className="create-epilogg__dates-hometown-wrapper">
                <div className="create-epilogg__dates">
                    <Datepicker
                        dateFormat="MMMM dd, yyyy"
                        disabled={isUploading || isSavingEpilogg}
                        id="dateDateOfBirth"
                        label="Date of Birth"
                        maxDate={new Date()}
                        name="dateOfBirth"
                        onChange={(newDate) => handleDateChange(handleChange, 'dateOfBirth', null, newDate)}
                        placeholderText="date of birth xx/xx/xxxx"
                        selected={birth}
                        scrollableYearDropdown
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={150}
                    />
                    <span className="create-epilogg__dates-hyphen">
                        {' - '}
                    </span>
                    <Datepicker
                        dateFormat="MMMM dd, yyyy"
                        disabled={isUploading || isSavingEpilogg}
                        id="dateDateOfDeath"
                        label="Date of Death"
                        maxDate={new Date(moment().add('years', 1).format())}
                        name="dateOfDeath"
                        onChange={(newDate) => handleDateChange(handleChange, 'dateOfDeath', null, newDate)}
                        placeholderText="date of death xx/xx/xxxx"
                        selected={death}
                        scrollableYearDropdown
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={150}
                    />
                </div>

                {epilogg.locationAddress ? (
                    <div className="create-epilogg__hometown--selected--wrapper">
                        <Textbox
                            className="create-epilogg__hometown create-epilogg__hometown--selected"
                            disabled
                            value={epilogg.locationAddress}
                        />

                        <Button
                            disabled={isUploading || isSavingEpilogg}
                            noStyles
                            onClick={clearPlaceSelected}
                        >
                            <Icon type="x" />
                        </Button>
                    </div>
                ) : (
                    <div className="o-textbox create-epilogg__hometown">
                        <Autocomplete
                            className="c-textbox"
                            fields={[
                                'geometry.location',
                                'formatted_address',
                                'name',
                                'place_id'
                            ]}
                            onPlaceSelected={(place) => {
                                handlePlaceSelected(place);
                            }}
                            placeholder="location"
                            types={['(cities)']}
                        />
                    </div>
                )}
            </div>

            {epilogg.content.length === 0 && (
                <AddMenu
                    isInHeader
                />
            )}
        </div>
    );
};

CreateHeader.propTypes = {
    epilogg: PropTypes.object.isRequired,
    setTimer: PropTypes.func.isRequired
};

export default CreateHeader;
