import React from 'react';
import PropTypes from 'prop-types';
import { validated } from 'react-custom-validation';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clone from 'clone';

import { isEmail, isEqual, isStringLongerThan, isRequired } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';

import { setSignupData, signup } from 'containers/Auth/store/actions';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Form from 'components/Form';
import FormError from 'components/FormError';
import LoginWithFacebook from 'components/LoginWithFacebook';
import LoginWithGoogle from 'components/LoginWithGoogle';
import FormRow from 'components/FormRow';
import Textbox from 'components/Textbox';


let SignupForm = ({signupData, $field, $submit, $validation}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isSigningUp, signupError } = useSelector(store => store.auth);

    const handleChange = (name, value) => {
        let newSignupDataObj = clone(signupData);
        newSignupDataObj[name] = value;
        dispatch(setSignupData(newSignupDataObj));
    };

    const handleSubmit = (isValid) => {
        if(isValid === true) {
            dispatch(signup())
                .then((res) => {
                    history.push('/auth/verify')
                });
        }
    };

    return (
        <Form className="signup-form auth-form--skinny">
            <FormRow>
                <Textbox
                    id="txtFirstName"
                    label="first name"
                    name="firstName"
                    placeholder="first name"
                    required
                    type="text"
                    validation={$validation.firstName}
                    value={signupData.firstName || ''}
                    {...$field('firstName', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtLastName"
                    label="last name"
                    name="lastName"
                    placeholder="last name"
                    required
                    type="text"
                    validation={$validation.lastName}
                    value={signupData.lastName || ''}
                    {...$field('lastName', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtEmailAddress"
                    label="email"
                    name="email"
                    placeholder="email"
                    required
                    type="email"
                    validation={$validation.email}
                    value={signupData.email || ''}
                    {...$field('email', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtPassword"
                    label="password"
                    name="password"
                    placeholder="password"
                    required
                    type="password"
                    validation={$validation.password}
                    value={signupData.password || ''}
                    {...$field('password', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtPassword2"
                    label="confirm password"
                    name="password2"
                    placeholder="confirm password"
                    required
                    type="password"
                    validation={$validation.password2}
                    value={signupData.password2 || ''}
                    {...$field('password2', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormError
                error={signupError}
            />

            <FormRow className="signup-form__button-wrapper auth-form__button-wrapper">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isSigningUp}
                >
                    Continue
                </Button>
            </FormRow>

            <div className="signup-form__or">
                <span>OR</span>
            </div>

            <FormRow className="signup-form__button-wrapper auth-form__button-wrapper">
                <LoginWithGoogle />
            </FormRow>

            <FormRow className="signup-form__button-wrapper auth-form__button-wrapper">
                <LoginWithFacebook />
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={signupData.wantsNewsletter}
                    className="signup-form__newsletter-checkbox"
                    id="chkNewsletter"
                    label="sign me up for the epilogg newsletter"
                    name="wantsNewsletter"
                    onChange={() => handleChange('wantsNewsletter', !signupData.wantsNewsletter)}
                />

                <Checkbox
                    checked={signupData.dontShareInformation}
                    id="chkDontShare"
                    label="don't share my information with partner organizations"
                    name="dontShareInformation"
                    onChange={() => handleChange('dontShareInformation', !signupData.dontShareInformation)}
                />
            </FormRow>
        </Form>
    );
};

SignupForm.propTypes = {
    signupData: PropTypes.object.isRequired
};

function signupFormValidationConfig(props) {
    const { email, firstName, lastName, password, password2 } = props.signupData;

    return {
        fields: ['email', 'firstName', 'lastName', 'password', 'password2'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            firstName: [
                [isRequired, firstName]
            ],
            lastName: [
                [isRequired, lastName]
            ],
            password: [
                [isRequired, password],
                [isStringLongerThan, password, 8, 'Password must be at least 8 characters long']
            ],
            password2: [
                [isRequired, password2],
                [isEqual, password2, password, 'Passwords must match'],
                [isStringLongerThan, password, 8, 'Password must be at least 8 characters long']
            ]
        }
    }
}

export default SignupForm = validated(signupFormValidationConfig)(SignupForm);
