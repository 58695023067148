import './InvitationsModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../../components/Modal";
import {useDispatch, useSelector} from "react-redux";
import {inviteEditor, removeEditor, setEmailToInvite} from "../store/actions";
import Form from "../../../../components/Form";
import FormRow from "../../../../components/FormRow";
import {handleTextChange} from "../../../../utils/handle-changes";
import {isEmail, isRequired} from "../../../../utils/validations";
import {validated} from "react-custom-validation";
import Textbox from "../../../../components/Textbox";
import Button from "../../../../components/Button";
import FormError from "../../../../components/FormError";

let InvitationsModal = ({close, emailToInvite, show, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();

    const { inviteEditorError, isInvitingEditor, isRemovingEditor, removeEditorError } = useSelector(store => store.invitations);
    const { collaborators } = useSelector(store => store.epiloggs.epilogg);
    const { id: authUserId } = useSelector(store => store.auth.profile);

    const handleChange = (name, value) => {
        dispatch(setEmailToInvite(value));
    };

    const handleClose = () => {
        dispatch(setEmailToInvite(''));
        $fieldEvent('reset');
        close();
    };

    const handleRemove = async (email) => {
        try {
            await dispatch(removeEditor(email));
        } catch(err) {
            console.log('InvitationModal handleRemove err', err);
        }
    };

    const sendInvitation = async (isValid, email) => {
        if(isValid) {
            try {
                await dispatch(inviteEditor(email));
                $fieldEvent('reset');
            } catch(err) {
                console.log('InvitationModal sendInvitation err', err);
            }
        }
    };

    return (
        <Modal
            className="invitations-modal"
            declineButtonOnClick={handleClose}
            show={show}
            showActivityIndicator={isInvitingEditor}
            title="invite editors"
        >
            <Form>
                <FormRow className="invitations-modal__form">
                    <Textbox
                        disabled={isInvitingEditor}
                        label="email address to invite"
                        name="emailToInvite"
                        onChange={(event) => handleTextChange(handleChange, event)}
                        placeholder="email address to invite"
                        required
                        type="email"
                        validation={$validation.emailToInvite}
                        value={emailToInvite}
                    />

                    <Button
                        disabled={isRemovingEditor}
                        onClick={(e) => {
                            e.preventDefault();
                            $submit(() => sendInvitation(true), () => sendInvitation(false))
                        }}
                        showActivityIndicator={isInvitingEditor}
                    >
                        Invite
                    </Button>
                </FormRow>

                <FormError
                    error={inviteEditorError}
                />
            </Form>

            <div className="invitations-modal__collaborators">
                {collaborators && collaborators.length > 0 ? (
                    <>
                        {collaborators.map((c) => (
                            <div className="invitations-modal__collaborator">
                                <div className="invitations-modal__collaborator-email">
                                    {c.email} ({c.userId === authUserId ? 'you' : c.status})
                                </div>

                                {c.userId !== authUserId && (
                                    <div className="invitations-modal__collaborator-actions">
                                        {c.status === 'invited' && (
                                            <Button
                                                className="invitations-modal__collaborator-actions__resend"
                                                disabled={isInvitingEditor || isRemovingEditor}
                                                noStyles
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    sendInvitation(true, c.email);
                                                }}
                                            >
                                                resend
                                            </Button>
                                        )}

                                        <Button
                                            disabled={isInvitingEditor || isRemovingEditor}
                                            noStyles
                                            onClick={() => handleRemove(c.email)}
                                        >
                                            remove
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </>
                ) : (
                    <>No collaborators yet. Invite one above.</>
                )}
            </div>

            <FormError
                error={removeEditorError}
            />

            <p>
                <Button
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </p>

            <div className="invitations-modal__one-editor-warning">
                *We recommend one editor working at a time to ensure all content is saved correctly. Refresh to see latest version!
            </div>
        </Modal>
    );
};

InvitationsModal.propTypes = {
    close: PropTypes.func.isRequired,
    emailToInvite: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired
};

function invitationsModalValidationConfig(props) {
    const { emailToInvite } = props;

    return {
        fields: ['emailToInvite'],
        validations: {
            emailToInvite: [
                [isRequired, emailToInvite],
                [isEmail, emailToInvite]
            ]
        }
    }
}


export default InvitationsModal = validated(invitationsModalValidationConfig)(InvitationsModal);
