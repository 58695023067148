import './Guestbook.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';
import ParseLinks from "../ParseLinks";

const Guestbook = ({comments, disabled, handleDeletePress}) => {
    return (
        <div className="c-guestbook">
            {comments && comments.length > 0 ? (
                <ol className="c-guestbook__comments-list">
                    {comments.map((comment) => (
                        <li className="c-guestbook__comment" key={comment.id}>
                            <div className="c-guestbook__comment-avatar" style={comment.user && comment.user.profilePicArtifact ? {backgroundImage: `url(${comment.user.profilePicArtifact.url})`} : {backgroundImage: `url(/obituary/img/noimage/default_avatar_img1.svg)`}} />

                            <div className="c-guestbook__comment-content">
                                <div className="c-guestbook__comment-name">
                                    {comment.user ? `${comment.user.firstName} ${comment.user.lastName}` : comment.displayName}
                                </div>


                                <ParseLinks>
                                    <div className="c-guestbook__comment-text">
                                        {comment.text && comment.text.split('\n').map((p, i) => {
                                            if(p) {
                                                return (
                                                    <p key={i}>
                                                        {p}
                                                    </p>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                </ParseLinks>

                                {handleDeletePress && disabled !== true ? (
                                    <Button
                                        className="c-guestbook__comment-delete-button"
                                        onClick={() => handleDeletePress(comment)}
                                    >
                                        <Icon type="x" />
                                    </Button>
                                ) : null}
                            </div>
                        </li>
                    ))}
                </ol>
            ) : (
                <div className="c-guestbook__no-comments">
                    There are no guestbook entries yet.
                </div>
            )}
        </div>
    );
};

Guestbook.propTypes = {
    comments: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    handleDeletePress: PropTypes.func
};

export default Guestbook;
