import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validated } from 'react-custom-validation';
import { useDispatch, useSelector } from "react-redux";
import clone from 'clone';

import { isEmail, isRequired } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';

import { clearCredentials, sendForgotPasswordEmail, setCredentials } from 'containers/Auth/store/actions';

import A from 'components/A';
import Button from 'components/Button';
import Form from 'components/Form';
import FormError from 'components/FormError';
import FormRow from 'components/FormRow';
import Modal from 'components/Modal';
import Textbox from 'components/Textbox';

let ForgotPasswordForm = ({credentials, $field, $fieldEvent, $submit, $validation}) => {
    const dispatch = useDispatch();

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    let { isSendingForgotPasswordEmail, sendForgotPasswordEmailError } = useSelector(store => store.auth);

    const handleChange = (name, value) => {
        let newCredentialsObj = clone(credentials);
        newCredentialsObj[name] = value;
        dispatch(setCredentials(newCredentialsObj));
    };

    const handleSubmit = (isValid) => {
        if(isValid === true) {
            dispatch(sendForgotPasswordEmail())
                .then((res) => {
                    setShowSuccessModal(true);
                    clearCredentials();
                    $fieldEvent('reset');
                });
        }
    };

    return (
        <Form className="forgot-password-form auth-form--skinny">
            <FormRow>
                <Textbox
                    id="txtEmailAddress"
                    label="email"
                    name="email"
                    placeholder="email"
                    required
                    type="email"
                    validation={$validation.email}
                    value={credentials.email || ''}
                    {...$field('email', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormError
                error={sendForgotPasswordEmailError}
            />

            <FormRow className="forgot-password-form__button-wrapper auth-form__button-wrapper">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false));
                    }}
                    showActivityIndicator={isSendingForgotPasswordEmail}
                >
                    Reset Password
                </Button>
            </FormRow>

            <FormRow className="auth-form__link-wrapper">
                <A to="/auth/login">
                    back to login
                </A>
                <>&nbsp;&nbsp;&nbsp;</>
                <A to="/auth/signup">
                    <strong>
                        create an account
                    </strong>
                </A>
            </FormRow>

            <Modal
                declineButtonOnClick={() => setShowSuccessModal(false)}
                declineButtonText="OK"
                show={showSuccessModal}
                title="check your email"
            >
                We've sent instructions to your email. Click the link to reset your password.
            </Modal>
        </Form>
    );
};

ForgotPasswordForm.propTypes = {
    credentials: PropTypes.object.isRequired
};

function ForgotPasswordFormValidationConfig(props) {
    const { email } = props.credentials;

    return {
        fields: ['email'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ]
        }
    }
}


export default ForgotPasswordForm = validated(ForgotPasswordFormValidationConfig)(ForgotPasswordForm);
