const PATHS = {
    auth: {
        changePassword: () => `v1/password/change`,
        forgotPassword: () => `v1/password/forgot`,
        getLoggedInUser: () => `v1/user`,
        login: () => `v1/login`,
        resetPassword: () => `v1/password/reset`,
        signup: () => `v1/signup/email`,
        signupWithFacebook: () => `v1/signup/facebook`,
        signupWithGoogle: () => `v1/signup/google`,
        saveProfile: () => `v1/user`,
        verifyEmail: (token) => `v1/one_click/verify_user?t=${token}`
    },
    epiloggs: {
        create: () => `v1/epilogg`,
        delete: (id) => `v1/epilogg/${id}`,
        deleteComment: (epiloggId, contentId, commentId) => `v1/epilogg/${epiloggId}/content/${contentId}/comments/${commentId}`,
        follow: (id) => `v1/epilogg/${id}/follow`,
        getById: (id) => `v1/epilogg/${id}`,
        newComment: (epiloggId, contentId) => `v1/epilogg/${epiloggId}/content/${contentId}/comments`,
        oneClickUnfollow: (token) => `v1/one_click/unfollow?t=${token}`,
        publish: (id) => `v1/epilogg/${id}/publish`,
        report: (id) => `v1/epilogg/${id}/report`,
        update: (id) => `v1/epilogg/${id}`
    },
    invitations: {
        accept: (token) => `v1/one_click/collaborator_accept?t=${token}`,
        add: (epiloggId) => `v1/epilogg/${epiloggId}/collaborators/add`,
        remove: (epiloggId) => `v1/epilogg/${epiloggId}/collaborators/remove`
    },
    treePurchase: {
        getUrl: (epiloggId) => `v1/epilogg/${epiloggId}/products/purchase`,
    },
    upload: {
        getPresignedUrl: () => 'v1/upload/artifacts'
    }
};

export default PATHS;
